import {
  ActionLayout,
  Button,
  CheckboxController,
  Container,
  EmailInputController,
  ExternalLink,
  Fieldset,
  Grid,
  Legend,
  PageBody,
  TelFieldController,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { ServerError } from '../../utils/forms/ServerError'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { VerifyPhoneDialog } from '../user/confirmSMS/VerifyPhoneDialog'
import { useRequestPhoneVerification } from '../user/useRequestPhoneVerification'
import { AliceCardTosLabel } from './AliceCardTosLabel'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { NonEditableRegisterInput } from './types'
import { useRegister } from './useRegister'
import { UseRegisterEmployeeOptions } from './useRegisterEmployee'

/**
 * Initial registration page for a user. In general, we cannot assume that the user
 * is logged in at this point, so we need to choose between a userUpdate call
 * and a createUser call.
 *
 * Currently, if you call `createUser` with existing user information, the user
 * will not be updated but the endpoint will return their existing information.
 *
 * ??? Are you wondering how the user navigates to the next step? Check out
 * `EnrollmentRouter`, which will watch for `user` state changes (using `useEnrollmentStatus`)
 * and redirect the user to the appropriate page.
 *
 * _Routing/navigation should not be defined in this component_.
 */
export const Register = () => {
  const intl = useIntl()
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const user = enrollmentData?.currentUser

  const { enrollmentCode, referredById } = useParams()
  if (!enrollmentCode) throw new Error('URL must contain enrollment code as a param')

  const nonEditableInput: NonEditableRegisterInput = {
    inviteCode: enrollmentCode,
    referredBy: referredById ? parseInt(referredById, 10) : undefined,
  }

  const { mutate: sendPhoneVerificationCode } = useRequestPhoneVerification()
  const registerOptions: UseRegisterEmployeeOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'enroll.register.updating_your_info' }),
    },
    {
      onSuccess: (data) => {
        const tel = data.registerEmployee?.employee?.user.tel
        if (!tel || tel?.isSmsConfirmed || tel?.isConfirmationCodeSent) return
        sendPhoneVerificationCode({ tel: tel.nationalNumber })
      },
    }
  )
  // receive the created user on success
  const { onSubmit, control, formState, watch, isLoading, isSuccess } = useRegister(
    nonEditableInput,
    registerOptions
  )
  const submitDisabled = isLoading || isSuccess

  const password = watch('password')
  const maySetPassword = password !== undefined // `undefined` means password setting in registration is disabled

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom={Boolean(user)}>
          <FormattedMessage id="enroll.register.greeting" />
        </Typography>
        {!user && (
          <Typography variant="body2" gutterBottom>
            <FormattedMessage id="enroll.register.if_account_then_sign_in" />
          </Typography>
        )}

        <form data-testid="register-user" onSubmit={onSubmit}>
          <Fieldset style={{ marginBottom: '1em' }}>
            <Legend gutterBottom>
              <FormattedMessage id="enroll.register.personal_info.heading" />
            </Legend>
            <Grid container columnGap={2} rowSpacing={1}>
              <Grid item xs={12} sm={4}>
                <TextFieldController
                  control={control}
                  name="firstName"
                  autoComplete="given-name"
                  label={intl.formatMessage({ id: 'profile.first_name_label' })}
                  required
                  fullWidth
                  rules={{
                    required: intl.formatMessage({ id: 'profile.name.validation.required' }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldController
                  control={control}
                  name="lastName"
                  autoComplete="family-name"
                  label={intl.formatMessage({ id: 'profile.last_name_label' })}
                  required
                  fullWidth
                  rules={{
                    required: intl.formatMessage({ id: 'profile.name.validation.required' }),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldController
                  control={control}
                  name="preferredName"
                  autoComplete="nickname"
                  label={intl.formatMessage({ id: 'profile.preferred_name_label' })}
                  helperText={intl.formatMessage({ id: 'common.optional' })}
                  fullWidth
                  rules={{
                    validate: (value) => {
                      if (value && /\d/.test(value)) {
                        return intl.formatMessage({ id: 'profile.name.validation.no_numbers' })
                      }
                      return true
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Fieldset>
          <Fieldset style={{ marginBottom: '1em' }}>
            <Legend gutterBottom>
              <FormattedMessage id="enroll.register.contact_info.heading" />
            </Legend>
            <Grid container columnGap={2} rowSpacing={1}>
              <Grid item xs={12} sm={8}>
                <EmailInputController
                  name="email"
                  label={intl.formatMessage({ id: 'profile.email_label' })}
                  required
                  fullWidth
                  helperText={intl.formatMessage({ id: 'enroll.register.email_address_helper' })}
                  autoComplete="email"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TelFieldController
                  name="tel"
                  label={intl.formatMessage({ id: 'profile.tel_label' })}
                  required
                  fullWidth
                  helperText={
                    !user?.tel?.isSmsConfirmed &&
                    intl.formatMessage({ id: 'enroll.register.phone_number_helper' })
                  }
                  autoComplete="tel"
                  control={control}
                />
              </Grid>
            </Grid>
          </Fieldset>

          {maySetPassword && (
            <Fieldset style={{ marginBottom: '1em' }}>
              <Legend gutterBottom>
                <FormattedMessage id="enroll.register.security.heading" />
              </Legend>
              <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                <Grid item xs={12} sm={4}>
                  <TextFieldController
                    control={control}
                    name="password"
                    label={intl.formatMessage({ id: 'profile.password_label' })}
                    type="password"
                    required
                    fullWidth
                    autoComplete="new-password"
                    rules={{
                      minLength: {
                        value: 8,
                        message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextFieldController
                    control={control}
                    name="passwordConfirmation"
                    label={intl.formatMessage({ id: 'profile.password_confirmation_label' })}
                    type="password"
                    required
                    fullWidth
                    autoComplete="new-password"
                    rules={{
                      validate: (value) =>
                        value === password ||
                        intl.formatMessage({ id: 'auth.reset_password.validation.no_match' }),
                    }}
                  />
                </Grid>
                <Typography variant="caption" component="p" sx={{ paddingLeft: 2, width: '100%' }}>
                  <FormattedMessage id="common.validation.minlength" values={{ minLength: 8 }} />
                </Typography>
              </Grid>

              {formState.errors.password?.message === 'Cannot change password during signup' && (
                <Typography color="error" variant="body2" gutterBottom>
                  <FormattedMessage id="enroll.register.already_have_account" />
                </Typography>
              )}
            </Fieldset>
          )}

          <Fieldset style={{ marginBottom: '1em' }}>
            <Legend gutterBottom>
              <FormattedMessage id="enroll.register.agreements.heading" />
              <br />
              <Typography variant="body2">
                <FormattedMessage id="enroll.register.agreements.subheading" />
              </Typography>
            </Legend>
            <CheckboxController
              color="primary"
              name="acceptTermsAndEsign"
              control={control}
              rules={{
                validate: (v) =>
                  v === true || intl.formatMessage({ id: 'enroll.register.validation.require_to_read_docs' }),
              }}
              label={
                <Typography variant="body2">
                  <FormattedMessage
                    id="enroll.register.legal_agreement.i_have_read_and_agree"
                    values={{
                      'esign-consent': () => (
                        <ExternalLink target="_blank" href="/docs/esign-consent">
                          <FormattedMessage id="enroll.register.legal_agreement.esign_disclosure_and_consent" />
                        </ExternalLink>
                      ),
                      'terms-and-conditions': () => (
                        <ExternalLink target="_blank" href="/docs/tos-employee">
                          <FormattedMessage id="enroll.register.legal_agreement.terms_and_conditions" />.
                        </ExternalLink>
                      ),
                    }}
                  />
                </Typography>
              }
            />

            <CheckboxController
              color="primary"
              name="acceptAliceCardTos"
              control={control}
              rules={{
                validate: (v) =>
                  v === true || intl.formatMessage({ id: 'cards.alice.confirm_you_read_the_documents' }),
              }}
              label={<AliceCardTosLabel />}
            />
          </Fieldset>

          <ServerError errors={formState.errors} />

          <ActionLayout
            primary={
              <>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={submitDisabled}
                >
                  <FormattedMessage id={user ? 'enroll.register.confirm_info_label' : 'common.continue'} />
                </Button>
                {Object.keys(formState.errors).length > 0 && (
                  <Typography color="error" variant="caption" component="p" textAlign="center">
                    <FormattedMessage id="common.validation.check_for_errors" />
                  </Typography>
                )}
              </>
            }
          />
        </form>
        <VerifyPhoneDialog />
      </Container>
    </PageBody>
  )
}
