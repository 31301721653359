import {
  alpha,
  ListItem,
  ListItemIcon,
  ListItemLink,
  ListItemText,
  Skeleton,
  Typography,
  TypographyProps,
  useTheme,
} from '@alice-financial/pretext-ui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import * as React from 'react'

export type HealthcheckItemStatus = 'complete' | 'incomplete' | 'pending'
export type InertHealthcheckItemProps = {
  status: HealthcheckItemStatus
  primary: string
  secondary?: string
  icon?: string | React.ReactNode
  action?: React.ReactNode
}
export type HealthcheckItemProps = InertHealthcheckItemProps & {
  to: string
  isLoading?: boolean
  actionIcon?: React.ReactNode
}
const StatusIcon = ({ status }: Pick<HealthcheckItemProps, 'status'>) => {
  if (status === 'complete') return <CheckCircleIcon color="primary" fontSize="large" />
  if (status === 'incomplete') return <WarningIcon color="warning" fontSize="large" />
  if (status === 'pending') return <AutorenewIcon style={{ fontSize: 30 }} />
  return null
}

const StatusIconOverride = ({ icon }: Pick<HealthcheckItemProps, 'icon'>) => {
  if (!icon) return null
  if (typeof icon === 'string') {
    return (
      <Typography fontSize="2em" lineHeight={1}>
        {icon}
      </Typography>
    )
  }
  return <>{icon}</>
}

export const StatusAvatar = ({ icon, status }: Pick<HealthcheckItemProps, 'status' | 'icon'>) => (
  <ListItemIcon>{icon ? <StatusIconOverride icon={icon} /> : <StatusIcon status={status} />}</ListItemIcon>
)

type HealthcheckTypographyProps<TElement extends React.ElementType = 'span'> = Omit<
  TypographyProps<TElement>,
  'variant' | 'fontWeight' | 'component'
>
const HealthcheckPrimary = (props: HealthcheckTypographyProps) => (
  <Typography variant="body2" fontWeight="bold" {...props} />
)
const HealthcheckSecondary = (props: HealthcheckTypographyProps<'p'>) => (
  <Typography variant="caption" component="p" {...props} />
)

export const HealthcheckItem = ({
  to,
  status: _status,
  primary,
  secondary,
  isLoading,
  icon,
  action,
  actionIcon,
  ...props
}: HealthcheckItemProps) => {
  const { palette } = useTheme()
  const statusToUse = isLoading ? 'pending' : _status
  return (
    <ListItem divider disableGutters disablePadding secondaryAction={action} {...props}>
      <ListItemLink
        to={to}
        color={statusToUse === 'incomplete' ? palette.warning.main : undefined}
        actionIcon={actionIcon}
      >
        <StatusAvatar icon={icon} status={statusToUse} />
        <ListItemText
          primary={isLoading ? <Skeleton width="20ch" /> : <HealthcheckPrimary>{primary}</HealthcheckPrimary>}
          secondary={
            isLoading && secondary ? (
              <Skeleton width="30ch" />
            ) : (
              <HealthcheckSecondary>{secondary}</HealthcheckSecondary>
            )
          }
          sx={{ margin: secondary ? 0 : undefined }}
        />
      </ListItemLink>
    </ListItem>
  )
}

/**
 * Use when you need a list item with a status avatar, but don't want it to be a link
 */
export const InertHealthcheckItem = ({
  primary,
  secondary,
  icon,
  status,
  action,
}: InertHealthcheckItemProps) => {
  const theme = useTheme()
  const statusColors = {
    complete: undefined,
    incomplete: alpha(theme.palette.warning.main, theme.palette.action.activatedOpacity),
    pending: alpha(theme.palette.secondary.main, theme.palette.action.activatedOpacity)
  }
  return (
    <ListItem
      divider
      sx={{
        backgroundColor: statusColors[status] || undefined,
      }}
    >
      <StatusAvatar icon={icon} status={status} />
      <ListItemText
        primary={<HealthcheckPrimary>{primary}</HealthcheckPrimary>}
        secondary={<HealthcheckSecondary>{secondary}</HealthcheckSecondary>}
      />
      {action}
    </ListItem>
  )
}
