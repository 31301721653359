import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { determineCanUsePaygroupBilling, hasStartedBillingConnection } from './billingUtils'
import { useSetUnifiedBilling } from './useSetUnifiedBilling'

export const UnifiedBillingForm = () => {
  const { data: orgData, isFetching: isRefreshingOrg } = useOrgDashboardQuery()
  const org = orgData?.organization
  const { mutate: setUnifiedBilling, isLoading: isSaving } = useSetUnifiedBilling([
    useOrgDashboardQuery.getKey(),
  ])
  const isUpdatingOrg = isSaving || isRefreshingOrg

  const canUsePaygroupBilling = determineCanUsePaygroupBilling(org)

  // automatically update to unified billing if the org CANNOT use paygroup billing
  React.useEffect(() => {
    if (!org || isUpdatingOrg || determineCanUsePaygroupBilling(org) || org.billing?.hasUnifiedBilling) return
    setUnifiedBilling({ orgId: org.id, hasUnifiedBilling: true })
  }, [org, isUpdatingOrg, setUnifiedBilling])

  if (!org || !canUsePaygroupBilling) return null
  if (hasStartedBillingConnection(org)) {
    if (org.billing?.hasUnifiedBilling) {
      return (
        <Typography variant="body2" gutterBottom>
          You&apos;ve chosen to bill all pay groups from the same account
        </Typography>
      )
    }
    return (
      <Typography variant="body2" gutterBottom>
        You&apos;ve chosen to connect a billing method for <strong>each pay group</strong>.
      </Typography>
    )
  }

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
    setUnifiedBilling({ orgId: org.id, hasUnifiedBilling: value === 'true' })

  const currentUnifiedBillingValue = org.billing?.hasUnifiedBilling
  const disabled = isUpdatingOrg

  return (
    <>
      <Typography variant="body2" gutterBottom>
        We support billing at the <strong>organization</strong> level or <strong>per-pay-group</strong>. You
        may pay by credit card or ACH using your bank account and routing numbers.
      </Typography>
      <Box mb={2}>
        <RadioGroup value={currentUnifiedBillingValue} onChange={handleChange}>
          <FormControlLabel
            disabled={disabled}
            value={false}
            control={<Radio />}
            label="Each pay group should be billed separately"
          />
          <FormControlLabel
            disabled={disabled}
            value={true}
            control={<Radio />}
            label="All pay groups are billed from one account"
          />
        </RadioGroup>
      </Box>
    </>
  )
}
