import { UseGqlMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { useCreateOrgFromProvisionalMutation } from './gql/createOrgFromProvisional_gen'
import { OrgOnboardingApiValues, OrgOnboardingFormValues, ProvisionalOrganization } from './types'

const formValuesToApiValues = (values: OrgOnboardingFormValues): OrgOnboardingApiValues => {
  const {
    legal_name,
    payroll_platform_id,
    line_1,
    line_2,
    city_name,
    state_code,
    zipcode,
    action: _action,
    num_locations: _num_locations,
    ...pay_schedule
  } = values
  return {
    legal_name,
    payroll_platform_id: payroll_platform_id || null, // empty string will be coerced to null
    address_attributes: {
      line_1,
      line_2,
      city_name,
      state_code,
      zipcode,
    },
    pay_schedule,
  }
}

export const apiValuesToFormValues = (
  provisionalOrg: ProvisionalOrganization
): Omit<Partial<OrgOnboardingFormValues>, 'action'> => {
  const { address, payroll_platform, pay_schedule, ...unchangedKeys } = provisionalOrg
  const {
    line_1 = '',
    line_2 = '',
    city: city_name = '',
    state: state_code = '',
    zipcode = '',
  } = address || {}

  return {
    payroll_platform_id: payroll_platform?.id || '',
    line_1,
    line_2,
    city_name,
    state_code,
    zipcode,
    num_locations: 1,
    ...pay_schedule,
    ...unchangedKeys,
  }
}
const createProvisionalOrg = (values: OrgOnboardingFormValues) =>
  fetchRest<ProvisionalOrganization>(`/api/onboarding/provisional_orgs`, {
    method: 'POST',
    body: JSON.stringify(formValuesToApiValues(values)),
  })

const getUpdateProvisionalOrg = (id: string) => (values: OrgOnboardingFormValues) =>
  fetchRest<ProvisionalOrganization>(`/api/onboarding/provisional_orgs/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(formValuesToApiValues(values)),
  })

/**
 * This hook manages the API calls for the full lifecycle of a provisional org
 * - create
 * - update
 * - 'submit' (convert to full org)
 *
 * It will create or update the provisional on every request, but will only convert to a full org if the action is 'submit'
 * and the user has confirmed that they want to do so. The confirmation is only required when converting to a full org
 * because it is not possible to edit the provisional organization _after_ it has been converted to a full org.
 */
export const useManageProvisionalOrg = (
  id: string | undefined,
  mutationOptions: UseGqlMutationCallbacks<ProvisionalOrganization, OrgOnboardingFormValues> = {}
) => {
  const { mutateAsync: createOrgFromProvisional } = useCreateOrgFromProvisionalMutation() // use mutateAsync so that errors bubble up to parent mutation

  const mutationOptionsWithNotifications = useMutationNotifier({}, mutationOptions)
  return useMutationWithInvalidation(
    async (values) => {
      // if `id` is specified, we are updating an existing provisional org, otherwise we are creating a new one
      const primaryRequest = id ? getUpdateProvisionalOrg(id) : createProvisionalOrg

      const provisionalOrg = await primaryRequest(values)

      if (values.action === 'submit') {
        // can only convert if the API says it's ready
        if (!provisionalOrg.ready_for_conversion) {
          throw new Error('Provisional organization is not ready for conversion')
        }
        const acceptTheThingsICannotChange = confirm(
          'Are you sure you want to submit? You will need to contact support to update these details in the future'
        )
        if (acceptTheThingsICannotChange) {
          await createOrgFromProvisional({ input: { provisionalOrgId: provisionalOrg.id } })
        }
      }
      return provisionalOrg
    },
    mutationOptionsWithNotifications,
    'all'
  )
}
