import { InlineSkeleton, PII, Typography, TypographyProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AddressAttributes } from './types'
import { profileAddressToAddressAttributes, useQueryAddress } from './useQueryAddress'
import { AddressEntity } from '../../../user/types'

const addressIsAddressEntity = (address: AddressAttributes | AddressEntity): address is AddressEntity => {
  return (address as AddressEntity).city !== undefined
}

type AddressDisplayProps = TypographyProps & { address?: AddressAttributes | AddressEntity | null }

export const AddressLoading = (props: AddressDisplayProps) => (
  <Typography {...props}>
    <InlineSkeleton width="8em" />
    <br />
    <InlineSkeleton width="6em" />
    <br />
    <InlineSkeleton width="10em" />
  </Typography>
)

export const AddressDisplay = ({ address, ...props }: AddressDisplayProps) => {
  if (!address) return <AddressLoading {...props} />
  const addressAttributes = addressIsAddressEntity(address)
    ? profileAddressToAddressAttributes(address)
    : address
  return (
    <Typography {...props}>
      <PII>
        {addressAttributes.line_1}
        <br />
        {addressAttributes.line_2 ? (
          <>
            {addressAttributes.line_2}
            <br />
          </>
        ) : null}
        {addressAttributes.city_name}, {addressAttributes.state_code} {addressAttributes.zipcode}
      </PII>
    </Typography>
  )
}

export const UserAddressDisplay = (props: Omit<AddressDisplayProps, 'address'>) => {
  const { data: address } = useQueryAddress()
  return <AddressDisplay address={address} {...props} />
}
