import {
  ActionLayout,
  Button,
  Container,
  InfoDrawer,
  Typography,
  useInfoDrawerControl,
} from '@alice-financial/pretext-ui'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ContactSupport } from '../employeeDashboard/ContactSupport'

const ANCHOR_NAME = 'start-date-info'

type InfoContentProps = {
  employerName: string | undefined | null
  onClose: () => void
}
const InfoContent = ({ employerName, onClose }: InfoContentProps) => (
  <>
    <Container>
      <Typography gutterBottom>
        <FormattedMessage
          id="enroll.register.start_date_info.requirement_for_alice_to_work"
          values={{ employerName }}
        />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage
          id="enroll.register.start_date_info.if_already_started_at_employer"
          values={{ employerName }}
        />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage
          id="enroll.register.start_date_info.if_havent_started_at_employer"
          values={{ employerName }}
        />
      </Typography>

      <ActionLayout
        secondary={
          <Button color="secondary" onClick={onClose} startIcon={<ArrowBackIcon />}>
            <FormattedMessage id="common.back" />
          </Button>
        }
      />
    </Container>
    <ContactSupport onClick={onClose} />
  </>
)

type StartDateInfoProps = {
  children: React.ReactNode
  employerName: string | undefined | null
}
export const StartDateInfo = ({ children, employerName }: StartDateInfoProps) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      title={
        <Typography variant="h2">
          <FormattedMessage id="enroll.register.start_date_info.if_i_dont_know_my_start_date" />
        </Typography>
      }
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent onClose={onClose} employerName={employerName} />}
    >
      {children}
    </InfoDrawer>
  )
}
