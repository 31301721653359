import { Typography } from '@alice-financial/pretext-ui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CreditCardIllustration from 'jsx:./assets/credit-card-illustration.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { prettyTelString } from '../../../../utils/formatters/telFormat'
import { useQueryUser } from '../../../user'
import { CardConnectCardProps, SpendingConnectCard } from '../SpendingConnectCard'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { PERSONAL_CARDS_ROUTE } from './BankingConnections'
import { isAvailable } from './connect/bankingConnectionUtils'

const CardRoot = ({ label, children }: Pick<CardConnectCardProps, 'label' | 'children'>) => {
  return (
    <SpendingConnectCard
      data-testid="connect-personal"
      to={PERSONAL_CARDS_ROUTE}
      label={label}
      Card={CreditCardIllustration}
    >
      {children}
    </SpendingConnectCard>
  )
}

export const BankingConnectionConnectCard = () => {
  const { data: user } = useQueryUser()
  const {
    data: { bankingConnections },
  } = useQuerySpendingConnections()
  const availableConnections = bankingConnections.filter(isAvailable)
  const connectionsCount = availableConnections.length || 0

  if (connectionsCount > 0) {
    return (
      <CardRoot
        label={
          <Typography>
            <CheckCircleIcon color="primary" sx={{ verticalAlign: 'middle' }} />{' '}
            {connectionsCount > 1 ? (
              <FormattedMessage id="connections.connection_count_plural" values={{ connectionsCount }} />
            ) : (
              <FormattedMessage id="connections.connection_count_singular" />
            )}
          </Typography>
        }
      >
        {user?.tel && (
          <Typography variant="body2">
            <FormattedMessage
              id="connections.will_text_when_eligible_txn"
              values={{ tel: prettyTelString(user.tel) }}
            />
          </Typography>
        )}
      </CardRoot>
    )
  }
  return (
    <CardRoot
      label={
        <>
          <Typography>
            <FormattedMessage id="connections.new_connection.link_cards" />
          </Typography>
          <ChevronRightIcon />
        </>
      }
    >
      <Typography variant="body2">
        <FormattedMessage id="connections.instructions.personal_card" />
      </Typography>
    </CardRoot>
  )
}
