import {
  ActionLayout,
  Button,
  Callout,
  CheckboxController,
  Link,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { EmployeeRole } from '../../graphql/generated.types'
import { AdminEmployeeFragment, useAdminEmployeeProfileQuery } from './gql/adminEmployeeProfile_gen'
import { useUpdateEmailPreferences } from './useUpdateEmailPreferences'

type AdminManagementProps = { adminEmployee: AdminEmployeeFragment }
const AdminManagement = ({ adminEmployee }: AdminManagementProps) => {
  if (adminEmployee.role !== EmployeeRole.OrganizationAdmin) return null
  return <Typography variant="body2" fontWeight="bold" gutterBottom></Typography>
}

type AdminEmployeeProfileProps = { adminEmployee: AdminEmployeeFragment }
const ProfileTypeDisplay = ({ adminEmployee }: AdminEmployeeProfileProps) => {
  return (
    <>
      <Callout variant="body2" gutterBottom>
        {adminEmployee.role === EmployeeRole.OrganizationAdmin && (
          <>
            You have admin access to all paygroups in {adminEmployee.employer.organization?.name}.<br />
            You may manage administrator access for other employees on the{' '}
            <Link to={`/manage/people`}>People page</Link>
          </>
        )}
        {adminEmployee.role === EmployeeRole.EmployerAdmin &&
          `You have admin access for paygroup ${adminEmployee.employer.name}`}
      </Callout>
      <AdminManagement adminEmployee={adminEmployee} />
    </>
  )
}

const AdminEmailPreferencesForm = ({ adminEmployee }: AdminEmployeeProfileProps) => {
  const { emailPreferences } = adminEmployee
  const { mutate: updateEmailPreferences } = useUpdateEmailPreferences(adminEmployee.id)
  const { control, handleSubmit } = useForm({ values: emailPreferences })
  return (
    <form onSubmit={handleSubmit((values) => updateEmailPreferences(values))}>
      <Typography variant="h2" gutterBottom>
        Set your email preferences
      </Typography>
      <CheckboxController
        control={control}
        name="monthlyFinance"
        label="Monthly finance review, covering invoices and deduction withdrawals from the previous month"
      />
      <CheckboxController control={control} name="perInvoice" label="Email for each invoice" />
      <CheckboxController
        control={control}
        name="perPbaWithdrawal"
        label="Email for each payroll bank account withdrawal of employee contributions"
      />
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" type="submit">
            Update preferences
          </Button>
        }
      />
    </form>
  )
}

/**
 * The admin profile page currently only manages email preferences for admins
 */
export const AdminProfilePage = () => {
  const { data: adminEmployeeData } = useAdminEmployeeProfileQuery()

  const adminEmployee = adminEmployeeData?.employee
  if (!adminEmployee) return null

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Administrator profile
      </Typography>
      <ProfileTypeDisplay adminEmployee={adminEmployee} />
      <AdminEmailPreferencesForm adminEmployee={adminEmployee} />
    </PageBody>
  )
}
