import * as Types from '../generated.types'

export type BankingAccountFragment = {
  __typename?: 'BankingAccount'
  id: number
  name?: string | null
  cardType?: Types.BankCardType | null
  accountType?: Types.BankingAccountType | null
  platform: Types.BankingAccountPlatform
  accountLast4?: string | null
}

export const BankingAccountFragmentDoc = `
    fragment BankingAccount on BankingAccount {
  id
  name
  cardType
  accountType
  platform
  accountLast4: last4
}
    `
