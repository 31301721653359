import { Container, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS } from '../useEnrollmentStatus'
import { ReenrollmentForm } from './Reenrollment'
import { useReenroll } from './useReenrollment'

/**
 * This component is the one shown to the users who started reenrollment without
 * having a spending connection (users who originally enrolled via the old/Ada
 * enrollment flow which didn't require them to get an Alice card or connect
 * a personal card). Prior to seeing this component the EE must have been asked
 * to setup a spending connection (which set a flag in localStorage),
 * which by the API rules will automatically mark them enrolled after setting it up;
 * because of that allowing the EE to reset their password in this component is
 * just for convenience, and not a requirement for reenrollment (given the EE is
 * already marked re-enrolled).
 *
 */
export const ReenrolledByConnectingSpending = () => {
  const { onSubmit, control, isLoading, isSuccess } = useReenroll({
    // Since the localStorage flag was mostly to show this component, once the
    // EE has completed business in this component we can remove the flag.
    onSuccess: () => window.localStorage.removeItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS),
  })

  const { data: currentUserData } = useCurrentUserQuery()

  return (
    <PageBody>
      <Container>
        <Typography variant="h2" gutterBottom>
          <FormattedMessage id="reenroll.landing_spending_connected.almost_finished" />
        </Typography>

        <Typography gutterBottom>
          <FormattedMessage
            id="reenroll.landing_spending_connected.submit_reenrollment"
            values={{ username: currentUserData?.currentUser?.email }}
          />
        </Typography>

        <ReenrollmentForm onSubmit={onSubmit} control={control} disabled={isLoading || isSuccess} />
      </Container>
    </PageBody>
  )
}
