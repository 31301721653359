import { Container, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommutingSpendingSummary } from './CommutingSpendingSummary'
import { TxnList } from './TransactionList'
import { DatePagingContextProvider } from '../../../utils/paging/datePaging/DatePagingContext'

/**
 * parent container for transaction list, detail, and edit routes
 */
export const SpendingSummary = () => {
  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="spending.heading" />
        </Typography>
      </Container>
      <CommutingSpendingSummary />
      <DatePagingContextProvider>
        <TxnList />
      </DatePagingContextProvider>
    </PageBody>
  )
}
