import * as React from 'react'
import { useSpendingConnectionSummary } from '../employeeDashboard/cards/useQuerySpendingConnections'
import { EmployeeAccountStatus } from '../graphql/generated.types'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'

/**
 * enrollment flow is 'not_started' -> 'registered' -> 'spending_connected' -> 'newly_enrolled' -> 'enrolled'
 *
 * re-enrollment flow is one of these:
 * 1. if EE originally enrolled via the web-apps: 'needs_reenrollment' -> 'enrolled'
 * 2. if EE originally enrolled via Ada: 'needs_reenrollment_and_spending_connection' -> 'reenrolled_by_connecting_spending' -> 'enrolled'
 *
 * (when #2 above the EE starts without any spending connections, so we take them to
 * get one, and per the REST API logic they're automatically re-enrolled when they
 * get one, so the 'reenrolled_by_connecting_spending' status is just a virtual-inner one
 * we add because we need to show a different UI for that case)
 *
 * Note that these states do not directly correspond to an employee's `accountStatus` in the REST API
 */
export type EnrollmentCompletionStatus =
  | 'not_started'
  | 'registered'
  | 'spending_connected'
  | 'newly_enrolled'
  | 'enrolled'
  | 'ineligible'
  | 'needs_reenrollment'
  | 'needs_reenrollment_and_spending_connection'
  | 'reenrolled_by_connecting_spending'

// This string is the name of a localStorage key we used to identify when the EE
// has started re-enrollment without having a spending connection.
export const REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS =
  'reenrollmentStartedWithoutSpendingConnection'

/**
 *
 * This function tells if the user has started the re-enrollment process without
 * any spending connections. That's is used at `_useEnrollmentStatus` to compute the
 * EE enrollment status. Whenever the user starts the re-enrollment process without
 * a spending connection, we set a localStorage flag to true. Such localStorage flag
 * is used because we don't have another way (like a backend flag) to know if the
 * EE started the re-enrollment process without a spending connection (no dummy
 * password, for example, given that a real password was set on the first enrollment).
 */
const _useIsOrStartedReenrollmentWithoutSpendingConnections = () => {
  const { data: enrollmentInfoData } = useEmployeeEnrollmentInfoQuery()
  const { hasConnectedBankingConnections } = useSpendingConnectionSummary()
  const enrollmentInfo = enrollmentInfoData?.employee?.enrollmentInfo

  const hasToOrStartedReenrollmentWithoutSpendingConnections =
    enrollmentInfo?.accountStatus === EmployeeAccountStatus.NotEnrolled &&
    Boolean(enrollmentInfo?.enrollmentDate) &&
    hasConnectedBankingConnections === false // we only care about banking connections, not Alice Cards

  React.useEffect(() => {
    if (window.localStorage.getItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS) === 'true') return // set once, never unset by this hook
    if (hasToOrStartedReenrollmentWithoutSpendingConnections) {
      window.localStorage.setItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS, 'true')
    }
  }, [hasToOrStartedReenrollmentWithoutSpendingConnections])

  return (
    hasToOrStartedReenrollmentWithoutSpendingConnections ||
    window.localStorage.getItem(REENROLLMENT_STARTED_WITHOUT_SPENDING_CONNECTIONS)
  )
}

/**
 * This hook derives enrollment-related state from user state - the main attributes
 * to look for are:
 * 1. an SMS-confirmed phone number
 * 2. connected spending
 * 3. 'enrolled' account status
 * 4. a non-dummy password
 * 5. enrollment_date (in the case of re-enrollments)
 * 6. localStorage flag (in the case of re-enrollments)
 */
const _useEnrollmentStatus = (): EnrollmentCompletionStatus | undefined => {
  const {
    data: employeeEnrollmentData,
    isFetching: isFetchingLatestEnrollmentData,
    isError: isMissingEnrollmentInfo,
  } = useEmployeeEnrollmentInfoQuery()
  const {
    hasConnectedBankingConnections,
    hasAliceCards,
    isFetching: isFetchingLatestSpendingData,
  } = useSpendingConnectionSummary()
  const hasConnections = hasConnectedBankingConnections || hasAliceCards
  const isOrStartedReenrollmentWithoutSpendingConnections =
    _useIsOrStartedReenrollmentWithoutSpendingConnections()

  if (isFetchingLatestEnrollmentData || isFetchingLatestSpendingData) {
    return undefined // indeterminate state
  }
  const isSmsConfirmed = employeeEnrollmentData?.currentUser?.tel?.isSmsConfirmed
  const isPasswordSet =
    employeeEnrollmentData?.currentUser && !employeeEnrollmentData?.currentUser.hasDummyPassword
  const employmentStartDate = employeeEnrollmentData?.employee?.employmentStartDate
  const enrollmentInfo = employeeEnrollmentData?.employee?.enrollmentInfo
  if (!enrollmentInfo) return 'not_started' // logged out - it's ok to show the enrollment flow
  if (isMissingEnrollmentInfo) return 'not_started'

  const eeAccountStatus = enrollmentInfo.accountStatus

  if (eeAccountStatus === EmployeeAccountStatus.Enrolled) {
    return employmentStartDate ? 'enrolled' : 'newly_enrolled'
  }

  if (enrollmentInfo.registrationBlocker) return 'ineligible'

  if (!isSmsConfirmed || !isPasswordSet) return 'not_started'

  // If the EE's account status is "not_enrolled" and they have an enrollment date,
  // that indicates the EE was enrolled at some point, something happened (they
  // were terminated or on leave and now they're back and went to the "not_enrolled"
  // status), so they need to re-enroll.
  // The other reason for having to go through the reenrollment flow is when the
  // EE record is one transferred from another org (and the old record was in an
  // inactive status ex. terminated, on leave, etc). Technically, from the EE record
  // perspective is not a reenrollment (since the EE record itself doesn't have a record of
  // a past reenrollment), but from a user perspective they have to reenroll at
  // Alice to keep using it from their new org. That allows us to update the EE
  // record status to "enrolled" (since it starts on "not_enrolled").
  const eeHasToGoTroughReenrollment =
    eeAccountStatus === EmployeeAccountStatus.NotEnrolled && (enrollmentInfo.enrollmentDate || hasConnections)

  if (eeHasToGoTroughReenrollment) {
    if (isOrStartedReenrollmentWithoutSpendingConnections) {
      return 'needs_reenrollment_and_spending_connection'
    }
    return 'needs_reenrollment'
  }

  // if (enrollmentInfo.enrollmentBlocker === EmployeeEnrollmentBlocker.NotEnrolledInBenefit) return 'registered'
  if (!hasConnections) return 'registered'

  // if the EE re-enrolled starting without spending connections, then added at
  // least one (per guard condition next above), then we signal that by the status next
  if (isOrStartedReenrollmentWithoutSpendingConnections) return 'reenrolled_by_connecting_spending'

  if (enrollmentInfo.accountStatus === EmployeeAccountStatus.NotEnrolled) return 'spending_connected'
  return 'enrolled'
}

/**
 * Supply a stable 'status' value to the parent. The logic for determining the status is deferred to
 * `_useEnrollmentStatus` above, which listens to API data changes and may return an indeterminate state
 */
export const useEnrollmentStatus = () => {
  const statusRightNow = _useEnrollmentStatus()
  const [status, setStatus] = React.useState(statusRightNow)
  if (statusRightNow !== undefined && status !== statusRightNow) setStatus(statusRightNow)

  return status
}

export const useIsEnrolling = () => {
  const enrollmentStatus = useEnrollmentStatus()
  if (enrollmentStatus === undefined) return undefined
  return enrollmentStatus !== 'ineligible' && enrollmentStatus !== 'enrolled'
}
