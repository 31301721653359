import {
  Box,
  ExternalLink,
  FormControlLabel,
  Link,
  MenuItem,
  PII,
  Select,
  Switch,
  SwitchProps,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { LoginLink } from '../app/auth/LoginLink'
import { LocaleProvider, useLocaleState } from './LocaleProvider'
import { ENGLISH_TRNS } from './messages/en-us'
import { SPANISH_TRNS } from './messages/es'
import { SupportedLangCode } from './types'

type ProviderProps = {
  children?: React.ReactNode
}

export const MESSAGE_MAP: Record<SupportedLangCode, typeof ENGLISH_TRNS> = {
  'en-us': ENGLISH_TRNS,
  es: SPANISH_TRNS,
}

/**
 * A wrapper around React-Intl IntlProvider that will change the translation
 * settings based on the currently-loaded user object from the API. In order
 * to change the language, you must update the user preference
 */
const TranslationProvider: React.FC<ProviderProps> = ({ children }) => {
  const [locale] = useLocaleState()
  const messages = MESSAGE_MAP[locale]
  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale="en-us"
      messages={messages}
      defaultRichTextElements={{
        'email-admin-support': () => (
          <ExternalLink href="mailto:admin-support@thisisalice.com">
            admin-support@thisisalice.com
          </ExternalLink>
        ),
        strong: (...chunks) => <strong>{chunks}</strong>,
        'strong-nowrap': (...chunks) => <strong style={{ whiteSpace: 'nowrap' }}>{chunks}</strong>,
        br: () => <br />,
        em: (...chunks) => <em>{chunks}</em>,
        li: (...chunks) => <li>{chunks}</li>,
        pii: (...chunks) => <PII>{chunks}</PII>,
        'link-login': (...chunks) => <LoginLink>{chunks}</LoginLink>,
        'link-pw-reset': (...chunks) => <Link to="/forgot-password">{chunks}</Link>,
      }}
    >
      {children}
    </ReactIntlProvider>
  )
}

/**
 * This provider should wrap the application in order to provide the correct React-Intl
 * transalations as well as enabling the `useLocaleState` hook, which any child component
 * can use to get/set the user's preferred locale
 */
export const LocalizationProvider: React.FC<ProviderProps> = ({ children }) => (
  <LocaleProvider>
    <TranslationProvider>{children}</TranslationProvider>
  </LocaleProvider>
)

type SwitchLanguageProps = SwitchProps & {
  compact?: boolean
}

const CompactLanguageSwitch = () => {
  const [locale, setLocale, disabled] = useLocaleState()
  return (
    <Select
      onChange={(e) => setLocale(e.target.value as SupportedLangCode)}
      value={locale}
      disabled={disabled}
      aria-label="Switch language"
    >
      <MenuItem value="en-us">English</MenuItem>
      <MenuItem value="es">Español</MenuItem>
    </Select>
  )
}

export const SwitchLanguage = ({ compact, ...switchProps }: SwitchLanguageProps) => {
  const [locale, setLocale, disabled] = useLocaleState()
  if (compact) return <CompactLanguageSwitch />

  const control = (
    <Switch
      {...switchProps}
      name="language"
      disabled={disabled}
      onChange={() => setLocale(locale === 'en-us' ? 'es' : 'en-us')}
      inputProps={{ 'aria-label': 'Switch to Español' }}
      checked={locale === 'es'}
    />
  )
  return (
    <Box textAlign="center" whiteSpace="nowrap" display="flex" alignSelf="center">
      <Box pr={1} display="inline-block" alignSelf="center" component={Typography}>
        English
      </Box>
      <FormControlLabel control={control} label="Español" />
    </Box>
  )
}
