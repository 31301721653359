import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EnrollmentEmployerQueryVariables = Types.Exact<{
  inviteCode?: Types.InputMaybe<Types.Scalars['String']['input']>
  email?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type EnrollmentEmployerQuery = {
  __typename?: 'Query'
  enrollmentEmployer?: {
    __typename?: 'EnrollmentEmployer'
    id: number
    isActive: boolean
    marketingFriendlyName?: string | null
    enrolledCount: number
    inviteCode: string
  } | null
}

export const EnrollmentEmployerDocument = `
    query EnrollmentEmployer($inviteCode: String, $email: String) {
  enrollmentEmployer(inviteCode: $inviteCode, email: $email) {
    id
    isActive
    marketingFriendlyName
    enrolledCount
    inviteCode
  }
}
    `

export const useEnrollmentEmployerQuery = <TData = EnrollmentEmployerQuery, TError = Error>(
  variables?: EnrollmentEmployerQueryVariables,
  options?: UseQueryOptions<EnrollmentEmployerQuery, TError, TData>
) => {
  return useQuery<EnrollmentEmployerQuery, TError, TData>(
    variables === undefined ? ['EnrollmentEmployer'] : ['EnrollmentEmployer', variables],
    fetchGql<EnrollmentEmployerQuery, EnrollmentEmployerQueryVariables>(
      EnrollmentEmployerDocument,
      variables
    ),
    options
  )
}

useEnrollmentEmployerQuery.getKey = (variables?: EnrollmentEmployerQueryVariables) =>
  variables === undefined ? ['EnrollmentEmployer'] : ['EnrollmentEmployer', variables]
