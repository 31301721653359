import { useBankingTransactionQuery } from './graphql/bankingTransaction_gen'
import { apiTxnToAppTxn } from './txnUtils'

/**
 * Thin wrapper around `useBankingTransactionQuery` that will query details of a
 * particular transaction and transform it using `apiTxnToAppTxn`
 */
export const useTransactionDetailQuery = (id: string) => {
  const { data: txnData, ...queryInfo } = useBankingTransactionQuery({ id })
  return {
    data: txnData && apiTxnToAppTxn(txnData.bankingTransaction),
    ...queryInfo,
  }
}
