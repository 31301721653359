import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ActionLayout,
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@alice-financial/pretext-ui'
import DownloadIcon from '@mui/icons-material/Download'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import * as React from 'react'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { PageSizePagerControls } from '../../../utils/paging/pageSizePaging/PageSizePagerControls'
import {
  PageSizePagingContextProvider,
  usePageSizePagingContext,
} from '../../../utils/paging/pageSizePaging/PageSizePagingContext'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { InvoiceStatus } from '../../graphql/generated.types'
import { InvoiceFragment, useInvoicesQuery } from './gql/invoices_gen'
import { useParams } from 'react-router'

const byCreatedAt = (a: InvoiceFragment, b: InvoiceFragment) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

const DUE_AFTER_DAYS = 7 // enough time for an ACH to clear
const getIsOverdue = (invoice: InvoiceFragment) =>
  invoice.status === InvoiceStatus.Pending &&
  new Date(invoice.createdAt).getTime() < new Date().getTime() + 1000 * 60 * 60 * 24 * DUE_AFTER_DAYS

type InvoiceStatusMessageProps = {
  invoice: InvoiceFragment
}
const InvoiceStatusMessage = ({ invoice }: InvoiceStatusMessageProps) => {
  switch (invoice.status) {
    case InvoiceStatus.Paid:
      return (
        <Typography variant="body2" component="span" color="primary" fontWeight="bold">
          (PAID)
        </Typography>
      )
    case InvoiceStatus.Pending: {
      const isOverdue = getIsOverdue(invoice)
      if (isOverdue) {
        return (
          <Typography variant="body2" component="span" color="error" fontWeight="bold">
            (OVERDUE)
          </Typography>
        )
      }
      return null
    }
    case InvoiceStatus.Failed: // fallthrough
    case InvoiceStatus.AutopayFailed:
      return (
        <Typography variant="body2" component="span" color="error" fontWeight="bold">
          (Payment failed)
        </Typography>
      )
    case InvoiceStatus.Refunded:
      return (
        <Typography variant="body2" component="span" color="primary" fontWeight="bold">
          (Refunded)
        </Typography>
      )
  }
}

const startEndString = (start: string | null | undefined, end: string | null | undefined) =>
  start && end ? `${dateStringFormat.medium(start)} - ${dateStringFormat.medium(end)}` : null

type InvoiceProps = {
  invoice: InvoiceFragment
  defaultExpanded?: boolean
}
const Invoice = ({ invoice, defaultExpanded }: InvoiceProps) => {
  const isOverdue = getIsOverdue(invoice)

  const iconColor = invoice.status === InvoiceStatus.Paid ? 'primary' : 'disabled'
  return (
    <Accordion key={invoice.id} defaultExpanded={defaultExpanded}>
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <ListItem component="div" disableGutters disablePadding>
          <ListItemIcon>
            {invoice.status === InvoiceStatus.Paid ? (
              <TaskAltIcon fontSize="large" color={iconColor} />
            ) : (
              <MoneyOffIcon fontSize="large" color={iconColor} />
            )}
            {/** display icon according to payment status */}
          </ListItemIcon>
          <Box width="100%" paddingRight={1}>
            <Box width="100%" display="flex" justifyContent="space-between" flexWrap="nowrap">
              <Box>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="0.8rem"
                  fontWeight="bold"
                  textOverflow="ellipsis"
                  sx={{ textWrap: 'nowrap' }}
                >
                  {invoice.ownerName}
                </Typography>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight="bold">
                      #{invoice.reference} <InvoiceStatusMessage invoice={invoice} />
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" component="p" fontStyle="italic">
                      Fee for period {startEndString(invoice.startDate, invoice.endDate)}
                    </Typography>
                  }
                />
              </Box>
              <Box textAlign="right">
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="0.8rem"
                  fontWeight="bold"
                  sx={{ textWrap: 'nowrap' }}
                >
                  {dateStringFormat.long(invoice.createdAt)}
                </Typography>
                <ListItemText
                  primary={
                    <Typography variant="body2" color={isOverdue ? 'error' : undefined} fontWeight="bold">
                      Amount: {formatCentsAsDollars(invoice.amount, false)}
                    </Typography>
                  }
                  secondary={
                    Boolean(invoice.netSavings && invoice.amount) && (
                      <Typography variant="caption" component="p" fontStyle="italic" noWrap>
                        incl. discount: {formatCentsAsDollars(invoice.netSavings)}
                      </Typography>
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <ListItem component="div" disableGutters disablePadding>
          <ListItemIcon></ListItemIcon>
          <Box width="100%" pt={1} pr={2}>
            {invoice.status === InvoiceStatus.AutopayFailed && (
              <Typography variant="caption" component="p" color="error" fontWeight="bold" gutterBottom>
                Please check the billing account for {invoice.ownerName}
              </Typography>
            )}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>description</TableCell>
                    <TableCell align="right">fee</TableCell>
                    {Boolean(invoice.netSavings) && (
                      <>
                        <TableCell align="right">discount</TableCell>
                        <TableCell align="right">total</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell width="100%">{item.description}</TableCell>
                      <TableCell align="right">
                        {formatCentsAsDollars(item.amount + item.netSavings, false)}
                      </TableCell>
                      {Boolean(invoice.netSavings) && (
                        <>
                          <TableCell align="right" sx={{ fontStyle: 'italic' }}>
                            -{formatCentsAsDollars(item.netSavings)}
                          </TableCell>
                          <TableCell align="right">{formatCentsAsDollars(item.amount)}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell width="100%"></TableCell>
                    {Boolean(invoice.netSavings) && (
                      <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </>
                    )}
                    <TableCell
                      align="right"
                      sx={{ borderTop: '2px solid black', fontWeight: 'bold', fontSize: '1.1rem' }}
                    >
                      {formatCentsAsDollars(invoice.amount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <ActionLayout
              primary={
                <>
                  <Button
                    size="small"
                    variant="contained"
                    LinkComponent="a"
                    href={`/billing/invoices/download?invoice_id=${invoice.id}`}
                    endIcon={<DownloadIcon />}
                  >
                    PDF
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    LinkComponent="a"
                    target="_blank"
                    href={`/api/organization/invoices/${invoice.id}/download_report`}
                    endIcon={<DownloadIcon />}
                  >
                    CSV
                  </Button>
                </>
              }
            />
          </Box>
        </ListItem>
      </AccordionDetails>
    </Accordion>
  )
}

const InvoiceListPagerControlLabel = ({ invoices }: { invoices: Array<NonNullable<InvoiceFragment>> }) => {
  const firstItem = invoices.at(0)
  const lastItem = invoices.at(-1)
  if (!firstItem || !lastItem) return null
  const sameDay =
    dateStringFormat.mediumNoYear(firstItem.createdAt) === dateStringFormat.mediumNoYear(lastItem.createdAt)
  return (
    <Typography variant="subtitle1" fontWeight="bold">
      {dateStringFormat.medium(firstItem.createdAt)}
      {!sameDay && ` - ${dateStringFormat.medium(lastItem.createdAt)}`}
    </Typography>
  )
}
const InvoiceList = ({ org }: OrgInvoicesProps) => {
  const { employerId: employerIdParam } = useParams()
  const employerId = employerIdParam ? parseInt(employerIdParam) : undefined
  const [paging] = usePageSizePagingContext()

  const { data: invoicesData, isLoading } = useInvoicesQuery({ orgId: org.id, employerId, ...paging })
  const invoices = (invoicesData?.organization?.billing?.invoicesConnection.nodes || [])
    .filter(isNotNull)
    .sort(byCreatedAt)
  const pageInfo = invoicesData?.organization?.billing?.invoicesConnection.pageInfo

  return (
    <>
      {pageInfo && (
        <PageSizePagerControls pageInfo={pageInfo}>
          <InvoiceListPagerControlLabel invoices={invoices} />
        </PageSizePagerControls>
      )}
      {!isLoading && invoices.length === 0 && <Typography>No invoices found</Typography>}
      {invoices.map((invoice) => (
        <Invoice key={invoice.id} invoice={invoice} />
      ))}
      {pageInfo && invoices.length > 5 && <PageSizePagerControls pageInfo={pageInfo} />}
    </>
  )
}

type OrgInvoicesProps = {
  org: OrganizationDetailFragment
}
export const OrgInvoices = ({ org }: OrgInvoicesProps) => {
  return (
    <Box mt={3}>
      <Typography variant="h2" gutterBottom>
        Invoices
      </Typography>
      <PageSizePagingContextProvider>
        <InvoiceList org={org} />
      </PageSizePagingContextProvider>
    </Box>
  )
}
