import * as Types from '../generated.types'

export type PaymentCardFragment = {
  __typename?: 'PaymentCard'
  id: number
  billingSystemId: string
  brand: string
  expMonth: number
  expYear: number
  last4: string
}

export const PaymentCardFragmentDoc = `
    fragment PaymentCard on PaymentCard {
  id
  billingSystemId
  brand
  expMonth
  expYear
  last4
}
    `
