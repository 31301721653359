import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useMutationNotifier } from '../../../../../utils/useMutationNotifier'
import { useEmployeeEnrollmentInfoQuery } from '../../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'

type FinicityConnectValues = { platform_id: string }

export const useCreateFinicityConnection = (
  mutationOptions: UseRestMutationCallbacks<null, FinicityConnectValues> = {}
) => {
  const _mutationOptions = useMutationNotifier({}, mutationOptions)

  return useMutationWithInvalidation(
    (values: FinicityConnectValues) => {
      const body = JSON.stringify(values)
      return fetchRest<null>('/api/user/banking_institutions/finicity_connect', { method: 'POST', body })
    },
    _mutationOptions,
    [
      useConnectedCardsQuery.getKey(),
      useEmployeeEnrollmentInfoQuery.getKey(), // because card connections affect account_status
    ]
  )
}
