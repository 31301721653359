import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type PayPeriodsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>
  after?: Types.InputMaybe<Types.Scalars['String']['input']>
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>
  before?: Types.InputMaybe<Types.Scalars['String']['input']>
  employerId?: Types.InputMaybe<Types.Scalars['Int']['input']>
}>

export type PayPeriodsQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    payPeriods: {
      __typename?: 'PayPeriodConnection'
      nodes?: Array<{
        __typename?: 'PayPeriod'
        id: number
        payday: string
        startDate: string
        endDate: string
        employeeCount: number
        totalEmployeeReimbursement: number
        totalEmployeeDeduction: number
        totalEmployeeSavings: number
        invoice?: { __typename?: 'Invoice'; id: number; reference?: string | null; amount: number } | null
        paygroupInfo: { __typename?: 'EmployerInfo'; id: number; name?: string | null }
      } | null> | null
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type PayPeriodSummaryFragment = {
  __typename?: 'PayPeriod'
  id: number
  payday: string
  startDate: string
  endDate: string
  employeeCount: number
  totalEmployeeReimbursement: number
  totalEmployeeDeduction: number
  totalEmployeeSavings: number
  invoice?: { __typename?: 'Invoice'; id: number; reference?: string | null; amount: number } | null
  paygroupInfo: { __typename?: 'EmployerInfo'; id: number; name?: string | null }
}

export const PayPeriodSummaryFragmentDoc = `
    fragment PayPeriodSummary on PayPeriod {
  id
  payday
  startDate
  endDate
  employeeCount
  totalEmployeeReimbursement
  totalEmployeeDeduction
  totalEmployeeSavings
  invoice {
    id
    reference
    amount
  }
  paygroupInfo {
    id
    name
  }
}
    `
export const PayPeriodsDocument = `
    query PayPeriods($first: Int, $after: String, $last: Int, $before: String, $employerId: Int) {
  organization {
    payPeriods(
      first: $first
      after: $after
      last: $last
      before: $before
      employerId: $employerId
    ) {
      nodes {
        ...PayPeriodSummary
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    ${PayPeriodSummaryFragmentDoc}`

export const usePayPeriodsQuery = <TData = PayPeriodsQuery, TError = Error>(
  variables?: PayPeriodsQueryVariables,
  options?: UseQueryOptions<PayPeriodsQuery, TError, TData>
) => {
  return useQuery<PayPeriodsQuery, TError, TData>(
    variables === undefined ? ['PayPeriods'] : ['PayPeriods', variables],
    fetchGql<PayPeriodsQuery, PayPeriodsQueryVariables>(PayPeriodsDocument, variables),
    options
  )
}

usePayPeriodsQuery.getKey = (variables?: PayPeriodsQueryVariables) =>
  variables === undefined ? ['PayPeriods'] : ['PayPeriods', variables]
