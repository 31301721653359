import {
  Box,
  Button,
  ButtonProps,
  Divider,
  ExternalLink,
  Typography,
  useResponseNotification,
} from '@alice-financial/pretext-ui'

import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHelpScout } from '../auth/helpScout/beacon'
import { useAnalytics } from '../telemetry/AnalyticsProvider'

type ContactSupportProps = {
  onClick?: () => void
}

export const useGetHelp = () => {
  const intl = useIntl()
  const analytics = useAnalytics()
  const helpScoutBeacon = useHelpScout()
  const { notifyError } = useResponseNotification()
  return {
    openHelp: () => {
      analytics.track('contact_support.open')
      if (!helpScoutBeacon) {
        notifyError(intl.formatMessage({ id: 'contact_support.contact.in_app_unavailable' }))
        return
      }
      helpScoutBeacon('open')
    },
  }
}

export const ContactSupportButton = ({ onClick, ...buttonProps }: ContactSupportProps & ButtonProps) => {
  const { openHelp } = useGetHelp()
  const _onClick = () => {
    onClick && onClick()
    openHelp()
  }
  return (
    <>
      <Button variant="contained" color="primary" {...buttonProps} onClick={_onClick}>
        <FormattedMessage id="contact_support.contact.label" />
      </Button>
      <ExternalLink href="mailto:support@thisisalice.com">
        <Typography variant="caption" color="text.secondary" component="p">
          support@thisisalice.com
        </Typography>
      </ExternalLink>
    </>
  )
}

export const ContactSupport = ({ onClick }: ContactSupportProps) => {
  return (
    <>
      <Box mt={5} mb={2} component={Divider} />
      <Box textAlign="center">
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="contact_support.still_have_questions" />
        </Typography>
        <ContactSupportButton onClick={onClick} />
      </Box>
    </>
  )
}
