import { Slide } from '@mui/material'
import { CSSProperties } from '@mui/material/styles/createMixins'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '../feedback'
import { CloseButton } from '../inputs/CloseButton'

type SlideTransitionProps = TransitionProps & {
  children: React.ReactElement
}
const _SlideTransition = (props: SlideTransitionProps, ref: React.Ref<unknown>) => (
  <Slide direction="down" ref={ref} {...props} />
)
const Transition = React.forwardRef(_SlideTransition)

type FullDialogProps = Omit<DialogProps, 'title'> & {
  title?: React.ReactElement | string
  onClose?: () => void
  children: React.ReactNode | Array<React.ReactNode>
  actions?: React.ReactNode
  justifyActions?: CSSProperties['justifyContent']
}

export const FullDialog = ({
  title,
  onClose,
  children,
  actions,
  justifyActions,
  ...props
}: FullDialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-title"
      TransitionComponent={props.fullScreen ? Transition : undefined}
      slotProps={{ backdrop: { invisible: props.fullScreen } }}
      {...props}
    >
      {title && (
        <DialogTitle component="div" id="dialog-title">
          {title}
          {onClose && <CloseButton color="primary" aria-label="close" onClick={onClose} />}
        </DialogTitle>
      )}
      {!title && onClose && <CloseButton color="primary" aria-label="close" onClick={onClose} />}
      <DialogContent>{children}</DialogContent>
      {actions && (
        <DialogActions sx={justifyActions && { justifyContent: justifyActions }}>{actions}</DialogActions>
      )}
    </Dialog>
  )
}
