import { Container, Paper, styled, useMediaQuery, useTheme } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { REDIRECT_PARAM } from '../routes/routeUtils'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'

const FormContainer = styled(Container)(({ theme }) => ({
  // a bit of extra vertical padding for this one-off layout
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(3),
  maxWidth: '30rem !important',
}))

type AuthFormLayoutProps = {
  children: React.ReactNode
}
export const AuthFormLayout = ({ children }: AuthFormLayoutProps) => {
  const theme = useTheme()
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: currentUserData } = useCurrentUserQuery()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const redirect = searchParams.get(REDIRECT_PARAM) || '/'

  // if there's already a user, go ahead and navigate into the app
  React.useEffect(() => {
    if (currentUserData?.currentUser && location.pathname !== redirect) navigate(redirect, { replace: true })
  }, [currentUserData, navigate, redirect, location])

  return (
    <FormContainer>
      <Paper elevation={isMobileWidth ? 0 : 2} sx={{ padding: isMobileWidth ? '0.5em' : '2em' }}>
        {children}
      </Paper>
    </FormContainer>
  )
}
