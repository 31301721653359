// List of mapping codes from https://developer.tryfinch.com/integrations/providers
// (keys are for the platform names in our system and values are the Finch codes).
// Using `as const` to make TS know this is an unchanging const object so that
// it can infer the object exact values when using `ValueOf` below (is this too much?)
export const PAYROLL_PLATFORM_FINCH_CODES = {
  adp_run: 'adp_run',
  gusto: 'gusto',
  paycom: 'paycom',
  paylocity: 'paylocity',
} as const

type ValueOf<T> = T[keyof T]
export type PayrollPlatformFinchCode = ValueOf<typeof PAYROLL_PLATFORM_FINCH_CODES>
export type PayrollPlatformAliceCode = keyof typeof PAYROLL_PLATFORM_FINCH_CODES
