import {
  ActionLayout,
  Box,
  Button,
  PinInputController,
  Typography,
  useResponseNotification,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { prettyTelString } from '../../../utils/formatters/telFormat'
import { ConfirmPhoneCodeFormValues } from '../types'
import { useSubmitPhoneVerification } from '../useSubmitPhoneVerification'
import { GqlUserFragment } from '../gql/currentUser_gen'

type VerifyPhoneFormProps = {
  tel: GqlUserFragment['tel']
  onNotReceived: () => void
}

/**
 * This is two forms in one:
 * 1. A form to submit the verification code
 * 2. A form to re-send the verification code, optionally to a new phone number
 */
export const VerifyPhoneForm = ({ tel, onNotReceived }: VerifyPhoneFormProps) => {
  const intl = useIntl()
  const { notifySubmit, notifyError, clearNotif } = useResponseNotification()
  const { handleSubmit, control, setError } = useForm<ConfirmPhoneCodeFormValues>({
    defaultValues: { confirmation_code: '' },
  })

  const {
    mutate: onSubmitVerification,
    isLoading: isSubmittingVerification,
    isSuccess: isSuccessfulVerification,
  } = useSubmitPhoneVerification({
    onMutate: () =>
      notifySubmit(intl.formatMessage({ id: 'enroll.phone_verification.sending_verification_code' })),
    onError: (err) => {
      const errorMessage = err.message
      if (errorMessage !== 'invalid_code') {
        notifyError(errorMessage)
        return
      }
      clearNotif()
      setError(
        'confirmation_code',
        {
          message: intl.formatMessage({ id: 'enroll.phone_verification.incorrect_code' }),
          type: 'mismatch',
        },
        { shouldFocus: true }
      )
    },
    onSuccess: () => clearNotif(),
  })

  const submitCodeDisabled = isSubmittingVerification || isSuccessfulVerification

  const onSubmit = handleSubmit(({ confirmation_code }) => {
    onSubmitVerification({ confirmation_code })
  })

  return (
    <form data-testid="verify-phone" onSubmit={onSubmit}>
      <Typography variant="body2">
        <FormattedMessage
          id="enroll.phone_verification.texted_you_a_verification_code"
          values={{
            tel: (tel && prettyTelString(tel?.nationalNumber)) || '',
          }}
        />
      </Typography>
      <Box mb={2} mt={1} textAlign="center">
        <PinInputController
          control={control}
          name="confirmation_code"
          helperText={intl.formatMessage({ id: 'enroll.phone_verification.enter_the_4_digit_code' })}
          autoFocus
          rules={{
            validate: (value) => {
              if (value) return
              return intl.formatMessage(
                {
                  id: 'enroll.phone_verification.code_sent_to_tel',
                },
                { tel: tel ? prettyTelString(tel.nationalNumber) : '' }
              )
            },
          }}
        />
      </Box>

      <ActionLayout
        primary={
          <Button
            variant="contained"
            type="submit"
            data-testid="submit-verify-phone"
            disabled={submitCodeDisabled}
            fullWidth
          >
            <FormattedMessage id="common.confirm" />
          </Button>
        }
        secondary={
          <Button variant="outlined" fullWidth color="secondary" onClick={onNotReceived}>
            <FormattedMessage id="enroll.phone_verification.did_not_receive_label" />
          </Button>
        }
      />
    </form>
  )
}
