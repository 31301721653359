import { HealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type OrgLogoHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}
export const OrgLogoHealthcheck = ({ org }: OrgLogoHealthcheckProps) => {
  if (!org) return null
  if (!org.onboardingState.isLogoPresent) {
    return (
      <HealthcheckItem
        primary="Add a logo for your organization"
        secondary="Required to generate your enrollment materials"
        status="incomplete"
        to="/manage/locations"
      />
    )
  }
  return null
}
