import { Container, InfoDrawer, Typography, useInfoDrawerControl } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../../../ContactSupport'
import { FormattedMessage } from 'react-intl'

const ANCHOR_NAME = 'digital-wallet-info'

type InfoContentProps = {
  onClickSupport: () => void
}
const InfoContent = ({ onClickSupport }: InfoContentProps) => (
  <>
    <Container>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.digital_wallet.opening_instruction" />
      </Typography>
      <Typography variant="h3">
        <FormattedMessage
          id="cards.alice.digital_wallet.add_your_alice_card_to_platform"
          values={{ platform: 'Apple Pay' }}
        />
      </Typography>
      <ul>
        <FormattedMessage id="cards.alice.digital_wallet.add_to_apple_pay_instructions" />
      </ul>
      <Typography variant="h3">
        <FormattedMessage
          id="cards.alice.digital_wallet.add_your_alice_card_to_platform"
          values={{ platform: 'Google Pay' }}
        />
      </Typography>
      <ul>
        <FormattedMessage id="cards.alice.digital_wallet.add_to_google_pay_instructions" />
      </ul>
    </Container>
    <ContactSupport onClick={onClickSupport} />
  </>
)

type DigitalWalletInfoProps = {
  children: React.ReactNode
}
export const DigitalWalletInfo = ({ children }: DigitalWalletInfoProps) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      title={
        <Typography variant="h2">
          <FormattedMessage id="cards.alice.digital_wallet.add_instant_card_to_digital_wallet" />
        </Typography>
      }
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent onClickSupport={onClose} />}
    >
      {children}
    </InfoDrawer>
  )
}
