import { CircularProgress, Container, PageBody } from '@alice-financial/pretext-ui'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useEmployerDetailQuery } from '../../orgDashboard/employer/gql/employerDetail_gen'
import { useSearchParamsRedirect } from '../../routes/routeUtils'
import { PAYROLL_PLATFORM_FINCH_CODES, PayrollPlatformAliceCode } from './types'
import { useFinchIframe } from './useFinchIframe'

interface FinchAuthGatewayProps {
  referrerPage?: string // URL for redirecting user back to the previous page in this app.
  PayrollPlatformAliceCode?: PayrollPlatformAliceCode
}

const isPayrollPlatformCode = (code: string | null): code is PayrollPlatformAliceCode =>
  typeof code === 'string' && Object.keys(PAYROLL_PLATFORM_FINCH_CODES).includes(code)

/**
 * This component acts as a gateway for the Finch Connect iframe. It opens the iframe
 * for payroll platform connection, exchanges the authorization token for a Finch token,
 * and redirects the user to the referrer page. The referrer page and payroll platform
 * can be passed as query parameters or props.
 */
export const FinchAuthGateway: React.FC<FinchAuthGatewayProps> = () => {
  const { employerId } = useParams()
  if (!employerId) throw new Error('employerId is required for FinchAuthRequiredAlert')
  const returnHere = useSearchParamsRedirect()
  const { data: employerData } = useEmployerDetailQuery({ id: parseInt(employerId, 10) })
  const org = employerData?.organization
  const employer = org?.employer
  if (!org || !employer) return null
  const { isFinchEnabled, payrollPlatform } = org.payrollConnection
  const hasActiveConnection = employer.paygroupConfig?.finchConnection?.isActive

  if (!payrollPlatform?.code || !isFinchEnabled || hasActiveConnection) return null
  if (!isPayrollPlatformCode(payrollPlatform.code)) {
    throw new Error(`${payrollPlatform.code} is not a supported payroll platform`)
  }
  const payrollPlatformFinchCode = PAYROLL_PLATFORM_FINCH_CODES[payrollPlatform?.code]

  return <FinchAuthGatewayIFrame payrollProvider={payrollPlatformFinchCode} returnWith={returnHere} />
}

type FinchAuthGatewayIFrameProps = {
  payrollProvider: PayrollPlatformAliceCode
  returnWith: (returnParams: URLSearchParams) => void
}
const FinchAuthGatewayIFrame = ({ payrollProvider, returnWith }: FinchAuthGatewayIFrameProps) => {
  const { isTokenExchangeLoading } = useFinchIframe({
    payrollProvider,
    returnWith,
  })

  if (isTokenExchangeLoading) {
    return (
      <PageBody>
        <Container
          component="section"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '1em' }}
        >
          <CircularProgress />
        </Container>
      </PageBody>
    )
  }
  return null
}
