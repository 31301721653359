import { SectionHeading } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useMayManageAliceCardProgram } from '../aliceCardProgram/aliceCardProgramUtils'
import { ConnectedPaymentMethods } from '../aliceCardProgram/ConnectedPaymentMethods'
import { AliceCardProgramSetup } from '../aliceCardProgram/setup/AliceCardProgramSetup'

type AliceCardProgramSectionProps = {
  org?: OrganizationDetailFragment | null
}

export const AliceCardProgramSection = ({ org }: AliceCardProgramSectionProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!org || !mayManageAliceCardProgram) return null
  return (
    <>
      <SectionHeading id="alice-card-program" to="/manage/alice-card-program" gutterBottom>
        Alice Card
      </SectionHeading>
      {org.aliceCardProgram.hasFundingConnected ? (
        <ConnectedPaymentMethods org={org} />
      ) : (
        <AliceCardProgramSetup org={org} />
      )}
    </>
  )
}
