import type { StripeConstructorOptions } from '@stripe/stripe-js'
import * as React from 'react'
import { BillingWorkspace } from '../../graphql/generated.types'

const loadStripe = (publishableKey: string, options?: StripeConstructorOptions | undefined) =>
  import('@stripe/stripe-js').then((stripe) => stripe.loadStripe(publishableKey, options))
/**
 * Load the Stripe instance for billing-related operations.
 */
export const useBillingStripe = (billingWorkspace?: BillingWorkspace) => {
  const key =
    billingWorkspace === BillingWorkspace.Pretaxhero
      ? process.env.REACT_APP_STRIPE_PTH_BILLING_PUBLIC_KEY
      : process.env.REACT_APP_STRIPE_BILLING_PUBLIC_KEY
  return React.useMemo(() => loadStripe(key || ''), [key])
}

/**
 * Load the Stripe instance for issuing-related operations.
 */
export const useIssuingStripe = ({ stripeAccount }: { stripeAccount: string | null | undefined }) =>
  React.useMemo(
    () =>
      stripeAccount
        ? loadStripe(process.env.REACT_APP_STRIPE_ISSUING_PUBLIC_KEY || '', { stripeAccount })
        : null,
    [stripeAccount]
  )
