import { useStripe } from '@stripe/react-stripe-js'
import { useForm } from 'react-hook-form'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { ConnectACHFormValues } from './types'
import { datadogRum } from '@datadog/browser-rum'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { composeMutationCallbacks } from '@alice-financial/api'

const trackConnectACHError = (error?: { message?: string }, context?: Record<string, unknown>) => {
  datadogRum.addError(error || 'Unexpected setupIntent status', {
    source: 'StripeJS',
    req: 'confirmUsBankAccountSetup',
    ...context,
  })
}

type ConnectACHMutationOptions = UseMutationOptions<void, unknown, ConnectACHFormValues>
/**
 * Mutation to create ACH-based payment method in Stripe. This will not register the payment method
 * with Alice - the user will have to agree to the ACH mandate using the same clientSecret in order
 * for the payment method to be persisted
 */

const useConnectACHMutation = (clientSecret: string, mutationOptions: ConnectACHMutationOptions) => {
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser

  const stripe = useStripe() // must be inside <Elements />

  return useMutation<void, unknown, ConnectACHFormValues>(
    ['connectACH'],
    (values: ConnectACHFormValues) => {
      if (!stripe) throw new Error('Missing Stripe configuration, please refresh the page')
      if (!user) throw new Error('Missing user information')

      return stripe
        .confirmUsBankAccountSetup(clientSecret, {
          payment_method: {
            us_bank_account: {
              routing_number: values.routingNumber,
              account_number: values.accountNumber,
              account_holder_type: 'company',
            },
            billing_details: {
              name: [user.firstName, user.lastName].join(' ').trim(),
              email: user.email || '',
            },
          },
        })
        .then((res) => {
          if (res.setupIntent?.status !== 'succeeded') {
            trackConnectACHError(res.error, { res })
            throw res.error
          }
        })
        .catch((error) => {
          trackConnectACHError(error)
          throw error
        })
    },
    mutationOptions
  )
}

export const useConnectACHForm = (clientSecret: string, mutationOptions: ConnectACHMutationOptions) => {
  const { handleSubmit, ...form } = useForm<ConnectACHFormValues>()

  const mutationOptionsWithFormError = composeMutationCallbacks(mutationOptions, {
    onError: () => {
      form.setError('root.ach_connect_error', { message: 'unable to verify' }) // 'Unexpected setupIntent status
    },
  })

  const { mutate: connectACH } = useConnectACHMutation(clientSecret, mutationOptionsWithFormError)

  return {
    onSubmit: handleSubmit((values) => connectACH(values)),
    ...form,
  }
}
