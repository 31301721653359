/**
 * @deprecated - This file should be removed whenever the CardsIndex component is
 * removed. The replacement of this component is the ReenrollmentIntro (at
 * BankingConnections.tsx) component.
 */
import * as React from 'react'
import { Typography } from '@alice-financial/pretext-ui'
import { FormattedMessage } from 'react-intl'
import { useEnrollmentStatus } from '../useEnrollmentStatus'

// This component has the greeting shown to EEs when they are re-enrolling under the
// following scenario:
// there are EEs who enrolled via the old (the Ada one) enrollment flow, and they
// might not have a spending connection (ex. Alice card or personal card connected).
// In such cases we show them this component as part of the <CardsIndex /> and
// also set the a flag in localStorage which will make the <ReenrolledByConnectingSpending />
// be loaded after.
export const ReenrollingWithoutSpendingConnectionsGreeting = () => {
  const enrollmentStatus = useEnrollmentStatus()
  // Nothing to show here if the enrollmentStatus isn't the one we're looking for
  if (enrollmentStatus !== 'needs_reenrollment_and_spending_connection') return null

  return (
    <div>
      <Typography variant="h1" textAlign="center" gutterBottom>
        <FormattedMessage id="reenroll.landing.welcome_back" />
      </Typography>
      <Typography gutterBottom textAlign="center">
        <FormattedMessage id="reenroll.landing_spending_connected.select_option_below_to_reenroll" />
      </Typography>
      <br />
    </div>
  )
}
