import * as React from 'react'
import { useClearSession } from './useLogout'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'
import { useResponseNotification } from '@alice-financial/pretext-ui'

type SessionManagerProps = {
  children: React.ReactNode
}

// This message is defined on the back end for expired sessions, a special case of 401 Unauthorized responses
const SESSION_EXPIRED_MESSAGE = 'Session expired'

/**
 * This component will ensure that expired sessions are cleared from the server so that
 * the user can log in or register. It will also re-mount its children after the session
 * is refreshed to ensure that child API calls are retried
 */
export const SessionManager = ({ children }: SessionManagerProps) => {
  const { error } = useCurrentUserQuery()
  const sessionIsExpired = error?.message === SESSION_EXPIRED_MESSAGE
  const { notifySubmit, notifySuccess } = useResponseNotification()

  const {
    mutate: clearSession,
    isLoading: isClearingSession,
    isSuccess: sessionIsCleared,
  } = useClearSession({
    onMutate: () => notifySubmit('Session expired, refreshing...'),
    onSuccess: () => notifySuccess('Session refreshed'),
  })

  React.useEffect(() => {
    if (!sessionIsExpired || isClearingSession || sessionIsCleared) return

    clearSession()
  }, [sessionIsExpired, clearSession, isClearingSession, sessionIsCleared])

  if (isClearingSession) return null // unmount children while clearing session
  return <>{children}</>
}
