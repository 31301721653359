import { Box, Container, Grid, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SwitchLanguage } from '../../../translations/LocalizationProvider'
import { DocumentList } from './documents/DocumentList'
import { EmployeeDocument } from './documents/types'
import { useDocumentListQuery } from './documents/useDocumentListQuery'
import { useHasAliceCards } from '../cards/useQuerySpendingConnections'
import { AddressForm } from './address/AddressForm'

const EmployeeDocumentList = () => {
  const intl = useIntl()
  const { data: signableDocuments } = useDocumentListQuery()
  const agreements: EmployeeDocument[] = [
    {
      id: 'esign-consent',
      name: intl.formatMessage({ id: 'enroll.register.legal_agreement.esign_disclosure_and_consent' }),
      file_type: 'html_page',
    },
    {
      id: 'tos-employee',
      name: intl.formatMessage({ id: 'enroll.register.legal_agreement.terms_and_conditions' }),
      file_type: 'html_page',
    },
    {
      id: 'privacy_policy',
      name: intl.formatMessage({ id: 'footer.privacy_policy' }),
      file_type: 'html_page',
    },
  ]

  const documents = [...(signableDocuments || []), ...agreements]
  return <DocumentList documents={documents} />
}

export const ProfileIndex = () => {
  const hasAliceCards = useHasAliceCards()
  return (
    <PageBody>
      <Box mb={4} component={Container}>
        <Typography variant="h1">
          <FormattedMessage id="profile.heading" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="profile.index.what_can_be_managed" />
        </Typography>
      </Box>

      {hasAliceCards && (
        <Box mb={4} component={Container}>
          <AddressForm>
            <Typography gutterBottom>
              <FormattedMessage id="profile.address.note_billing_address" />
            </Typography>
          </AddressForm>
        </Box>
      )}

      <Box mb={4} component={Container}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography variant="h2">
              <FormattedMessage id="profile.index.language" />
            </Typography>
          </Grid>
          <Grid>
            <SwitchLanguage />
          </Grid>
        </Grid>
      </Box>
      <Container>
        <Typography variant="h2">
          <FormattedMessage id="profile.index.documents" />
        </Typography>
        <EmployeeDocumentList />
      </Container>
    </PageBody>
  )
}
