import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from '@alice-financial/pretext-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Elements } from '@stripe/react-stripe-js'
import * as React from 'react'
import { PaymentCardInput } from '../../externalServices/stripe/PaymentCardInput'
import { useBillingStripe } from '../../externalServices/stripe/useLoadStripe'
import { ACHBillingSourceInput } from './ACHBillingSourceInput'
import { ConnectBillingSourceProps } from './types'
import { useBillingClientSecret } from './useBillingClientSecret'

type PaymentOptionProps = ConnectBillingSourceProps &
  Pick<AccordionProps, 'expanded' | 'disabled' | 'onChange'>
const PaymentCardOption = ({ billable, onSuccess, ...accordionProps }: PaymentOptionProps) => (
  <Accordion {...accordionProps}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Enter credit card details
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <PaymentCardInput billable={billable} onSuccess={onSuccess} />
    </AccordionDetails>
  </Accordion>
)

const BankAccountOption = ({
  billable,
  clientSecret,
  onSuccess,
  onBankSelected,
  ...accordionProps
}: PaymentOptionProps & { clientSecret: string; onBankSelected: () => void }) => (
  <Accordion {...accordionProps}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Enter bank account details
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ACHBillingSourceInput
        clientSecret={clientSecret}
        billable={billable}
        onSuccess={onSuccess}
        onBankSelected={onBankSelected}
      />
    </AccordionDetails>
  </Accordion>
)

type PaymentOption = 'card' | 'bank_credentials'
export const ConnectBillingSource = ({ billable, onSuccess }: ConnectBillingSourceProps) => {
  const [expandedOption, setExpandedOption] = React.useState<PaymentOption | null>(null)
  const [isBankSelected, setIsBankSelected] = React.useState(false)
  const stripePromise = useBillingStripe(billable?.billing?.billingWorkspace)
  const { data: clientSecret } = useBillingClientSecret(billable)

  if (!clientSecret) return null

  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom>
          Choose how you would like to connect the account we should bill for <strong>{billable.name}</strong>
        </Typography>
        <Box pb={3}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            {!isBankSelected && (
              <PaymentCardOption
                billable={billable}
                onSuccess={onSuccess}
                expanded={expandedOption === 'card'}
                onChange={(_, isExpanded) => setExpandedOption(isExpanded ? 'card' : null)}
              />
            )}
            <BankAccountOption
              billable={billable}
              clientSecret={clientSecret}
              onSuccess={() => {
                onSuccess()
                setIsBankSelected(false) // once new bank account is connected, allow card entry again
              }}
              expanded={expandedOption === 'bank_credentials' || isBankSelected}
              onChange={(_, isExpanded) =>
                setExpandedOption(isExpanded && !isBankSelected ? 'bank_credentials' : null)
              }
              onBankSelected={() => setIsBankSelected(true)} // bank connected but ACH mandate not accepted
            />
          </Elements>
        </Box>
      </Container>
    </>
  )
}
