export const ONE_DAY_MS = 1000 * 60 * 60 * 24

/**
 * Total number of whole days starting from `start` and ending on `end`, inclusive
 */
export const daysBetweenDates = (start: string | Date, end: string | Date) => {
  const startDate = typeof start === 'string' ? new Date(start) : start
  const endDate = typeof end === 'string' ? new Date(end) : end
  return Math.floor((endDate.getTime() - startDate.getTime()) / ONE_DAY_MS)
}

/**
 * calculate the datetime that is the supplied number of days from the supplied date
 * - duration may be positive or negative
 */
export const daysFromDate = (date: Date, days: number) => new Date(date.getTime() + days * ONE_DAY_MS)

/**
 * convenience method to get the last day of the month (i.e. 28, 29, 30, or 31)
 * Ensures time zone does not affect the calculation by using UTC methods.
 */
export const getLastDayOfMonth = (date: Date = new Date()): number => {
  const year = date.getUTCFullYear() // Get the year in UTC
  const month = date.getUTCMonth() // Get the month in UTC

  // Create a new Date object in UTC for the last day of the given month
  const newDate = new Date(Date.UTC(year, month + 1, 0))

  return newDate.getUTCDate() // Return the day of the month in UTC
}
