import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { SetBankingAccountMutation, useSetBankingAccountMutation } from './gql/setBankingAccount_gen'
import { SetBankingAccountInput } from '../../graphql/generated.types'
import { ConfirmBankAccountSetupResponse } from '../../externalServices/stripe/useConfirmBankAccount'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'

type UseSetBankingAccountMutationOptions = UseGqlMutationCallbacks<
  SetBankingAccountMutation,
  ConfirmBankAccountSetupResponse
>
export const useSetBankingAccount = (
  owner: Pick<SetBankingAccountInput, 'ownerId' | 'ownerType'>,
  invalidationKeys: Array<QueryKey>,
  mutationOptions?: UseSetBankingAccountMutationOptions
) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    invalidationKeys
  )
  const { mutateAsync: setBankingAccount } = useSetBankingAccountMutation()
  return useMutation(
    ['setBankingAccount'],
    (confirmationResponse) => setBankingAccount({ input: { ...owner, ...confirmationResponse } }),
    mutationOptionsWithInvalidation
  )
}
