import { InfoDrawer } from '@alice-financial/pretext-ui'

import * as React from 'react'

const InfoContent = () => (
  <iframe
    src="https://scribehow.com/embed/Alice_Card_Know_Your_Customer_compliance_process_with_Stripe__CynGvXXRQ8-zOPlskckhOg"
    width="600"
    height="680"
    allowFullScreen
    style={{ border: 'none' }}
  ></iframe>
)

export const SetupWalkthrough = () => (
  <InfoDrawer anchorName="alice-card-walkthrough" infoContent={<InfoContent />} modal noIcon>
    View the Alice Card Setup step-by-step guide
  </InfoDrawer>
)
