import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@alice-financial/pretext-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Elements } from '@stripe/react-stripe-js'
import * as React from 'react'
import { ACHMandate } from '../../../externalServices/stripe/ACHMandate'
import { ConnectBankAccountForm } from '../../../externalServices/stripe/ConnectBankAccountForm'
import { useIssuingStripe } from '../../../externalServices/stripe/useLoadStripe'
import { ConnectACHForm } from './ConnectACHForm'
import { useStripeSetupIntentSecretQuery } from './gql/stripeSetupIntentSecret_gen'
import { TypedBankableEntity } from './types'
import { useCreateAliceCardPaymentMethod } from './useCreateAliceCardPaymentMethod'

type ConnectPaymentProps = {
  clientSecret: string
  onSuccess: () => void
}

const ACHOption = ({ clientSecret, onSuccess }: ConnectPaymentProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Provide account &amp; routing numbers
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ConnectACHForm clientSecret={clientSecret} onSuccess={onSuccess} />
    </AccordionDetails>
  </Accordion>
)

const BankAccountOption = ({ clientSecret, onSuccess }: ConnectPaymentProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Log in to verify
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ConnectBankAccountForm clientSecret={clientSecret} onSuccess={onSuccess} />
    </AccordionDetails>
  </Accordion>
)

type AddNewPaymentMethodProps = {
  onSuccess?: () => void
  entity: TypedBankableEntity
}
/**
 * When a new payment method needs to be connected to fund Alice Card, this component allows
 * the admin to choose between:
 * 1. Directly connect ACH using account and routing numbers
 * 2. Start the Stripe flow to connect a bank account
 *
 * Both options then require the user to accept the ACH mandate
 */
export const AddNewPaymentMethod = ({ onSuccess, entity }: AddNewPaymentMethodProps) => {
  const [showACHMandate, setShowACHMandate] = React.useState(false)
  const { mutateAsync: createPaymentMethod } = useCreateAliceCardPaymentMethod(entity, { onSuccess })
  const { data: stripeSetupIntentSecretData } = useStripeSetupIntentSecretQuery()
  const companyAccount = stripeSetupIntentSecretData?.organization?.aliceCardProgram.companyAccount
  const clientSecret = companyAccount?.stripeSetupIntentSecret
  const stripeAccount = companyAccount?.stripeAccountId
  const stripePromise = useIssuingStripe({ stripeAccount })

  if (!clientSecret || !stripeAccount) return null // should probably show loading UI (suspense?)

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      {showACHMandate ? (
        <ACHMandate
          clientSecret={clientSecret}
          handleConfirmationResponse={createPaymentMethod}
          invalidationKeys={[useStripeSetupIntentSecretQuery.getKey()]}
        />
      ) : (
        <>
          <ACHOption clientSecret={clientSecret} onSuccess={() => setShowACHMandate(true)} />
          <BankAccountOption clientSecret={clientSecret} onSuccess={() => setShowACHMandate(true)} />
        </>
      )}
    </Elements>
  )
}
