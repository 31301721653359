import { useQueryUser } from './useQueryUser'

/**
 * Hook to determine whether the current user should be considered a 'demo' user,
 * using parallel logic to the BE definition of `is_interactive_demo`
 *
 * @see https://github.com/alice-financial/alice/blob/32b9494c2211d57c9ce05cb7c7d9a62243467b1c/app/services/fake/interactive_demo_service.rb#L18-L24
 */
export const useIsDemoUser = () => {
  const { data: user } = useQueryUser()
  if (process.env.APP_ENV === 'production') return false

  return Boolean(user?.employee_profile?.employer?.name.toLowerCase().includes('interactive'))
}
