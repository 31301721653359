import { ActionButtonProps, CancelButton, EditButton, SubmitButton } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AddressDisplay, AddressLoading } from './AddressDisplay'
import { AddressInput } from './AddressInput'
import { AddressFormError } from './AddressInputError'
import { useHeadlessAddressEdit } from './useHeadlessAddressEdit'
import { FormattedMessage } from 'react-intl'

type UseAddressComponentsOptions = {
  doVerify?: boolean
  actionButtonProps?: ActionButtonProps
}
/**
 * A hook to determine the rendered components for editing an address
 */
export const useAddressComponents = ({
  doVerify,
  actionButtonProps: actionProps = {},
}: UseAddressComponentsOptions = {}) => {
  const {
    address,
    contenteditable,
    control,
    error,
    isLoading,
    isSubmitting,
    isSubmitDisabled,
    onCancel,
    onEdit,
    onSubmit,
  } = useHeadlessAddressEdit({ doVerify })

  const hasExistingAddress = Boolean(address.line_1)
  const isPendingVerification = Boolean(doVerify && !address.verified_at)

  const action = (
    <span>
      {!isPendingVerification && contenteditable && !isSubmitting && hasExistingAddress && (
        <CancelButton onClick={onCancel} {...actionProps} />
      )}{' '}
      {!contenteditable && !isSubmitting ? (
        <EditButton onClick={onEdit} {...actionProps} />
      ) : (
        <SubmitButton disabled={isSubmitDisabled} loading={isSubmitting} {...actionProps}>
          {doVerify ? <FormattedMessage id="common.verify" /> : <FormattedMessage id="common.save" />}
        </SubmitButton>
      )}
    </span>
  )

  const content = (isLoading && <AddressLoading gutterBottom />) ||
    ((!contenteditable || isSubmitting) && <AddressDisplay address={address} gutterBottom />) || (
      <AddressInput control={control} />
    )

  return {
    error: error && <AddressFormError error={error} />,
    content,
    action,
    onSubmit,
    contenteditable,
  }
}
