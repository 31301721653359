import { HealthcheckItem, InertHealthcheckItem, InertHealthcheckItemProps } from '@alice-financial/pretext-ui'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import * as React from 'react'
import { useLocation } from 'react-router'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus } from '../../graphql/generated.types'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'

const ItemDisplay = (props: InertHealthcheckItemProps) => {
  const location = useLocation()
  if (location.pathname === '/manage/alice-card-program') {
    return <InertHealthcheckItem {...props} />
  }
  return <HealthcheckItem {...props} to="/manage/alice-card-program" />
}

type AliceCardProgramHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}
export const AliceCardProgramHealthcheck = ({ org }: AliceCardProgramHealthcheckProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  if (!org) return null
  const { onboardingState } = org
  if (
    !onboardingState.isAlreadyLaunched &&
    (!onboardingState.isPayrollConnectionComplete || !onboardingState.isLogoPresent)
  ) {
    return null
  }
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  const fundingConnected = org.aliceCardProgram.hasFundingConnected
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking || org.employers.length === 1)

  if (!kycComplete) {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Client onboarding incomplete"
        secondary="Company representative information required"
      />
    )
  }
  if (!fundingConnected) {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Client onboarding incomplete"
        secondary={
          hasUnifiedBanking
            ? 'Alice Card funding account must be connected'
            : 'Alice Card funding account for each pay group must be connected'
        }
        icon={<MoneyOffIcon color="warning" fontSize="large" />}
      />
    )
  }
  return (
    <ItemDisplay
      status="complete"
      primary="Alice Card issuing is active"
      secondary={hasUnifiedBanking ? 'Payroll bank account connected' : 'Payroll bank accounts are connected'}
    />
  )
}
