import { InlineSkeleton, Link, Section, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatDollar } from '../../../utils/formatters/moneyFormat'
import { useQueryUser } from '../../user'

type SavingsSummaryProps = {
  rawSavingsAmount: number // dollar amount
  employerName: string | undefined
}
const SavingsSummary = ({ rawSavingsAmount, employerName }: SavingsSummaryProps) => {
  const savings = formatDollar(rawSavingsAmount)
  return (
    <Typography gutterBottom fontWeight="normal">
      <FormattedMessage
        id="employee_dashboard.main.pretax_spending_savings_details"
        values={{
          employer: () => employerName || <InlineSkeleton width="7em" />,
          savings: () => (
            <Typography variant="h2" component="span">
              <Link to="/spending">{savings}</Link>
            </Typography>
          ),
        }}
      />
    </Typography>
  )
}

export const HomepageSavingsSection = () => {
  const { data: user } = useQueryUser()
  const employee = user?.employee_profile
  const rawSavingsAmount = employee?.enrollments.total_estimated_pretax_savings_dollars

  // if savings is not available _or zero_, don't show the section
  if (!rawSavingsAmount) return null

  return (
    <Section>
      <SectionHeading>
        <FormattedMessage id="employee_dashboard.main.savings" />
      </SectionHeading>

      <SavingsSummary
        rawSavingsAmount={rawSavingsAmount}
        employerName={employee?.employer?.marketing_friendly_name}
      />
    </Section>
  )
}
