import * as Types from '../generated.types'

export type EmployeeInfoFragment = {
  __typename?: 'Employee'
  id: number
  employmentStartDate?: string | null
  role: Types.EmployeeRole
  enrollmentInfo?: { __typename?: 'EmployeeEnrollment'; accountStatus: Types.EmployeeAccountStatus } | null
  user: {
    __typename?: 'User'
    id: number
    email?: string | null
    firstName?: string | null
    lastName?: string | null
  }
  employerInfo: { __typename?: 'EmployerInfo'; id: number; name?: string | null }
}

export const EmployeeInfoFragmentDoc = `
    fragment EmployeeInfo on Employee {
  id
  employmentStartDate
  role
  enrollmentInfo {
    accountStatus
  }
  user {
    id
    email
    firstName
    lastName
  }
  employerInfo {
    id
    name
  }
}
    `
