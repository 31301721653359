import { ActionLayout, Button } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { EmploymentStartDateInputController } from './EmploymentStartDateInput'
import { useCompleteEnrollmentRequirements } from './useCompleteEnrollmentRequirements'
import { Navigate } from 'react-router'

/**
 * This form handles input for any user data that has not been set previously. Currently
 * it handles
 *
 * 1. Setting a password
 * 2. Sending us a start date, if known
 */
export const CompleteEnrollmentRequirements = () => {
  const { isComplete, onSubmit, control, watch, setValue, isLoading, isSuccess } =
    useCompleteEnrollmentRequirements()

  const alreadyStarted = watch('already_started')
  const setAlreadyStarted = (value: boolean) => setValue('already_started', value)
  const disabled = isLoading || isSuccess

  if (isComplete) return <Navigate to="/" />

  return (
    <form onSubmit={onSubmit} data-testid="complete-enrollment">
      <EmploymentStartDateInputController
        control={control}
        alreadyStartedState={[alreadyStarted, setAlreadyStarted]}
      />

      <ActionLayout
        primary={
          <Button variant="contained" fullWidth type="submit" disabled={disabled}>
            <FormattedMessage id="enroll.summary.continue_label" />
          </Button>
        }
      />
    </form>
  )
}
