import * as Types from '../../../graphql/generated.types'

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfoFragment_gen'
import {
  BankingTransactionFragmentDoc,
  ReimbursementClaimFragmentDoc,
} from '../../../graphql/fragments/BankingTransactionFragment_gen'
import { BankingAccountFragmentDoc } from '../../../graphql/fragments/BankingAccountFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type BankingTransactionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>
  after?: Types.InputMaybe<Types.Scalars['String']['input']>
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>
  before?: Types.InputMaybe<Types.Scalars['String']['input']>
  confirmationStatus?: Types.InputMaybe<Types.ConfirmationStatusValue>
}>

export type BankingTransactionsQuery = {
  __typename?: 'Query'
  bankingTransactions: {
    __typename?: 'BankingTransactionConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
    nodes?: Array<{
      __typename?: 'BankingTransaction'
      id: string
      amount: number
      date: string
      estimatedSavings: number
      name: string
      spendEligibility: {
        __typename?: 'SpendEligibility'
        confirmedPartialAmount?: number | null
        pretaxCategory: Types.PretaxCategory
        autoConfirmedAt?: string | null
        isDisconfirmable?: boolean | null
        confirmationStatus: {
          __typename?: 'ConfirmationStatus'
          updatedAt?: string | null
          value: Types.ConfirmationStatusValue
        }
      }
      account:
        | {
            __typename: 'BankingAccount'
            id: number
            name?: string | null
            cardType?: Types.BankCardType | null
            accountType?: Types.BankingAccountType | null
            platform: Types.BankingAccountPlatform
            accountLast4?: string | null
          }
        | { __typename: 'CashPaymentAccount' }
      reimbursementClaim?: {
        __typename?: 'ReimbursementClaim'
        approvalStatus: Types.ReimbursementClaimApprovalStatus
        reviewedAt?: string | null
      } | null
      reimbursementStatus?: { __typename?: 'ReimbursementStatus'; isFullyReimbursed: boolean } | null
    } | null> | null
  }
}

export const BankingTransactionsDocument = `
    query BankingTransactions($first: Int, $after: String, $last: Int, $before: String, $confirmationStatus: ConfirmationStatusValue) {
  bankingTransactions(
    first: $first
    after: $after
    last: $last
    before: $before
    confirmationStatus: $confirmationStatus
  ) {
    pageInfo {
      ...PageInfo
    }
    nodes {
      ...BankingTransaction
    }
  }
}
    ${PageInfoFragmentDoc}
${BankingTransactionFragmentDoc}
${BankingAccountFragmentDoc}
${ReimbursementClaimFragmentDoc}`

export const useBankingTransactionsQuery = <TData = BankingTransactionsQuery, TError = Error>(
  variables?: BankingTransactionsQueryVariables,
  options?: UseQueryOptions<BankingTransactionsQuery, TError, TData>
) => {
  return useQuery<BankingTransactionsQuery, TError, TData>(
    variables === undefined ? ['BankingTransactions'] : ['BankingTransactions', variables],
    fetchGql<BankingTransactionsQuery, BankingTransactionsQueryVariables>(
      BankingTransactionsDocument,
      variables
    ),
    options
  )
}

useBankingTransactionsQuery.getKey = (variables?: BankingTransactionsQueryVariables) =>
  variables === undefined ? ['BankingTransactions'] : ['BankingTransactions', variables]
