import { UseRestMutationCallbacks, fetchRest } from '@alice-financial/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useAnalytics } from '../telemetry/AnalyticsProvider'

type UseLogoutMutationOptions = UseRestMutationCallbacks<void, void>

/**
 * A hook that will return a mutation for clearing the session on the server. This
 * should be used whenever a session is expired, or when the user logs out.
 *
 * *BE CAREFUL*: this will clear the session on the server, but it will not clear
 * any local state. If the user is logging out,
 *
 * Note that the _preferred_ way to logout a user is to send them to a dedicated
 * /logout route in the application
 */
export const useClearSession = (mutationOptions?: UseLogoutMutationOptions) =>
  useMutation(() => fetchRest<void>('/sign_out', { method: 'DELETE' }), mutationOptions)

/**
 * A hook that will end the current user session and clear local state.
 */
export const useLogout = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { mutate: logout, isLoading } = useClearSession({
    onSuccess: () => {
      queryClient.clear()
      analytics.reset() // clear analytics tracking IDs from local storage/cookies
      window.location.assign('/') // hard refresh to root of application
    },
  })
  React.useEffect(() => {
    if (isLoading) return
    logout()
  }, [logout, isLoading])
}
