import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CardsIcon from 'jsx:./assets/cards.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuerySpendingConnections } from '../employeeDashboard/cards/useQuerySpendingConnections'
import { CompleteEnrollmentRequirements } from './CompleteEnrollmentRequirements'
import {
  findActiveCardAccount,
  isAliceCardAccountBalanceGated,
} from '../employeeDashboard/cards/alice/aliceCardUtils'
import { getBestAliceCard } from '../employeeDashboard/cards/spendingConnectionUtils'

const ConnectedSpendingSummary = () => {
  const {
    data: { bankingConnections, aliceCardAccounts },
    isLoading,
  } = useQuerySpendingConnections()

  if (isLoading) return null
  const activeCardAccount = findActiveCardAccount(aliceCardAccounts)
  const bestAliceCard = getBestAliceCard(activeCardAccount?.aliceCards)
  const isBalanceGated = Boolean(aliceCardAccounts?.find(isAliceCardAccountBalanceGated))
  const hasAliceCard = Boolean(bestAliceCard) || isBalanceGated

  const connectionsCount = bankingConnections.length + Number(hasAliceCard)

  return (
    <List disablePadding>
      <ListItem disablePadding>
        <ListItemIcon>
          <CardsIcon width={40} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" fontWeight="bold">
            {connectionsCount === 1 ? (
              <FormattedMessage id="connections.connection_count_singular" />
            ) : (
              <FormattedMessage id="connections.connection_count_plural" values={{ connectionsCount }} />
            )}
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="connections.connect_more_accounts"
              values={{
                'connect-link': (...chunks) => (
                  <strong>
                    <Link to="/cards" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      {chunks} <ChevronRightIcon />
                    </Link>
                  </strong>
                ),
              }}
            />
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  )
}

/**
 * This component should be displayed when a user completes enrollment
 */
export const NewEnrollmentSummary = () => {
  return (
    <PageBody>
      <Container>
        <Typography variant="h2" gutterBottom>
          <FormattedMessage id="enroll.summary.all_set" />
        </Typography>

        <Typography gutterBottom>
          <FormattedMessage id="enroll.summary.start_spending" />
        </Typography>

        <ConnectedSpendingSummary />

        <Box mt={6}>
          <CompleteEnrollmentRequirements />
        </Box>
      </Container>
    </PageBody>
  )
}
