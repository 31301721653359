import { composeMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import {
  ActionLayout,
  Button,
  Container,
  InlineSkeleton,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { Navigate, useNavigate } from 'react-router'
import { daysFromDate } from '../../../../../../utils/dateUtils'
import { dateStringFormat } from '../../../../../../utils/formatters/dateFormat'
import {
  BankingAccountPlatform,
  BankingConnectionStatus,
  CardShipmentStatus,
} from '../../../../../graphql/generated.types'
import { BankingConnectionFragment } from '../../../gql/connectedCardsQuery_gen'
import { useDisconnectConnectionMutation } from '../../../gql/disconnectConnection_gen'
import { useQuerySpendingConnections } from '../../../useQuerySpendingConnections'
import { ReconnectOMNYForm } from './ConnectOMNYCard'
import { useUpdateOmnyCardShipmentMutation } from './gql/updateOMNYCardShipment_gen'

/**
 * An OMNY card can be
 * 1. `activeCardShipment.shippedAt: null` == "not yet shipped"
 * 2. `activeCardShipment.shippedAt: ISO8601Date` == "shipped on <date>"
 * 3. `status: connected` == "received/connected"
 */
export const getOMNYStatus = (bankingConnection: BankingConnectionFragment) => {
  if (bankingConnection.status === BankingConnectionStatus.ConnectionError) {
    return 'connection_error'
  }
  if (bankingConnection.status === BankingConnectionStatus.Connected) {
    return 'connected'
  }
  if (bankingConnection.status === BankingConnectionStatus.Pending) {
    const shipment = bankingConnection.activeCardShipment
    if (shipment?.shippedAt) {
      return 'shipped'
    }
    return 'pending_shipment'
  }
  return ''
}
export const getOMNYStatusLabel = (bankingConnection: BankingConnectionFragment, intl: IntlShape) => {
  const status = getOMNYStatus(bankingConnection)
  switch (status) {
    case 'connection_error':
      return intl.formatMessage({ id: 'cards.omny.status.connection_error' })
    case 'connected':
      return intl.formatMessage({ id: 'cards.omny.status.connected' })
    case 'pending_shipment':
      return intl.formatMessage({ id: 'cards.omny.status.pending' })
    case 'shipped': {
      const shippedAt = bankingConnection?.activeCardShipment?.shippedAt
      if (!shippedAt) return ''
      const shippedDate = dateStringFormat.medium(shippedAt || '')
      return intl.formatMessage({ id: 'cards.omny.status.shipped' }, { shippedDate })
    }
    default:
      return ''
  }
}

const getOMNYConnection = (bankingConnections: Array<BankingConnectionFragment>) =>
  bankingConnections.find((conn) => conn.platform === BankingAccountPlatform.Omny)

const useOMNYConnection = () => {
  // load user's existing connections to see if any match the selected institution
  const {
    data: { bankingConnections },
    ...queryState
  } = useQuerySpendingConnections()

  return {
    data: getOMNYConnection(bankingConnections),
    ...queryState,
  }
}

const OMNYAction = ({ bankingConnection }: { bankingConnection?: BankingConnectionFragment }) => {
  const navigate = useNavigate()
  const mutationOptions = useMutationOptionsWithInvalidation({}, [useQuerySpendingConnections.getKey()])
  const { mutate: updateOMNYCardShipment } = useUpdateOmnyCardShipmentMutation(mutationOptions)
  const { mutate: disconnect } = useDisconnectConnectionMutation(
    composeMutationCallbacks(mutationOptions, { onSuccess: () => navigate('../') })
  )

  if (!bankingConnection) return null
  const status = getOMNYStatus(bankingConnection)

  const reportStatus = (status: CardShipmentStatus) => {
    const shipmentId = bankingConnection.activeCardShipment?.id
    if (!shipmentId) throw new Error('No shipment ID available')
    updateOMNYCardShipment({ input: { shipmentId, status } })
  }

  switch (status) {
    case 'connected':
      return (
        <ActionLayout
          primary={
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => disconnect({ input: { connectionId: bankingConnection.id } })}
            >
              <FormattedMessage id="connections.disconnect.label" />
            </Button>
          }
        />
      )
    case 'shipped':
    case 'pending_shipment': {
      const shippedAt = bankingConnection.activeCardShipment?.shippedAt
      const today = new Date()

      // a week after shipping, the user may report missing
      const mayReportMissing = shippedAt && new Date(shippedAt) < daysFromDate(today, -7)
      return (
        <>
          {!mayReportMissing && (
            <Typography>
              <FormattedMessage id="cards.omny.shipping_time" />
            </Typography>
          )}
          <ActionLayout
            primary={
              <Button
                fullWidth
                variant="contained"
                onClick={() => reportStatus(CardShipmentStatus.Received)}
                disabled={status === 'pending_shipment'}
              >
                <FormattedMessage id="cards.omny.received_label" />
              </Button>
            }
            secondary={
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => reportStatus(CardShipmentStatus.Missing)}
                disabled={!mayReportMissing}
              >
                <FormattedMessage id="cards.omny.report_missing_label" />
              </Button>
            }
          />
        </>
      )
    }
    case 'connection_error':
      return <ReconnectOMNYForm connectionId={bankingConnection.id} />
  }
  return null
}

export const OMNYConnectionDetail = () => {
  const intl = useIntl()
  const { data: omnyConnection, isLoading } = useOMNYConnection()

  if (!isLoading && !omnyConnection) return <Navigate to="../connect/omny" replace />

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          OMNY
        </Typography>
        <Typography gutterBottom>
          <strong>Status:</strong>{' '}
          {omnyConnection ? getOMNYStatusLabel(omnyConnection, intl) : <InlineSkeleton width="10em" />}
        </Typography>

        <OMNYAction bankingConnection={omnyConnection} />
      </Container>
    </PageBody>
  )
}
