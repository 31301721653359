import {
  Box,
  FormLabel,
  GridFilterModel,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useFilter } from './useFilter'

const isString = (value: string): value is string => typeof value === 'string'
export const useSearchFilter = () => {
  const [searchValues, updateSearchParam] = useFilter('search', { typeGuard: isString })
  const updateSearchValues = (filterModel: GridFilterModel) => {
    const searchTerms = filterModel.quickFilterValues?.filter((term) => typeof term === 'string')
    updateSearchParam(searchTerms?.join(','))
  }

  return [searchValues?.split(',').map((s) => s.trim()), updateSearchValues] as const
}

export const EmployeeSearchFilter = (props: GridToolbarProps['quickFilterProps']) => {
  return (
    <Box display="flex" flexDirection="column">
      <FormLabel>Name &amp; email</FormLabel>
      <GridToolbarQuickFilter variant="outlined" debounceMs={500} {...props} />
    </Box>
  )
}
