import { Typography } from '@alice-financial/pretext-ui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AliceCardIllustration from 'jsx:./assets/alice-card-illustration.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { AliceCard } from '../../../graphql/generated.types'
import { CardConnectCardProps, SpendingConnectCard } from '../SpendingConnectCard'
import { useAliceCardFeatureEnabled, useQuerySpendingConnections } from '../useQuerySpendingConnections'
import {
  findActiveCardAccount,
  getAvailableInstantAliceCard,
  getAvailablePhysicalAliceCard,
  isAliceCardAccountBalanceGated,
  isAliceCardRequestPending,
} from './aliceCardUtils'

const CardRoot = (props: Omit<CardConnectCardProps, 'Card'>) => (
  <SpendingConnectCard data-testid="connect-alice" {...props} Card={AliceCardIllustration} />
)

const NewAliceCardRequest = () => (
  <CardRoot
    to="/cards/alice"
    label={
      <>
        <Typography>
          <FormattedMessage id="cards.alice.get_an_alice_card" />
        </Typography>
        <ChevronRightIcon fontSize="inherit" />
      </>
    }
  >
    <Typography variant="body2">
      <FormattedMessage id="cards.alice.use_card_instead_paying_from_pocket" />
    </Typography>
  </CardRoot>
)

const AliceCardRequested = () => (
  <CardRoot
    to="/cards/alice"
    label={
      <Typography gutterBottom>
        <CheckCircleIcon color="primary" sx={{ verticalAlign: 'middle' }} />{' '}
        <FormattedMessage id="cards.alice.youre_getting_an_alice_card" />
      </Typography>
    }
  >
    <Typography variant="body2">
      <FormattedMessage id="cards.alice.about_card_arrival" />
    </Typography>
  </CardRoot>
)

const AliceCardBalanceGated = () => (
  <CardRoot
    to="/cards/alice"
    label={
      <Typography>
        <CheckCircleIcon color="primary" sx={{ verticalAlign: 'middle' }} />{' '}
        <FormattedMessage id="cards.alice.youre_getting_an_alice_card" />
      </Typography>
    }
  >
    <Typography variant="body2">
      <FormattedMessage id="cards.alice.balance_gated_account_info" />
    </Typography>
  </CardRoot>
)

type AliceCardReceivedProps = {
  instantCard?: Pick<AliceCard, 'last4'>
  physicalCard?: Pick<AliceCard, 'last4'>
}
const AliceCardReceived = ({ instantCard, physicalCard }: AliceCardReceivedProps) => {
  const card = instantCard || physicalCard
  if (!card) return null
  return (
    <CardRoot
      to="/cards/alice"
      label={
        <Typography>
          <CheckCircleIcon color="primary" sx={{ verticalAlign: 'middle' }} />{' '}
          <FormattedMessage id="cards.alice.your_card_is_ready" />
        </Typography>
      }
    >
      <Typography variant="body2">
        <FormattedMessage id="cards.alice.a_card_is_ready" values={{ last4: card.last4 }} />.
      </Typography>
    </CardRoot>
  )
}

export const AliceCardConnectCard = () => {
  const {
    data: { aliceCardAccounts },
  } = useQuerySpendingConnections()
  const isAliceCardFeatureEnabled = useAliceCardFeatureEnabled()
  const activeCardAccount = findActiveCardAccount(aliceCardAccounts)
  const isBalanceGated = Boolean(aliceCardAccounts?.find(isAliceCardAccountBalanceGated))

  if (!isAliceCardFeatureEnabled) return null

  const instantCard = activeCardAccount && getAvailableInstantAliceCard(activeCardAccount.aliceCards)
  const physicalCard = activeCardAccount && getAvailablePhysicalAliceCard(activeCardAccount.aliceCards)

  if (instantCard || physicalCard) {
    return <AliceCardReceived instantCard={instantCard} physicalCard={physicalCard} />
  }

  // no available cards - check if one was requested
  if (isAliceCardRequestPending(activeCardAccount)) return <AliceCardRequested />
  if (isBalanceGated) return <AliceCardBalanceGated />

  return <NewAliceCardRequest />
}
