import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { useQueryUser } from '../../user'
import { EmployeeProfile } from '../../user/types'

const getOffboardDate = (employeeProfile?: EmployeeProfile) => {
  const offboardingDate = employeeProfile?.employer?.offboarding_date
  if (!offboardingDate) return null

  return dateStringFormat.dateFromDateString(offboardingDate)
}

export const useOffboardDate = () => {
  const { data: user } = useQueryUser()
  if (user === undefined) return undefined
  if (user === null) return null

  return getOffboardDate(user.employee_profile)
}
