import { Alert } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQueryUser } from '../../../user/useQueryUser'

/**
 * This alert signals to the EEs that we're auto-confirming their expenses.
 */
export const HomepageAutoConfirmationAlert: React.FC = () => {
  const { data: user } = useQueryUser()

  if (!user) return null

  if (!user.has_recent_auto_confirmed_txns) return null

  return (
    <Alert severity="info">
      <FormattedMessage id="employee_dashboard.auto_confirmation_alert" />
    </Alert>
  )
}
