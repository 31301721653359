import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EmployeeEnrollmentInfoQueryVariables = Types.Exact<{ [key: string]: never }>

export type EmployeeEnrollmentInfoQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    firstName?: string | null
    lastName?: string | null
    preferredName?: string | null
    hasDummyPassword: boolean
    birthdate?: string | null
    email?: string | null
    tel?: { __typename?: 'Telephone'; nationalNumber: string; isSmsConfirmed?: boolean | null } | null
  } | null
  employee?: {
    __typename?: 'Employee'
    id: number
    tosAcceptedAt?: string | null
    hasAcceptedAliceCardTos: boolean
    employmentStartDate?: string | null
    aliceCardAccount?: {
      __typename?: 'AliceCardAccount'
      id: number
      status: Types.AliceCardAccountStatus
      aliceCardEnrollment?: { __typename?: 'AliceCardEnrollment'; tosAcceptedAt?: string | null } | null
    } | null
    enrollmentInfo?: {
      __typename?: 'EmployeeEnrollment'
      enrollmentDate?: string | null
      accountStatus: Types.EmployeeAccountStatus
      registrationBlocker?: Types.EmployeeRegistrationBlocker | null
      enrollmentBlocker?: Types.EmployeeEnrollmentBlocker | null
    } | null
    employerInfo: { __typename?: 'EmployerInfo'; id: number; marketingFriendlyName?: string | null }
  } | null
}

export const EmployeeEnrollmentInfoDocument = `
    query EmployeeEnrollmentInfo {
  currentUser {
    id
    firstName
    lastName
    preferredName
    hasDummyPassword
    birthdate
    email
    tel {
      nationalNumber
      isSmsConfirmed
    }
  }
  employee {
    id
    tosAcceptedAt
    hasAcceptedAliceCardTos
    employmentStartDate
    aliceCardAccount {
      id
      status
      aliceCardEnrollment {
        tosAcceptedAt
      }
    }
    enrollmentInfo {
      enrollmentDate
      accountStatus
      registrationBlocker
      enrollmentBlocker
    }
    employerInfo {
      id
      marketingFriendlyName
    }
  }
}
    `

export const useEmployeeEnrollmentInfoQuery = <TData = EmployeeEnrollmentInfoQuery, TError = Error>(
  variables?: EmployeeEnrollmentInfoQueryVariables,
  options?: UseQueryOptions<EmployeeEnrollmentInfoQuery, TError, TData>
) => {
  return useQuery<EmployeeEnrollmentInfoQuery, TError, TData>(
    variables === undefined ? ['EmployeeEnrollmentInfo'] : ['EmployeeEnrollmentInfo', variables],
    fetchGql<EmployeeEnrollmentInfoQuery, EmployeeEnrollmentInfoQueryVariables>(
      EmployeeEnrollmentInfoDocument,
      variables
    ),
    options
  )
}

useEmployeeEnrollmentInfoQuery.getKey = (variables?: EmployeeEnrollmentInfoQueryVariables) =>
  variables === undefined ? ['EmployeeEnrollmentInfo'] : ['EmployeeEnrollmentInfo', variables]
