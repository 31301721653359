import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type RegisterEmployeeMutationVariables = Types.Exact<{
  input: Types.RegisterEmployeeInput
}>

export type RegisterEmployeeMutation = {
  __typename?: 'Mutation'
  registerEmployee?: {
    __typename?: 'RegisterEmployeePayload'
    employee?: {
      __typename?: 'Employee'
      id: number
      user: {
        __typename?: 'User'
        id: number
        tel?: {
          __typename?: 'Telephone'
          nationalNumber: string
          isSmsConfirmed?: boolean | null
          isConfirmationCodeSent?: boolean | null
        } | null
      }
    } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const RegisterEmployeeDocument = `
    mutation RegisterEmployee($input: RegisterEmployeeInput!) {
  registerEmployee(input: $input) {
    employee {
      id
      user {
        id
        tel {
          nationalNumber
          isSmsConfirmed
          isConfirmationCodeSent
        }
      }
    }
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useRegisterEmployeeMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<RegisterEmployeeMutation, TError, RegisterEmployeeMutationVariables, TContext>
) => {
  return useMutation<RegisterEmployeeMutation, TError, RegisterEmployeeMutationVariables, TContext>(
    ['RegisterEmployee'],
    (variables?: RegisterEmployeeMutationVariables) =>
      fetchGql<RegisterEmployeeMutation, RegisterEmployeeMutationVariables>(
        RegisterEmployeeDocument,
        variables
      )(),
    options
  )
}
