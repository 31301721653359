import {
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import { PaymentMethodFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { AddNewPaymentMethod } from './AddNewPaymentMethod'
import { isConnectable } from './fundingUtils'
import { TypedBankableEntity } from './types'
import { useConnectPaymentMethod } from './useConnectPaymentMethod'

type ConnectablePaymentMethodListProps = {
  connectablePaymentMethods: Array<PaymentMethodFragment>
  entity: TypedBankableEntity
  onSuccess?: () => void
}

const ConnectablePaymentMethodList = ({
  connectablePaymentMethods,
  entity,
  onSuccess,
}: ConnectablePaymentMethodListProps) => {
  const { mutate: connectPaymentMethod } = useConnectPaymentMethod({ owner: entity }, { onSuccess })
  return (
    <Paper sx={{ mb: 2 }}>
      <List disablePadding>
        {(connectablePaymentMethods || []).map((method) => (
          <ListItem key={method.id} disablePadding disableGutters>
            <ListItemButton onClick={() => connectPaymentMethod({ paymentMethodId: method.id })}>
              <ListItemIcon>
                <AddIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" fontWeight="bold" color="primary" width="100%">
                    {method.descriptor}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

type ConnectPaymentMethodProps = {
  entity: TypedBankableEntity
  availablePaymentMethods: Array<PaymentMethodFragment> | undefined | null
  currentPaymentMethod: PaymentMethodFragment | undefined
  onSuccess?: () => void
}

export const ConnectPaymentMethod = ({
  entity,
  availablePaymentMethods,
  currentPaymentMethod,
  onSuccess,
}: ConnectPaymentMethodProps) => {
  const { name } = entity
  const connectablePaymentMethods = (availablePaymentMethods || []).filter(
    isConnectable(currentPaymentMethod)
  )

  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom>
          Choose how you would like to verify the bank account that you use to withdraw payroll for{' '}
          <strong>{name}</strong>
        </Typography>
        {(connectablePaymentMethods || []).length > 0 && (
          <>
            <ConnectablePaymentMethodList
              connectablePaymentMethods={connectablePaymentMethods}
              entity={entity}
              onSuccess={onSuccess}
            />
            <Typography textAlign="center" fontWeight="bold">
              or
            </Typography>
          </>
        )}
      </Container>
      <Container sx={{ my: 2 }}>
        <AddNewPaymentMethod onSuccess={onSuccess} entity={entity} />
      </Container>
    </>
  )
}
