import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type DeleteBillingSourceMutationVariables = Types.Exact<{
  input: Types.DeleteBillingSourceInput
}>

export type DeleteBillingSourceMutation = {
  __typename?: 'Mutation'
  deleteBillingSource?: {
    __typename?: 'DeleteBillingSourcePayload'
    success?: boolean | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const DeleteBillingSourceDocument = `
    mutation DeleteBillingSource($input: DeleteBillingSourceInput!) {
  deleteBillingSource(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useDeleteBillingSourceMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteBillingSourceMutation,
    TError,
    DeleteBillingSourceMutationVariables,
    TContext
  >
) => {
  return useMutation<DeleteBillingSourceMutation, TError, DeleteBillingSourceMutationVariables, TContext>(
    ['DeleteBillingSource'],
    (variables?: DeleteBillingSourceMutationVariables) =>
      fetchGql<DeleteBillingSourceMutation, DeleteBillingSourceMutationVariables>(
        DeleteBillingSourceDocument,
        variables
      )(),
    options
  )
}
