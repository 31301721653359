{
  "name": "@alice-financial/user-web",
  "version": "1.0.0",
  "description": "A user-facing web application for Alice",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/alice-financial/web-apps.git"
  },
  "author": "",
  "license": "UNLICENSED",
  "bugs": {
    "url": "https://github.com/alice-financial/web-apps/issues"
  },
  "homepage": "https://github.com/alice-financial/web-apps#readme",
  "scripts": {
    "start": "parcel src/index.html --port=3001 --open --no-cache",
    "lint": "eslint \"src/**/*.{js,jsx,ts,tsx}\"",
    "format": "prettier --write \"src/**/*.{js,jsx,ts,tsx}\"",
    "build": "PARCEL_WORKER_BACKEND=process parcel build src/index.html && cp dist/v2/static/index.html dist/",
    "test": "jest",
    "typecheck": "tsc",
    "generate:graphql": "graphql-codegen --config src/graphql/codegen.yml && prettier --write \"src/**/{*_gen,generated.types}.ts\"",
    "generate:graphql:schema": "graphql-codegen --config src/graphql/codegen-schema.yml",
    "trn:export:file": "mkdir -p temp && ./scripts/trn-extract.ts > temp/trns.csv",
    "trn:export:copy": "./scripts/trn-extract.ts | pbcopy",
    "trn:import": "./scripts/trn-import.ts $TRN_SRC Spanish && ./scripts/trn-import.ts $TRN_SRC English"
  },
  "devDependencies": {
    "@alice-financial/jest-config": "*",
    "@alice-financial/tsconfig": "*",
    "@graphql-codegen/cli": "^5.0.2",
    "@graphql-codegen/near-operation-file-preset": "^3.0.0",
    "@graphql-codegen/schema-ast": "^4.0.2",
    "@graphql-codegen/typescript": "^4.0.6",
    "@graphql-codegen/typescript-operations": "^4.2.0",
    "@graphql-codegen/typescript-react-query": "^6.1.0",
    "@parcel/core": "^2.12.0",
    "@parcel/reporter-bundle-analyzer": "^2.12.0",
    "@parcel/transformer-graphql": "^2.12.0",
    "@parcel/transformer-inline-string": "^2.12.0",
    "@parcel/transformer-js": "^2.12.0",
    "@parcel/transformer-svg-react": "^2.12.0",
    "@testing-library/react": "^13.2.0",
    "@types/google.maps": "^3.55.9",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.11.30",
    "@types/webpack-env": "^1.18.4",
    "eslint-config-alice": "*",
    "graphql": "^16.8.1",
    "intl-messageformat": "^10.5.11",
    "lint-staged": "^15.2.2",
    "parcel": "^2.12.0",
    "parcel-resolver-ignore": "^2.1.5",
    "typescript": "^5.4.3"
  },
  "dependencies": {
    "@alice-financial/api": "*",
    "@alice-financial/pretext-ui": "*",
    "@datadog/browser-rum": "^5.12.0",
    "@datadog/datadog-ci": "^2.20.0",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@mui/icons-material": "^5.15.14",
    "@mui/material": "^5.15.14",
    "@segment/analytics-next": "^1.46.0",
    "@stripe/connect-js": "^3.3.7-beta-1",
    "@stripe/react-connect-js": "^3.3.11-beta-1",
    "@stripe/react-stripe-js": "^2.6.2",
    "@stripe/stripe-js": "^3.0.10",
    "@tanstack/react-query": "^4.26.1",
    "@tryfinch/react-connect": "^3.6.1",
    "@types/react": "18.0.28",
    "@types/react-dom": "18.0.11",
    "configcat-js": "^9.5.0",
    "connect-web-sdk": "^1.0.0-rc.5",
    "core-js": "^3.35.0",
    "memoize-one": "^6.0.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-error-boundary": "^4.0.11",
    "react-hook-form": "^7.43.5",
    "react-intl": "^6.6.3",
    "react-router": "^6.9.0",
    "react-router-dom": "^6.9.0"
  },
  "targets": {
    "default": {
      "distDir": "dist/v2/static",
      "publicUrl": "/v2/static",
      "outputFormat": "global",
      "engines": {
        "browsers": [
          ">0.5%",
          "not dead",
          "not op_mini all",
          "not ios_saf < 13",
          "not ie 11"
        ]
      }
    }
  }
}
