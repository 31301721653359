import { UseRestMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { useQueryClient } from '@tanstack/react-query'
import { isNotNull } from '../../../utils/typeUtils'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { BankingTransactionFragment } from '../../graphql/fragments/BankingTransactionFragment_gen'
import { ConfirmationStatusValue } from '../../graphql/generated.types'
import { useBankingTransactionQuery } from './graphql/bankingTransaction_gen'
import { BankingTransactionsQuery, useBankingTransactionsQuery } from './graphql/bankingTransactions_gen'
import { ConfirmationPayload } from './types'

const sortByDateReverse = (a: BankingTransactionFragment, b: BankingTransactionFragment) =>
  a.date < b.date ? 1 : -1

type BankingTransactionEntity = {
  id: string
  date: string // 'YYYY-MM-DD'
  confirmed: boolean
  confirmed_at: string
  confirmed_eligible_spend: boolean
  confirmed_partial_amount_dollars: number
}

const confirmTransaction = async (
  txnId: string,
  { confirmed_eligible_spend, confirmed_partial_amount }: ConfirmationPayload
) => {
  return fetchRest<BankingTransactionEntity>(`/api/user/transactions/${txnId}/confirm`, {
    method: 'PATCH',
    body: JSON.stringify({
      confirmed_eligible_spend,
      confirmed_partial_amount,
    }),
  })
}

const findTxn = (bankingTransactionsQuery: BankingTransactionsQuery, txnId: string) => {
  return bankingTransactionsQuery.bankingTransactions.nodes?.filter(isNotNull).find((txn) => txn.id === txnId)
}
/**
 * This hook provides an `onSuccess` handler for the banking transaction confirmation mutation that will
 * inject the response data into the query cache. This is an important performance optimization that
 * means the whole banking transaction list doesn't need to be refreshed on every confirmation, which some
 * users do multiple times per second in cases where they haven't accessed the app for awhile.
 */
const useResponseUpdater = () => {
  const queryClient = useQueryClient()
  return {
    /**
     * When confirmation is successful (even if it's a rejection), we need to update the cached txn
     * and modify any transaction lists that it should be added to or removed from.
     */
    onSuccess: (restTxn: BankingTransactionEntity) => {
      // find the cached version of the transaction - this should always exist, but technically doesn't have to
      // 1. collect all banking transaction query data - there may be multiple queries with different query variables in the cache
      const bankingTransactionData = queryClient.getQueriesData<BankingTransactionsQuery>(
        useBankingTransactionsQuery.getKey()
      )
      // 2. find one instance of the target txn anywhere in the cache
      const targetTxn = bankingTransactionData.reduce<BankingTransactionFragment | undefined>(
        (target, [_queryKey, queryValue]) => {
          if (!queryValue) return target
          return target || findTxn(queryValue, restTxn.id)
        },
        undefined
      )
      if (!targetTxn) return // we don't expect this to happen, but if it's not in the cache, no need to update anything

      const confirmationStatus = {
        value: restTxn.confirmed_eligible_spend
          ? ConfirmationStatusValue.Confirmed
          : ConfirmationStatusValue.Rejected,
        updatedAt: restTxn.confirmed_at,
      }
      const updatedSpendEligibility = {
        ...targetTxn.spendEligibility,
        confirmedPartialAmount: restTxn.confirmed_partial_amount_dollars,
        confirmationStatus,
      }
      // mutate the target txn in-place
      targetTxn.spendEligibility = updatedSpendEligibility

      bankingTransactionData.forEach(([[queryName, rawVariables], queryValue]) => {
        if (!queryValue) return // nothing to update
        // in order to ensure that the list is updated correctly,
        // we start by removing any instances of the target txn in the current list
        // and then re-insert it in the correct order where necessary
        queryValue.bankingTransactions.nodes = (queryValue.bankingTransactions.nodes || []).filter(
          (txn) => txn?.id !== targetTxn.id
        )

        const variables = !rawVariables || typeof rawVariables !== 'object' ? {} : rawVariables
        const matchesListConfirmationStatus =
          !('confirmationStatus' in variables) ||
          !variables.confirmationStatus ||
          variables.confirmationStatus === updatedSpendEligibility.confirmationStatus.value
        const isEarlierThanMostRecentDate =
          !('after' in variables) ||
          !variables.after ||
          (typeof variables.after === 'string' && targetTxn.date < variables.after)
        const isLaterThanOldestDate =
          !('before' in variables) ||
          !variables.before ||
          (typeof variables.before === 'string' && targetTxn.date > variables.before)
        const isInDateRange = isEarlierThanMostRecentDate && isLaterThanOldestDate

        if (matchesListConfirmationStatus && isInDateRange) {
          // txn is before the specified end date of list or there is no end date
          queryValue.bankingTransactions.nodes = queryValue.bankingTransactions.nodes
            .filter(isNotNull)
            .concat(targetTxn)
            .sort(sortByDateReverse)
        }
        // update the cache with the new list
        queryClient.setQueryData([queryName, variables], { ...queryValue })
      })
    },
  }
}
/**
 * A transaction-specific confirmation mutation
 */
export const useConfirmTransaction = (
  txnId: string,
  _mutationOptions?: UseRestMutationCallbacks<unknown, ConfirmationPayload>
) => {
  const { onSuccess: applyResponse } = useResponseUpdater()
  const { notifySuccess } = useResponseNotification()

  const mutationOptions = useMutationNotifier(
    {},
    {
      ..._mutationOptions,
      onSuccess: (...args: [BankingTransactionEntity, ConfirmationPayload, unknown]) => {
        const [bankingTransactionEntity, originalPayload] = args
        notifySuccess(
          originalPayload.confirmed_eligible_spend
            ? 'Transaction eligibility confirmed'
            : 'Transaction recorded as ineligible'
        )
        applyResponse(bankingTransactionEntity)
        return _mutationOptions?.onSuccess && _mutationOptions.onSuccess(...args)
      },
    }
  )
  return useMutationWithInvalidation(
    (confirmation: ConfirmationPayload) => confirmTransaction(txnId, confirmation),
    mutationOptions,
    [useBankingTransactionQuery.getKey({ id: txnId })]
  )
}
