import { Container, Dialog, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, useNavigate, useParams } from 'react-router'
import { BankingConnectionFragment } from '../../gql/connectedCardsQuery_gen'
import { useQuerySpendingConnections } from '../../useQuerySpendingConnections'
import { UnifiedBankingInstitution } from '../types'
import {
  UNSUPPORTED_UNIFIED_INSTITUTION,
  getIsConnectedToInstitution,
  isAvailable,
} from './bankingConnectionUtils'
import { UseCardConnectorConfig } from './types'
import { useConnectorConfig } from './useConnectorConfig'
import { useFinicityConnect } from './useFinicityConnect'
import { useGetUnifiedBankingInstitution } from './useGetBankingInstitution'

const FINICITY_CONNECT_ID = 'finicity-connect-dialog'

type FinicityConnectionProps = {
  bankingInstitution: UnifiedBankingInstitution
  existingConnection?: BankingConnectionFragment
  config: UseCardConnectorConfig
}
const FinicityConnection = ({ bankingInstitution, config }: FinicityConnectionProps) => {
  useFinicityConnect(bankingInstitution.platform_id, config, { selector: `#${FINICITY_CONNECT_ID}` })

  return (
    <Dialog
      data-testid="finicity-connect-display"
      open
      fullScreen
      PaperProps={{ id: FINICITY_CONNECT_ID, sx: { '& iframe': { width: '100%', height: '100%' } } }}
    />
  )
}

type ConnectFinicityBankProps = {
  existingConnection?: BankingConnectionFragment
  bankingInstitution: UnifiedBankingInstitution
  onComplete: () => void
}

/**
 * This component renders the connection interface for the supplied banking connection.
 * It should generally be rendered in an element that covers the whole page, because
 * both the Finicity and Plaid modals will cover overlay all other content.
 *
 * The parent component should determine whether connection/reconnection is actually
 * required.
 */
export const ConnectFinicityBank = ({ bankingInstitution, onComplete }: ConnectFinicityBankProps) => {
  const config = useConnectorConfig({ onComplete })
  if (bankingInstitution.platform !== 'finicity') {
    console.error(`${bankingInstitution.name} is not supported by Finicity`)
    // might as well try Plaid
    return <Navigate to="/cards/personal/connect/plaid" replace />
  }

  return <FinicityConnection bankingInstitution={bankingInstitution} config={config} />
}

export const ConnectFinicityBankRoute = () => {
  const navigate = useNavigate()
  const { bankingInstitutionId } = useParams()
  if (!bankingInstitutionId || bankingInstitutionId === UNSUPPORTED_UNIFIED_INSTITUTION) {
    // this shouldn't ever fire
    throw new Error('No banking institution ID available')
  }
  const { data: bankingInstitution } = useGetUnifiedBankingInstitution(bankingInstitutionId)
  // load user's existing connections to see if any match the selected institution
  const {
    data: { bankingConnections },
    isLoading: connectionsLoading,
  } = useQuerySpendingConnections()

  const hasAvailableCards = bankingConnections.filter(isAvailable).length > 0
  const returnTo = hasAvailableCards ? '/cards/personal' : '/dashboard'

  const existingConnection = bankingConnections.find(getIsConnectedToInstitution(bankingInstitutionId))

  if (connectionsLoading) return null
  if (!bankingInstitution) return null

  return (
    <PageBody>
      <Container>
        <Typography>
          <FormattedMessage id="connections.plaid.loading.heading" />
        </Typography>
        <ConnectFinicityBank
          bankingInstitution={bankingInstitution}
          existingConnection={existingConnection}
          onComplete={() => navigate(returnTo)}
        />
      </Container>
    </PageBody>
  )
}
