import * as React from 'react'
import { Navigate } from 'react-router'
import { useEnrollmentEmployer } from './useEnrollmentEmployer'
import { Callout, Container, PageBody, Typography } from '@alice-financial/pretext-ui'
import { FormattedMessage } from 'react-intl'

export const InactiveEmployer = () => {
  const { data: employerData } = useEnrollmentEmployer()
  const employer = employerData?.enrollmentEmployer

  if (!employer) return null
  // if it's not inactive, redirect to the enrollment landing page
  if (!employer.isActive) return <Navigate to=".." replace />
  const employerName = employer.marketingFriendlyName

  return (
    <PageBody>
      <Container>
        <Typography variant="h2" gutterBottom>
          <FormattedMessage id="enroll.inactive_er.er_no_longer_active" values={{ employerName }} />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="enroll.inactive_er.contact_hr" />
        </Typography>
        <Callout gutterBottom>
          <FormattedMessage id="enroll.inactive_er.if_account_then_sign_in" />
        </Callout>
      </Container>
    </PageBody>
  )
}
