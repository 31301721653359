import { daysFromDate } from '../../../utils/dateUtils'
import { DatePagingState } from '../../../utils/paging/datePaging/DatePagingContext'
import { dateConnectionVars } from '../../../utils/paging/datePaging/dateConnectionVars'
import { isNotNull } from '../../../utils/typeUtils'
import { BankingTransactionFragment } from '../../graphql/fragments/BankingTransactionFragment_gen'
import { ConfirmationStatusValue, PretaxCategory } from '../../graphql/generated.types'
import { useQueryUser } from '../../user'
import {
  BankingTransactionsQueryVariables,
  useBankingTransactionsQuery,
} from './graphql/bankingTransactions_gen'
import { apiTxnToAppTxn } from './txnUtils'
import { Transaction } from './types'
export const DEFAULT_DAYS_SHOWN = 90

export const ONE_DAY_AFTER_TODAY = daysFromDate(new Date(), 1)

const isNotRejected = (txn: BankingTransactionFragment | null) =>
  txn?.spendEligibility.confirmationStatus.value !== ConfirmationStatusValue.Rejected

/**
 * ONCALL-4469 temporary fix - only Alice internal should see healthcare transactions. The API will be
 * updated to suppress these soon.
 */
const isNotHealthcare = (txn: BankingTransactionFragment | null) =>
  txn?.spendEligibility.pretaxCategory !== PretaxCategory.Healthcare

export const TRANSACTIONS_QUERY_KEY = ['txn-list']
export const useTransactionsListQuery = (
  pagingState?: DatePagingState,
  confirmationStatus?: BankingTransactionsQueryVariables['confirmationStatus']
) => {
  const variables = {
    ...dateConnectionVars(pagingState),
    confirmationStatus,
  }

  const { data: user } = useQueryUser()
  const isAliceInternal = user?.employee_profile.employer_id === 1 // only used to override `isNotHealthcare`

  const { data: bankingTransactionsData, ...queryStatus } = useBankingTransactionsQuery(variables, {
    keepPreviousData: true,
  })
  const txns: Array<Transaction> = (bankingTransactionsData?.bankingTransactions.nodes || [])
    .filter(isNotNull)
    .filter(isNotRejected)
    .filter((txn) => isAliceInternal || isNotHealthcare(txn))
    .map(apiTxnToAppTxn)
  const pageInfo = bankingTransactionsData?.bankingTransactions.pageInfo
  return {
    data: {
      pageInfo,
      txns,
    },
    ...queryStatus,
  }
}
