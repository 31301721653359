import { Container, FullDialog, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentUserQuery } from '../gql/currentUser_gen'
import { ResendPhoneVerificationForm } from './ResendPhoneVerificationForm'
import { VerifyPhoneForm } from './VerifyPhoneForm'

/**
 * This dialog should be rendered anywhere a user sets/updates their phone number.
 *
 * It will _not_ display unless a verification code has been sent to the user's phone number,
 * but it does have the ability to re-send the code if the user has not received it or they
 * want the code sent to a different number.
 *
 * The component will _only_ ensure that the user's phone number is confirmed. It does not expose
 * any callbacks. The parent should check for `currentUser.tel.isSmsConfirmed` from the GraphQL
 * API in order to continue.
 */
export const VerifyPhoneDialog = () => {
  const [displayedForm, setDisplayedForm] = React.useState<'verify' | 'resend'>('verify')
  const { data: currentUserData, isFetching } = useCurrentUserQuery()
  const tel = currentUserData?.currentUser?.tel
  const showForm = Boolean(!isFetching && currentUserData?.currentUser?.tel?.isConfirmationCodeSent)

  return (
    <FullDialog
      open={showForm}
      title={
        <Typography variant="h2">
          <FormattedMessage id="enroll.phone_verification.verify_your_phone_number" />
        </Typography>
      }
    >
      <Container>
        {displayedForm === 'verify' && (
          <VerifyPhoneForm tel={tel} onNotReceived={() => setDisplayedForm('resend')} />
        )}
        {displayedForm === 'resend' && (
          <ResendPhoneVerificationForm tel={tel} onDone={() => setDisplayedForm('verify')} />
        )}
      </Container>
    </FullDialog>
  )
}
