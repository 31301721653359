import { Box, Callout, Container, Grid, List, PageBody, Paper, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { UserAddressDisplay } from '../../profile/address/AddressDisplay'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { AliceCardInfo } from './AliceCardInfo'
import { MigrateSynapseToStripeForm, useMayMigrateSynapseToStripe } from './MigrateSynapseToStripe'
import { RequestAliceCardForm, RequestFormProps } from './RequestAliceCardForm'
import {
  DISPLAY_ACCOUNT_STATUSES,
  isAliceCardAccountBalanceGated,
  sortCards,
  useAliceCardRequestScope,
} from './aliceCardUtils'
import { AliceCardItem } from './AliceCardItem'
import { AliceCard, AliceCardIssuingPlatform } from '../../../graphql/generated.types'
import { DigitalWalletInfo } from './instant/DigitalWalletInfo'

/**
 * The form description is dependent on whether the user is requesting an instant card, a physical card, or both.
 */
const RequestFormDescription = ({ scope }: RequestFormProps) => {
  switch (scope) {
    case 'instant_and_physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.request_an_alice_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.ordering_your_alice_card" />
          </Typography>
        </>
      )
    case 'physical':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.order_physical_card_description" />
          </Typography>
        </>
      )
    case 'instant':
      return (
        <>
          <Typography variant="h3" gutterBottom>
            <FormattedMessage id="cards.alice.order_instant_card" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.order_instant_card_description" />
          </Typography>
        </>
      )
    default:
      return null
  }
}

/**
 * The 'Alice Card homepage' - displays available cards,
 */
export const AliceCardList = () => {
  const {
    data: { aliceCardAccounts },
    isLoading,
  } = useQuerySpendingConnections()
  const requestScope = useAliceCardRequestScope()
  const doDisplayMigrationForm = useMayMigrateSynapseToStripe()
  const displayAccounts =
    aliceCardAccounts?.filter((account) => DISPLAY_ACCOUNT_STATUSES.includes(account.status)) || []
  const hasBalanceGatedAccount = displayAccounts.some(isAliceCardAccountBalanceGated)

  const notSynapse = (card: AliceCard) => card.issuingPlatform !== AliceCardIssuingPlatform.Synapse
  const cardsToDisplay = displayAccounts.flatMap((account) => account.aliceCards.filter(notSynapse)) || []

  if (isLoading) {
    return (
      <PageBody>
        <Container>
          <Typography gutterBottom>
            <FormattedMessage id="cards.alice.loading" />
          </Typography>
        </Container>
      </PageBody>
    )
  }

  cardsToDisplay.sort(sortCards)

  return (
    <PageBody>
      <Container>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="cards.alice.list.heading" />
        </Typography>
      </Container>
      <MigrateSynapseToStripeForm />
      <Container>
        <Typography gutterBottom>
          <FormattedMessage id="cards.alice.list.summary" />
        </Typography>
        <Callout component="div" gutterBottom>
          <Typography gutterBottom>
            <AliceCardInfo>
              <FormattedMessage id="cards.alice.what_can_i_do_with_an_alice_card" />
            </AliceCardInfo>
          </Typography>
          <Typography>
            <DigitalWalletInfo>
              <FormattedMessage id="cards.alice.suggest_adding_to_digital_wallets" />
            </DigitalWalletInfo>
          </Typography>
        </Callout>

        {hasBalanceGatedAccount && (
          <Callout gutterBottom>
            <FormattedMessage id="cards.alice.balance_gated_account_info" />
          </Callout>
        )}
      </Container>

      {cardsToDisplay.length > 0 && (
        <>
          <Container>
            <Paper>
              <Box p={2}>
                <Typography variant="h3" gutterBottom>
                  <FormattedMessage id="cards.personal.billing_address" />
                </Typography>
                <UserAddressDisplay />
              </Box>
            </Paper>
            <Grid container component={List} spacing={2} justifyContent="space-between">
              {cardsToDisplay.map((card) => (
                <AliceCardItem key={card.last4} card={card} />
              ))}
            </Grid>
          </Container>
        </>
      )}
      {requestScope && !doDisplayMigrationForm && (
        <Container>
          <RequestFormDescription scope={requestScope} />
          <RequestAliceCardForm scope={requestScope} />
        </Container>
      )}
    </PageBody>
  )
}
