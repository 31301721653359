import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrgAdminsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['Int']['input']
}>

export type OrgAdminsQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    employees: {
      __typename?: 'EmployeeConnection'
      nodes?: Array<{
        __typename?: 'Employee'
        id: number
        enrollmentInfo?: {
          __typename?: 'EmployeeEnrollment'
          accountStatus: Types.EmployeeAccountStatus
        } | null
        user: { __typename?: 'User'; id: number; firstName?: string | null; lastName?: string | null }
      } | null> | null
    }
  } | null
}

export type OrgAdminUserFragment = {
  __typename?: 'User'
  id: number
  firstName?: string | null
  lastName?: string | null
}

export const OrgAdminUserFragmentDoc = `
    fragment OrgAdminUser on User {
  id
  firstName
  lastName
}
    `
export const OrgAdminsDocument = `
    query OrgAdmins($orgId: Int!) {
  organization(id: $orgId) {
    id
    employees(role: organization_admin) {
      nodes {
        id
        enrollmentInfo {
          accountStatus
        }
        user {
          ...OrgAdminUser
        }
      }
    }
  }
}
    ${OrgAdminUserFragmentDoc}`

export const useOrgAdminsQuery = <TData = OrgAdminsQuery, TError = Error>(
  variables: OrgAdminsQueryVariables,
  options?: UseQueryOptions<OrgAdminsQuery, TError, TData>
) => {
  return useQuery<OrgAdminsQuery, TError, TData>(
    ['OrgAdmins', variables],
    fetchGql<OrgAdminsQuery, OrgAdminsQueryVariables>(OrgAdminsDocument, variables),
    options
  )
}

useOrgAdminsQuery.getKey = (variables: OrgAdminsQueryVariables) => ['OrgAdmins', variables]
