import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { BankingConnectionFragment, useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'

type DisconnectMutationOptions = UseRestMutationCallbacks<undefined, Pick<BankingConnectionFragment, 'id'>>
/**
 * This call sends an async _request_ to disconnect the instituion, but the institution may not
 * immediately be marked as "customer_disconnected"
 */
export const useDisconnectInstitution = (mutationOptions?: DisconnectMutationOptions) =>
  useMutationWithInvalidation(
    (connection) => fetchRest(`/api/spending/${connection.id}/disconnect`, { method: 'POST' }),
    mutationOptions,
    [useConnectedCardsQuery.getKey()]
  )

export const useDisconnect = (mutationOptions?: DisconnectMutationOptions) => {
  const intl = useIntl()
  const { mutate: disconnectInstitution } = useDisconnectInstitution(mutationOptions)
  return (connection: BankingConnectionFragment) => {
    const disconnectConfirmed = confirm(
      intl.formatMessage(
        { id: 'connections.disconnect.are_you_sure' },
        { institutionName: connection.institution?.name }
      )
    )
    if (disconnectConfirmed) disconnectInstitution(connection)
  }
}
