import { Container, Link, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'

export const NotFound = () => (
  <PageBody>
    <Container>
      <Typography variant="h1" gutterBottom>
        We couldn&apos;t find the page you&apos;re looking for
      </Typography>
      <Typography>
        You can head back to <Link to="/">the homepage</Link> to continue.
      </Typography>
    </Container>
  </PageBody>
)
