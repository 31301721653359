import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { SvgIcon } from '@mui/material'
import { styled } from '@mui/system'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from '../navigation'
import { FullDialog } from './FullDialog'

const InfoLink = styled(Link)({
  fontWeight: 'bold',
  fontStyle: 'italic',
  cursor: 'help',
  textDecoration: 'underline',
})

type InfoDrawerProps = {
  children: string | React.ReactNode
  anchorName: string
  title?: React.ReactElement
  infoContent: React.ReactNode
  modal?: boolean
  actions?: React.ReactNode
  Icon?: typeof SvgIcon
  noIcon?: boolean
}

/**
 * Imperative controls for a particular info drawer designated by `anchorName` - if
 * you need a particular component to open, close, or navigate to an InfoDrawer with
 * `anchorName`, use this hook. Note that the corresponding InfoDrawer must be
 * exist in the rendered JSX separately - these callbacks are used exclusively for control
 * interactions, not rendering.
 */
export const useInfoDrawerControl = (anchorName: string) => {
  const location = useLocation()
  const navigate = useNavigate()
  const onClose = () => navigate(-1)
  const openTarget = `#${anchorName}`
  const onOpen = () => navigate(openTarget)
  const open = location.hash.replace('#', '') === anchorName
  return { onClose, onOpen, openTarget, open }
}

export const InfoDrawer = ({
  children,
  title,
  anchorName,
  infoContent,
  modal,
  actions,
  Icon = HelpOutlineOutlinedIcon,
  noIcon,
}: InfoDrawerProps) => {
  const { onClose, openTarget, open } = useInfoDrawerControl(anchorName)
  return (
    <>
      <InfoLink to={openTarget} data-testid={anchorName}>
        {children} {!noIcon && <Icon fontSize="small" sx={{ marginBottom: '-0.2em' }} />}
      </InfoLink>
      <FullDialog
        data-testid={`${anchorName}-content`}
        title={title}
        fullScreen={!modal}
        open={open}
        onClose={onClose}
        actions={actions}
      >
        {infoContent}
      </FullDialog>
    </>
  )
}
