import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import {
  DeleteBillingSourceMutation,
  DeleteBillingSourceMutationVariables,
  useDeleteBillingSourceMutation,
} from './gql/deleteBillingSource_gen'

type UseDeleteBillingSourceMutationOptions = UseGqlMutationCallbacks<
  DeleteBillingSourceMutation,
  DeleteBillingSourceMutationVariables
>

export const useDeleteBillingSource = (
  invalidationKeys: Array<QueryKey>,
  mutationOptions?: UseDeleteBillingSourceMutationOptions
) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    invalidationKeys
  )
  const { mutateAsync: deleteBillingSource } = useDeleteBillingSourceMutation()
  return useMutation(['deleteBillingSource'], deleteBillingSource, mutationOptionsWithInvalidation)
}
