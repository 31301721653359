import * as React from 'react'
import {
  AliceCardAccountStatus,
  AliceCardIssuingStatus,
  AliceCardPlatform,
  BankingAccountPlatform,
  BankingConnectionStatus,
  PlaidLinkSessionStatusEnum,
} from '../../graphql/generated.types'
import { useOffboardDate } from '../../organization/offboarding/orgOffboardUtils'
import { useEmployeeQuery } from '../gql/employee_gen'
import { isAliceCardRequestPending } from './alice/aliceCardUtils'
import {
  AliceCardAccountFragment,
  BankingConnectionFragment,
  ConnectedCardsQuery,
  LinkSessionFragment,
  useConnectedCardsQuery,
} from './gql/connectedCardsQuery_gen'
import { canBeReconnected, isAvailable, isConnected } from './personal/connect/bankingConnectionUtils'
import { daysFromDate } from '../../../utils/dateUtils'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'

export type CombinedAliceCardAccount = Omit<AliceCardAccountFragment, 'id'>

const makeHasStatus = (status: BankingConnectionStatus) => (b: BankingConnectionFragment) =>
  b.status === status

const isStatusPending = makeHasStatus(BankingConnectionStatus.Pending)
const isPending = (bankingConnection: BankingConnectionFragment) => {
  const hasPendingStatus = isStatusPending(bankingConnection)
  return hasPendingStatus && bankingConnection.platform !== BankingAccountPlatform.Offline
}

const sortByCreatedAtReverse = (a: LinkSessionFragment, b: LinkSessionFragment) =>
  a.createdAt > b.createdAt ? 1 : -1

const getRecentLinkSession = (linkSessions: Array<LinkSessionFragment> | null | undefined) => {
  if (!linkSessions) return undefined
  const now = new Date()
  const recent = daysFromDate(now, -1)
  return linkSessions
    .sort(sortByCreatedAtReverse)
    .filter((session) => dateStringFormat.dateFromDateString(session.createdAt) > recent)
    .at(-1)
}
type ParsedConnectedCardsData = {
  aliceCardAccounts: Array<CombinedAliceCardAccount> | undefined
  bankingConnections: Array<BankingConnectionFragment>
  hasAcceptedAliceCardTos: boolean | undefined
  recentLinkSession: LinkSessionFragment | undefined
}

const parseConnectedCardsData = (
  connectedCardsData?: ConnectedCardsQuery,
  includeUnavailable = false
): ParsedConnectedCardsData => {
  const ee = connectedCardsData?.employee
  const bankingConnections = includeUnavailable
    ? ee?.spendingConnectionInfo.bankingConnections
    : ee?.spendingConnectionInfo.bankingConnections?.filter(isAvailable)
  const recentLinkSession = getRecentLinkSession(ee?.spendingConnectionInfo.plaid.linkSessions)
  return {
    recentLinkSession,
    hasAcceptedAliceCardTos: ee?.spendingConnectionInfo.hasAcceptedAliceCardTos,
    aliceCardAccounts: connectedCardsData?.employee?.spendingConnectionInfo.aliceCardAccounts || undefined,
    bankingConnections: bankingConnections || [],
  }
}

/**
 * Certain connection-related requests are handled asyncronously - the results are not
 * immediately available in the spending connections data, so it is helpful to set up
 * a polling request to check for the expected result of the async action.
 *
 * This function determines whether the connection data is in any of these 'pending' states
 *
 * 1. Immediately after connecting an institution, it has a `pending` status that should resolve
 *    within a short period of time
 * 2. Immediately after requesting an Alice Card (or reporting a missing card), the new
 *    card(s) may not immediately appear in the API response data, but should appear shortly after
 * 3. If a Plaid Link session has been created, but not yet completed, the data is still pending
 */
const dataIsPending = (data: ConnectedCardsQuery | undefined) => {
  const { bankingConnections, aliceCardAccounts, recentLinkSession } = parseConnectedCardsData(data)

  return (
    bankingConnections.find(isPending) || // 1
    (aliceCardAccounts && aliceCardAccounts.some(isAliceCardRequestPending)) || // 2
    recentLinkSession?.status === PlaidLinkSessionStatusEnum.Created // 3
  )
}
// This function determines whether the data should be refetched based on whether there are any cards
// in a 'pending' state - if so, refetch at the specified interval
const determineRefetchInterval = (data: ConnectedCardsQuery | undefined) =>
  dataIsPending(data) ? 5000 : false

const EMPTY_DATA: ParsedConnectedCardsData = {
  hasAcceptedAliceCardTos: false,
  aliceCardAccounts: undefined,
  bankingConnections: [],
  recentLinkSession: undefined,
}
export const useQuerySpendingConnections = ({ includeUnavailable = false } = {}) => {
  const offboardDate = useOffboardDate()
  const { data: connectedCardsData, ...fetchStatus } = useConnectedCardsQuery(undefined, {
    refetchInterval: determineRefetchInterval,
  })
  const data = React.useMemo(
    () => parseConnectedCardsData(connectedCardsData, includeUnavailable),
    [connectedCardsData, includeUnavailable]
  )
  if (offboardDate && offboardDate < new Date()) {
    return { data: EMPTY_DATA, ...fetchStatus }
  }

  return { data, ...fetchStatus }
}
// since `useQuerySpendingConnections` wraps `useConnectedCardsQuery`, just provide alias for query key
useQuerySpendingConnections.getKey = useConnectedCardsQuery.getKey

/**
 * hook to check for existing connections - the logic is not as straightforward as it seems,
 * given that we need to check for a _requested_ Alice Card that may not have been issued
 *
 * undefined => info is still loading
 * true => has connected a bank account or has requested an Alice Card
 * false => has not connected a bank account or requested an Alice Card
 */
export const useSpendingConnectionSummary = () => {
  const {
    data: { bankingConnections, aliceCardAccounts, hasAcceptedAliceCardTos },
    ...queryStatus
  } = useQuerySpendingConnections()

  const hasAliceCards = useHasAliceCards()

  return {
    hasConnectedBankingConnections: bankingConnections.filter(isConnected).length > 0,
    hasAcceptedAliceCardTos,
    hasAliceCards,
    hasActiveSynapseAccount: aliceCardAccounts?.some(
      (account) =>
        account.platform === AliceCardPlatform.Synapse && account.status === AliceCardAccountStatus.Active
    ),
    hasOMNYCard: bankingConnections.some((conn) => conn.platform === BankingAccountPlatform.Omny),
    hasReconnectionRequests: bankingConnections.some((conn) => canBeReconnected(conn.status)),
    ...queryStatus,
  }
}

/**
 * Alice Card routes are only available if the organization allows Alice Card *or the user
 * has an active Alice Card account*
 */
export const useHasAliceCards = (): boolean | null => {
  const {
    data: { aliceCardAccounts = [] },
    isLoading,
  } = useQuerySpendingConnections()

  const hasAliceCards = aliceCardAccounts.some(
    (account) =>
      account.status === AliceCardAccountStatus.Active ||
      account.status === AliceCardAccountStatus.BalanceGated
  )

  if (isLoading) return null // wait for API data that determines value
  return hasAliceCards
}

export const useAliceCardFeatureEnabled = (): boolean | undefined => {
  const { data: employeeData, isLoading, isError: isLoadingError } = useEmployeeQuery()

  if (isLoading || isLoadingError) return undefined
  const orgIssuingStatus = employeeData?.employee?.employer?.organization?.aliceCardProgram.cardIssuingStatus
  const orgIsReadyToIssue = orgIssuingStatus === AliceCardIssuingStatus.Active
  return orgIsReadyToIssue
}
