import {
  Box,
  Button,
  EmailInputController,
  Grid,
  Link,
  TextFieldController,
  Typography,
  Divider,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { getServerErrorHandler } from '../../utils/forms/ServerError'
import { AuthFormLayout } from './AuthFormLayout'
import { LoginFormValues } from './types'
import { useSubmitCredentials } from './useSubmitCredentials'
import { TelVerificationForm } from './TelVerificationForm'

export const Login = () => {
  const intl = useIntl()
  const [showVerificationCode, setShowVerificationCode] = React.useState(false)
  const { control, handleSubmit, setError } = useForm<LoginFormValues>({
    defaultValues: { email: '', password: '' },
  })
  const onError = getServerErrorHandler(setError, ['email', 'password'])
  const { mutate: submitCredentials, isSuccess, isLoading } = useSubmitCredentials({ onError })
  const isSubmitDisabled = isLoading || isSuccess
  const onSubmit = handleSubmit((values) => submitCredentials(values))

  return (
    <AuthFormLayout>
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id="auth.login.sign_in" />
      </Typography>
      {!showVerificationCode && (
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EmailInputController name="email" required fullWidth autoFocus control={control} />
            </Grid>
            <Grid item xs={12}>
              <TextFieldController
                control={control}
                name="password"
                label={<FormattedMessage id="profile.password_label" />}
                required
                fullWidth
                type="password"
                autoComplete="current-password"
                rules={{
                  required: intl.formatMessage({ id: 'profile.password.validation.required' }),
                  minLength: {
                    value: 8,
                    message: intl.formatMessage({ id: 'common.validation.minlength' }, { minLength: 8 }),
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Box component={Grid} item xs={12} sm={6} mt={5} mb={3}>
              <Button variant="contained" type="submit" fullWidth disabled={isSubmitDisabled}>
                <FormattedMessage id="auth.login.do_login" />
              </Button>
            </Box>
          </Grid>
          <Typography gutterBottom>
            <FormattedMessage id="auth.login.phone_verification" />
          </Typography>
        </form>
      )}

      <TelVerificationForm onShowVerificationCode={setShowVerificationCode} />

      <Divider sx={{ my: 3 }} />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
            <Link to="/forgot-password">
              <FormattedMessage id="auth.forgot_password" />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
            <Link to="/enroll">
              <FormattedMessage id="enroll.enroll_using_email" />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </AuthFormLayout>
  )
}
