import * as Types from '../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    firstName?: string | null
    lastName?: string | null
    preferredName?: string | null
    email?: string | null
    languagePreference: Types.LanguagePreference
    birthdate?: string | null
    isEmailConfirmed: boolean
    helpScoutSecureBeaconToken?: string | null
    tel?: {
      __typename?: 'Telephone'
      nationalNumber: string
      isSmsConfirmed?: boolean | null
      isConfirmationCodeSent?: boolean | null
    } | null
    provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
  } | null
}

export type GqlUserFragment = {
  __typename?: 'User'
  id: number
  firstName?: string | null
  lastName?: string | null
  preferredName?: string | null
  email?: string | null
  languagePreference: Types.LanguagePreference
  birthdate?: string | null
  isEmailConfirmed: boolean
  helpScoutSecureBeaconToken?: string | null
  tel?: {
    __typename?: 'Telephone'
    nationalNumber: string
    isSmsConfirmed?: boolean | null
    isConfirmationCodeSent?: boolean | null
  } | null
  provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
}

export const GqlUserFragmentDoc = `
    fragment GqlUser on User {
  id
  firstName
  lastName
  preferredName
  email
  languagePreference
  birthdate
  isEmailConfirmed
  tel {
    nationalNumber
    isSmsConfirmed
    isConfirmationCodeSent
  }
  helpScoutSecureBeaconToken
  provisionalOrganizations {
    id
  }
}
    `
export const CurrentUserDocument = `
    query CurrentUser {
  currentUser {
    ...GqlUser
  }
}
    ${GqlUserFragmentDoc}`

export const useCurrentUserQuery = <TData = CurrentUserQuery, TError = Error>(
  variables?: CurrentUserQueryVariables,
  options?: UseQueryOptions<CurrentUserQuery, TError, TData>
) => {
  return useQuery<CurrentUserQuery, TError, TData>(
    variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
    fetchGql<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, variables),
    options
  )
}

useCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) =>
  variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables]
