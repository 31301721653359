import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useLoginByPhoneCodeMutation, LoginByPhoneCodeMutation } from './LoginByPhoneCode_gen'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { useIntl } from 'react-intl'

type LoginByPhoneCodeProps = { tel: string; code: string; password: string | null }

type UseLoginByPhoneCodeMutationOptions = UseGqlMutationCallbacks<
  LoginByPhoneCodeMutation,
  LoginByPhoneCodeProps
>

export const useLoginByPhoneCode = (mutationOptions?: UseLoginByPhoneCodeMutationOptions) => {
  const intl = useIntl()

  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'auth.login.verifying_code' }),
      onSuccess: false,
    },
    mutationOptions
  )

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(_mutationOptions, 'all')

  const { mutateAsync: loginByPhoneCode } = useLoginByPhoneCodeMutation()

  return useMutation(
    ['loginByPhoneCode'],
    (input) => loginByPhoneCode({ input }),
    mutationOptionsWithInvalidation
  )
}
