import { RestApiError } from '@alice-financial/api'
import memoize from 'memoize-one'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { AddressAttributes, AddressFormValues } from './types'
import { useQueryAddress } from './useQueryAddress'
import { useUpdateAddress } from './useUpdateAddress'

export const getDefaultValues = memoize(
  (address: AddressAttributes): AddressFormValues => ({
    line_1: address.line_1,
    line_2: address.line_2,
    city_name: address.city_name,
    state_code: address.state_code,
    zipcode: address.zipcode,
  })
)

/**
 * Wrapper around mutation and response notification hooks to
 * expose only what the display component needs for triggering
 * form submissions - all non-rendering logic should be defined here
 *
 * This is a 'headless' hook
 *
 * state:
 * - `contenteditable`: is the form in 'edit' mode?
 * - `isLoading`: loading initial data
 * - `isSubmitting`: the mutation is currently in-flight
 * - `control`: react-hook-form prop for individual field `Controller`
 * - `isSubmitDisabled`: what it says
 * - `onSubmit`: generic form `onSubmit` callback
 * - `onEdit`: generic callback to trigger edit mode
 * - `onCancel`: generic callback to cancel edit mode
 */
export const useHeadlessAddressEdit = (options?: { doVerify?: boolean }) => {
  const [contenteditable, setContenteditable] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<RestApiError | null>(null)
  const { data: address, isLoading } = useQueryAddress()

  const userHasAddress = isLoading ? undefined : Boolean(address.line_1)
  const mustVerify = options?.doVerify && !address?.is_verified

  // set up the mutation hook with response status notifications
  const { mutate: updateAddress } = useUpdateAddress(
    {
      onMutate: () => {
        setError(null)
        setIsSubmitting(true)
        setContenteditable(false)
      },
      onError: (error) => {
        setContenteditable(true)
        setError(error)
      },
      onSettled: () => setIsSubmitting(false),
    },
    options?.doVerify
  )

  // set up form state management
  const defaultValues = getDefaultValues(address)
  const { reset, handleSubmit, control } = useForm<AddressFormValues>({
    mode: 'all',
    defaultValues,
  })

  React.useEffect(() => {
    if (error) return
    reset(defaultValues)
  }, [error, defaultValues, reset])

  return {
    contenteditable: contenteditable || userHasAddress === false || mustVerify,
    isLoading,
    isSubmitting,
    error,
    control,
    isSubmitDisabled: isSubmitting,
    onSubmit: handleSubmit((values) => updateAddress(values)),
    onEdit: () => setContenteditable(true),
    onCancel: () => {
      setContenteditable(false)
      reset()
    },
    address,
  }
}
