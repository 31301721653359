import * as Types from '../generated.types'

import { WorkLocationFragmentDoc } from './WorkLocationFragment_gen'
import { PaymentCardFragmentDoc } from './PaymentCardFragment_gen'
import { BankingAccountFragmentDoc } from './BankingAccountFragment_gen'
export type OrganizationDetailFragment = {
  __typename: 'Organization'
  id: number
  name: string
  commutingLaunchDate?: string | null
  logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
  aliceCardProgram: {
    __typename?: 'AliceCardProgram'
    kycStatus: Types.AliceCardKycStatus
    hasFundingConnected: boolean
    cardIssuingStatus: Types.AliceCardIssuingStatus
    hasUnifiedBanking?: boolean | null
    companyAccount?: {
      __typename?: 'StripeCompanyAccount'
      id: number
      stripeConnectedAccountId?: string | null
      stripeFinancialAccountId?: string | null
    } | null
  }
  aliceCardFunding: {
    __typename?: 'AliceCardFunding'
    paymentMethodConnections?: Array<{
      __typename?: 'PaymentMethodConnection'
      id: number
      isCurrent: boolean
      isActive: boolean
      paymentMethod: {
        __typename?: 'PaymentMethod'
        id: number
        last4?: string | null
        descriptor: string
        status: Types.PaymentMethodStatus
      }
    }> | null
    availablePaymentMethods?: Array<{
      __typename?: 'PaymentMethod'
      id: number
      last4?: string | null
      descriptor: string
      status: Types.PaymentMethodStatus
    }> | null
  }
  payrollConnection: {
    __typename?: 'PayrollConnection'
    payrollInstructionsCompletedAt?: string | null
    payrollConnectedAt?: string | null
    payrollPlatform?: {
      __typename?: 'PayrollPlatform'
      id: number
      name: string
      code: string
      permitsAdminReports: boolean
    } | null
  }
  billing?: {
    __typename?: 'Billing'
    hasUnifiedBilling: boolean
    hasBillingConnected: boolean
    billingWorkspace: Types.BillingWorkspace
    billingSource?:
      | {
          __typename: 'BankingAccount'
          id: number
          name?: string | null
          cardType?: Types.BankCardType | null
          accountType?: Types.BankingAccountType | null
          platform: Types.BankingAccountPlatform
          accountLast4?: string | null
        }
      | {
          __typename: 'PaymentCard'
          id: number
          billingSystemId: string
          brand: string
          expMonth: number
          expYear: number
          last4: string
        }
      | null
  } | null
  employers: Array<{
    __typename: 'Employer'
    id: number
    name?: string | null
    marketingFriendlyName?: string | null
    inviteCode: string
    onboardingStatus?: Types.EmployerOnboardingStatus | null
    aliceCardFunding: {
      __typename?: 'AliceCardFunding'
      paymentMethodConnections?: Array<{
        __typename?: 'PaymentMethodConnection'
        id: number
        isCurrent: boolean
        isActive: boolean
        paymentMethod: {
          __typename?: 'PaymentMethod'
          id: number
          last4?: string | null
          descriptor: string
          status: Types.PaymentMethodStatus
        }
      }> | null
      availablePaymentMethods?: Array<{
        __typename?: 'PaymentMethod'
        id: number
        last4?: string | null
        descriptor: string
        status: Types.PaymentMethodStatus
      }> | null
    }
    billing?: {
      __typename?: 'Billing'
      hasUnifiedBilling: boolean
      hasBillingConnected: boolean
      billingWorkspace: Types.BillingWorkspace
      billingSource?:
        | {
            __typename: 'BankingAccount'
            id: number
            name?: string | null
            cardType?: Types.BankCardType | null
            accountType?: Types.BankingAccountType | null
            platform: Types.BankingAccountPlatform
            accountLast4?: string | null
          }
        | {
            __typename: 'PaymentCard'
            id: number
            billingSystemId: string
            brand: string
            expMonth: number
            expYear: number
            last4: string
          }
        | null
    } | null
    employees: {
      __typename?: 'EmployeeConnection'
      reachableCount: number
      totalCount: number
      enrolledCount: number
      totalDeductions: number
      totalSavings: number
    }
  } | null>
  workLocations: Array<{
    __typename?: 'WorkLocation'
    id: number
    nickname?: string | null
    name: string
    latitude?: number | null
    longitude?: number | null
    logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
    address: {
      __typename?: 'AddressDisplay'
      isMailing: boolean
      isVerified: boolean
      line1: string
      line2?: string | null
      cityName: string
      stateCode: string
      zipcode: string
    }
  } | null>
  employees: {
    __typename?: 'EmployeeConnection'
    reachableCount: number
    totalCount: number
    enrolledCount: number
    totalDeductions: number
    totalSavings: number
  }
  onboardingState: {
    __typename?: 'OnboardingState'
    isAliceCardProgramActive: boolean
    isDoneWithOnboardingDocuments: boolean
    isAlreadyLaunched: boolean
    isBillingConnected: boolean
    isDoneWithPayrollInstructions: boolean
    isLogoPresent: boolean
    isLaunchingInFuture: boolean
    isLaunchingToday: boolean
    isPayrollConnected: boolean
    isPayrollConnectionComplete: boolean
    isReadyToLaunch: boolean
  }
}

export type EmployerSummaryFragment = {
  __typename: 'Employer'
  id: number
  name?: string | null
  marketingFriendlyName?: string | null
  inviteCode: string
  onboardingStatus?: Types.EmployerOnboardingStatus | null
  aliceCardFunding: {
    __typename?: 'AliceCardFunding'
    paymentMethodConnections?: Array<{
      __typename?: 'PaymentMethodConnection'
      id: number
      isCurrent: boolean
      isActive: boolean
      paymentMethod: {
        __typename?: 'PaymentMethod'
        id: number
        last4?: string | null
        descriptor: string
        status: Types.PaymentMethodStatus
      }
    }> | null
    availablePaymentMethods?: Array<{
      __typename?: 'PaymentMethod'
      id: number
      last4?: string | null
      descriptor: string
      status: Types.PaymentMethodStatus
    }> | null
  }
  billing?: {
    __typename?: 'Billing'
    hasUnifiedBilling: boolean
    hasBillingConnected: boolean
    billingWorkspace: Types.BillingWorkspace
    billingSource?:
      | {
          __typename: 'BankingAccount'
          id: number
          name?: string | null
          cardType?: Types.BankCardType | null
          accountType?: Types.BankingAccountType | null
          platform: Types.BankingAccountPlatform
          accountLast4?: string | null
        }
      | {
          __typename: 'PaymentCard'
          id: number
          billingSystemId: string
          brand: string
          expMonth: number
          expYear: number
          last4: string
        }
      | null
  } | null
  employees: {
    __typename?: 'EmployeeConnection'
    reachableCount: number
    totalCount: number
    enrolledCount: number
    totalDeductions: number
    totalSavings: number
  }
}

export type AliceCardProgramFragment = {
  __typename?: 'AliceCardProgram'
  kycStatus: Types.AliceCardKycStatus
  hasFundingConnected: boolean
  cardIssuingStatus: Types.AliceCardIssuingStatus
  hasUnifiedBanking?: boolean | null
  companyAccount?: {
    __typename?: 'StripeCompanyAccount'
    id: number
    stripeConnectedAccountId?: string | null
    stripeFinancialAccountId?: string | null
  } | null
}

export type AliceCardFundingFragment = {
  __typename?: 'AliceCardFunding'
  paymentMethodConnections?: Array<{
    __typename?: 'PaymentMethodConnection'
    id: number
    isCurrent: boolean
    isActive: boolean
    paymentMethod: {
      __typename?: 'PaymentMethod'
      id: number
      last4?: string | null
      descriptor: string
      status: Types.PaymentMethodStatus
    }
  }> | null
  availablePaymentMethods?: Array<{
    __typename?: 'PaymentMethod'
    id: number
    last4?: string | null
    descriptor: string
    status: Types.PaymentMethodStatus
  }> | null
}

export type PaymentMethodConnectionFragment = {
  __typename?: 'PaymentMethodConnection'
  id: number
  isCurrent: boolean
  isActive: boolean
  paymentMethod: {
    __typename?: 'PaymentMethod'
    id: number
    last4?: string | null
    descriptor: string
    status: Types.PaymentMethodStatus
  }
}

export type PaymentMethodFragment = {
  __typename?: 'PaymentMethod'
  id: number
  last4?: string | null
  descriptor: string
  status: Types.PaymentMethodStatus
}

export type BillingSource_BankingAccount_Fragment = {
  __typename: 'BankingAccount'
  id: number
  name?: string | null
  cardType?: Types.BankCardType | null
  accountType?: Types.BankingAccountType | null
  platform: Types.BankingAccountPlatform
  accountLast4?: string | null
}

export type BillingSource_PaymentCard_Fragment = {
  __typename: 'PaymentCard'
  id: number
  billingSystemId: string
  brand: string
  expMonth: number
  expYear: number
  last4: string
}

export type BillingSourceFragment = BillingSource_BankingAccount_Fragment | BillingSource_PaymentCard_Fragment

export type OnboardingStateFragment = {
  __typename?: 'OnboardingState'
  isAliceCardProgramActive: boolean
  isDoneWithOnboardingDocuments: boolean
  isAlreadyLaunched: boolean
  isBillingConnected: boolean
  isDoneWithPayrollInstructions: boolean
  isLogoPresent: boolean
  isLaunchingInFuture: boolean
  isLaunchingToday: boolean
  isPayrollConnected: boolean
  isPayrollConnectionComplete: boolean
  isReadyToLaunch: boolean
}

export const AliceCardProgramFragmentDoc = `
    fragment AliceCardProgram on AliceCardProgram {
  kycStatus
  hasFundingConnected
  cardIssuingStatus
  hasUnifiedBanking
  companyAccount {
    id
    stripeConnectedAccountId
    stripeFinancialAccountId
  }
}
    `
export const PaymentMethodFragmentDoc = `
    fragment PaymentMethod on PaymentMethod {
  id
  last4
  descriptor
  status
}
    `
export const PaymentMethodConnectionFragmentDoc = `
    fragment PaymentMethodConnection on PaymentMethodConnection {
  id
  isCurrent
  isActive
  paymentMethod {
    ...PaymentMethod
  }
}
    `
export const AliceCardFundingFragmentDoc = `
    fragment AliceCardFunding on AliceCardFunding {
  paymentMethodConnections {
    ...PaymentMethodConnection
  }
  availablePaymentMethods {
    ...PaymentMethod
  }
}
    `
export const BillingSourceFragmentDoc = `
    fragment BillingSource on BillingSource {
  __typename
  ... on PaymentCard {
    ...PaymentCard
  }
  ... on BankingAccount {
    ...BankingAccount
  }
}
    `
export const EmployerSummaryFragmentDoc = `
    fragment EmployerSummary on Employer {
  __typename
  id
  name
  marketingFriendlyName
  inviteCode
  onboardingStatus
  aliceCardFunding {
    ...AliceCardFunding
  }
  billing {
    hasUnifiedBilling
    hasBillingConnected
    billingWorkspace
    billingSource {
      ...BillingSource
    }
  }
  employees {
    reachableCount
    totalCount
    enrolledCount
    totalDeductions
    totalSavings
  }
}
    `
export const OnboardingStateFragmentDoc = `
    fragment OnboardingState on OnboardingState {
  isAliceCardProgramActive
  isDoneWithOnboardingDocuments
  isAlreadyLaunched
  isBillingConnected
  isDoneWithPayrollInstructions
  isLogoPresent
  isLaunchingInFuture
  isLaunchingToday
  isPayrollConnected
  isPayrollConnectionComplete
  isReadyToLaunch
}
    `
export const OrganizationDetailFragmentDoc = `
    fragment OrganizationDetail on Organization {
  __typename
  id
  name
  commutingLaunchDate
  logo {
    url
    thumbnailUrl
  }
  aliceCardProgram {
    ...AliceCardProgram
  }
  aliceCardFunding {
    ...AliceCardFunding
  }
  payrollConnection {
    payrollInstructionsCompletedAt
    payrollConnectedAt
    payrollPlatform {
      id
      name
      code
      permitsAdminReports
    }
  }
  billing {
    hasUnifiedBilling
    hasBillingConnected
    billingWorkspace
    billingSource {
      ...BillingSource
    }
  }
  employers {
    ...EmployerSummary
  }
  workLocations {
    ...WorkLocation
  }
  employees {
    reachableCount
    totalCount
    enrolledCount
    totalDeductions
    totalSavings
  }
  onboardingState {
    ...OnboardingState
  }
}
    `
