import { ActionLayout, Button, TelFieldController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRequestPhoneVerification } from '../useRequestPhoneVerification'
import { GqlUserFragment } from '../gql/currentUser_gen'

type ResendPhoneVerificationFormValues = {
  tel: string
}
type ResendPhoneVerificationProps = {
  tel: GqlUserFragment['tel']
  onDone: () => void
}

export const ResendPhoneVerificationForm = ({ tel, onDone }: ResendPhoneVerificationProps) => {
  const intl = useIntl()
  const {
    mutate: sendPhoneVerificationCode,
    isLoading: isSendingNewCode,
    isSuccess,
  } = useRequestPhoneVerification({ onSuccess: onDone })
  const { handleSubmit, control } = useForm<ResendPhoneVerificationFormValues>({
    values: {
      tel: tel?.nationalNumber ?? '',
    },
  })

  const onSubmit = handleSubmit(({ tel: telValue }) => {
    sendPhoneVerificationCode({ tel: telValue })
  })

  const resendCodeDisabled = isSuccess || isSendingNewCode

  return (
    <form data-testid="verify-phone" onSubmit={onSubmit}>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="enroll.phone_verification.check_number" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage
          id="enroll.phone_verification.edit_and_resend"
          values={{
            resendLabel: intl.formatMessage({ id: 'enroll.phone_verification.resend_code' }),
          }}
        />
      </Typography>

      <TelFieldController
        name="tel"
        label={intl.formatMessage({ id: 'profile.tel_label' })}
        required
        fullWidth
        autoComplete="tel"
        control={control}
        sx={{ marginTop: 2 }}
      />

      <ActionLayout
        primary={
          <Button variant="contained" type="submit" disabled={resendCodeDisabled} fullWidth>
            <FormattedMessage id="enroll.phone_verification.resend_code" />
          </Button>
        }
        secondary={
          <Button variant="outlined" onClick={onDone}>
            <FormattedMessage id="common.cancel.never_mind" />
          </Button>
        }
      />
    </form>
  )
}
