import memoize from 'memoize-one'
import { AddressEntity } from '../../../user/types'
import { USER_QUERY_KEY, useQueryUser } from '../../../user'
import { AddressAttributes } from './types'

export const profileAddressToAddressAttributes = memoize(
  (profileAddress?: AddressEntity): AddressAttributes => {
    const { line_1, line_2, city, state, zipcode, ...rest } = profileAddress ?? {}
    return {
      line_1: line_1 || '',
      line_2: line_2 || '',
      city_name: city || '',
      state_code: state || '',
      zipcode: zipcode || '',
      ...rest,
    }
  }
)

export const useQueryAddress = () => {
  const { data: user, ...queryStatus } = useQueryUser()
  return {
    data: profileAddressToAddressAttributes(user?.employee_profile?.address),
    ...queryStatus,
  }
}
useQueryAddress.getKey = () => USER_QUERY_KEY
