import { Box, IconListItem, List, ListProps, styled, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocaleState } from '../../../translations/LocaleProvider'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import MassTransitIcon from 'jsx:../../assets/massTransit.svg'
import ParkingIcon from 'jsx:../../assets/parking.svg'
import { CommuterLedgerSummaryFragment, useSpendingSummaryQuery } from './graphql/spendingSummary_gen'

const IconGridItem = styled(Box)({
  gridArea: '1 / 1',
  textAlign: 'center',
})
const ItemContent = styled(Box)({ gridArea: '1 / span 2' })

const useSpendingSummary = () => {
  const { data: spendingSummaryData, ...fetchStatus } = useSpendingSummaryQuery()

  return {
    data: spendingSummaryData?.employee?.ledger?.commuter,
    ...fetchStatus,
  }
}

const PRETAX_CATEGORY_KEY = {
  MassTransitLedger: 'transactions.pretax_categories.mass_transit',
  ParkingLedger: 'transactions.pretax_categories.parking',
} as const
const PRETAX_CATEGORY_ICON = {
  MassTransitLedger: MassTransitIcon,
  ParkingLedger: ParkingIcon,
}

type CommuterCategoryListItemProps = {
  categoryLedger: CommuterLedgerSummaryFragment['massTransit'] | CommuterLedgerSummaryFragment['parking']
}
const CommuterCategoryListItem = ({ categoryLedger }: CommuterCategoryListItemProps) => {
  const intl = useIntl()
  const [locale] = useLocaleState()
  if (!categoryLedger?.__typename || !categoryLedger?.current) return null
  const isUnderSpendingLimit = categoryLedger.current.available > 0

  const pretaxCategoryKey = PRETAX_CATEGORY_KEY[categoryLedger.__typename]
  const Icon = PRETAX_CATEGORY_ICON[categoryLedger.__typename]

  const trnValues = {
    limit: formatCentsAsDollars(categoryLedger.current.limitationReason?.spendingCap ?? undefined),
    spentSoFar: formatCentsAsDollars(categoryLedger.current.spentSoFar),
    available: formatCentsAsDollars(categoryLedger.current.available),
    monthName: new Date().toLocaleString(locale, { month: 'long' }),
    pretaxCategory: intl.formatMessage({ id: pretaxCategoryKey }),
  }

  const messageKey = isUnderSpendingLimit
    ? 'spending.summary.category.under_limit'
    : 'spending.summary.category.over_limit'

  return (
    <IconListItem>
      <IconGridItem>
        <Icon width="100%" />
      </IconGridItem>
      <ItemContent>
        <Typography variant="body2">
          <FormattedMessage id={messageKey} values={trnValues} />
        </Typography>
      </ItemContent>
    </IconListItem>
  )
}

export const CommutingSpendingSummary = (props: ListProps) => {
  const { data: spendingSummary } = useSpendingSummary()

  // if API doesn't return either summary, don't display list at all
  if (!spendingSummary?.massTransit?.current && !spendingSummary?.parking?.current) return null

  return (
    <List {...props}>
      <CommuterCategoryListItem categoryLedger={spendingSummary?.massTransit} />
      <CommuterCategoryListItem categoryLedger={spendingSummary?.parking} />
    </List>
  )
}
