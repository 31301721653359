import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CurrentUserOnboardingQueryVariables = Types.Exact<{ [key: string]: never }>

export type CurrentUserOnboardingQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    firstName?: string | null
    lastName?: string | null
    preferredName?: string | null
    email?: string | null
    hasDummyPassword: boolean
    tel?: { __typename?: 'Telephone'; nationalNumber: string; isSmsConfirmed?: boolean | null } | null
    provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
  } | null
}

export const CurrentUserOnboardingDocument = `
    query CurrentUserOnboarding {
  currentUser {
    id
    firstName
    lastName
    preferredName
    email
    hasDummyPassword
    tel {
      nationalNumber
      isSmsConfirmed
    }
    provisionalOrganizations {
      id
    }
  }
}
    `

export const useCurrentUserOnboardingQuery = <TData = CurrentUserOnboardingQuery, TError = Error>(
  variables?: CurrentUserOnboardingQueryVariables,
  options?: UseQueryOptions<CurrentUserOnboardingQuery, TError, TData>
) => {
  return useQuery<CurrentUserOnboardingQuery, TError, TData>(
    variables === undefined ? ['CurrentUserOnboarding'] : ['CurrentUserOnboarding', variables],
    fetchGql<CurrentUserOnboardingQuery, CurrentUserOnboardingQueryVariables>(
      CurrentUserOnboardingDocument,
      variables
    ),
    options
  )
}

useCurrentUserOnboardingQuery.getKey = (variables?: CurrentUserOnboardingQueryVariables) =>
  variables === undefined ? ['CurrentUserOnboarding'] : ['CurrentUserOnboarding', variables]
