import { Container, InfoDrawer, Typography, useInfoDrawerControl } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../../ContactSupport'
import { CommuterList } from '../../spending/EligibleExpensesInfo'
import { FormattedMessage } from 'react-intl'

const ANCHOR_NAME = 'alice-card-info'

type InfoContentProps = {
  onClickSupport: () => void
}
const InfoContent = ({ onClickSupport }: InfoContentProps) => (
  <>
    <Container>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.card_initial_state" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.save_on_transit_and_parking" />
      </Typography>
      <CommuterList />
      <Typography variant="h3" textAlign="center" gutterBottom>
        <FormattedMessage id="cards.alice.how_do_i_use_my_alice_card" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.paying_using_alice_card" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.save_amount_example" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="cards.alice.relax_with_alice" />
      </Typography>
    </Container>
    <ContactSupport onClick={onClickSupport} />
  </>
)

type AliceCardInfoProps = {
  children: React.ReactNode
}
export const AliceCardInfo = ({ children }: AliceCardInfoProps) => {
  const { onClose } = useInfoDrawerControl(ANCHOR_NAME)
  return (
    <InfoDrawer
      title={
        <Typography variant="h2">
          <FormattedMessage id="cards.alice.what_can_i_do_with_an_alice_card" />
        </Typography>
      }
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent onClickSupport={onClose} />}
    >
      {children}
    </InfoDrawer>
  )
}
