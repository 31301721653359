import {
  Callout,
  Collapse,
  Container,
  ExternalLink,
  Typography,
  useResponseNotification,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router'
import { AddressDisplay } from '../../../employeeDashboard/profile/address/AddressDisplay'
import { useOnboardingStatus } from '../useOnboardingStatus'
import { useQueryProvisionalOrgs } from '../useQueryProvisionalOrgs'
import { CompanyInfoForm } from './CompanyInfoForm'
import { CompanyInfoUKGForm, useUKGOnboardingCompany } from './CompanyInfoUKGForm'
import { useOrgDashboardQuery } from '../../../orgDashboard/homepage/gql/orgDashboard_gen'

export const CompanyInfoStep = () => {
  const isUKGOnboarding = Boolean(useUKGOnboardingCompany())
  const navigate = useNavigate()
  const { provisionalOrgId } = useParams()
  const { notifyError } = useResponseNotification()
  const { data: orgData } = useOrgDashboardQuery()
  const [onboardingStatus] = useOnboardingStatus()
  const { data: onboardingProvisionalOrg, isError: isProvisionalOrgError } =
    useQueryProvisionalOrgs(provisionalOrgId)
  const ready = onboardingStatus === 'no_onboarding' || Boolean(onboardingProvisionalOrg)
  const disabled = onboardingStatus === undefined

  React.useEffect(() => {
    if (isProvisionalOrgError) {
      notifyError("There was a problem loading your organization's onboarding status.")
    }
  }, [notifyError, isProvisionalOrgError])

  const createdOrg = orgData?.organization
  const canManageProvisionalOrg = Boolean(ready && !createdOrg)

  React.useEffect(() => {
    // If the user has a provisional org
    if (!onboardingProvisionalOrg) return
    //  but we are not on the org's onboarding page
    if (provisionalOrgId === onboardingProvisionalOrg.id.toString()) return
    // redirect to the org's onboarding page
    navigate(`/onboard/${onboardingProvisionalOrg.id}`, { replace: true, preventScrollReset: true })
  }, [provisionalOrgId, onboardingProvisionalOrg, navigate])

  if (isProvisionalOrgError) return null // this is bad, but we're using `notifyError` to inform user

  return (
    <Container>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="organization.onboarding.company_info_heading" />
      </Typography>
      {!ready && (
        <Typography gutterBottom>
          <FormattedMessage id="common.flow.please_complete_previous" />
        </Typography>
      )}
      {createdOrg && (
        <>
          <Typography>
            <strong>{onboardingProvisionalOrg?.legal_name}</strong>
          </Typography>
          <AddressDisplay address={onboardingProvisionalOrg?.address} />
          <Callout variant="body2" gutterBottom sx={{ marginTop: 2 }}>
            Organization info has been submitted. Please{' '}
            <ExternalLink href="mailto:admin-support@thisisalice.com">contact support</ExternalLink> to make
            changes.
          </Callout>
        </>
      )}
      <Collapse in={canManageProvisionalOrg}>
        {canManageProvisionalOrg &&
          (isUKGOnboarding ? (
            <CompanyInfoUKGForm />
          ) : (
            <CompanyInfoForm provisionalOrg={onboardingProvisionalOrg} disabled={disabled} />
          ))}
      </Collapse>
    </Container>
  )
}
