import {
  Box,
  Button,
  Grid,
  IconListItem,
  Link,
  Skeleton,
  styled,
  Typography,
} from '@alice-financial/pretext-ui'
import MassTransitIcon from 'jsx:../../assets/massTransit.svg'
import ParkingIcon from 'jsx:../../assets/parking.svg'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { dateFormat } from '../../../utils/formatters/dateFormat'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { ConfirmationStatusValue } from '../../graphql/generated.types'
import { getTxnUrl, ReadableTxnCategory, ReadableTxnStatus } from './txnUtils'
import { ConfirmationPayload, Transaction, TxnProps } from './types'
import { useConfirmTransaction } from './useConfirmTransaction'

/**
 * The TxnListItem uses all 3 columns from IconListItem, and lays out
 * transaction information in 5 rows
 */

// Left column, below date row
const TxnItemIconLayout = styled(Box)({
  gridArea: '1 / 1 / 6 / 2',
  justifySelf: 'center',
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  alignSelf: 'center',
})
const CategoryIconRoot = styled(Box, { shouldForwardProp: (propKey) => propKey !== 'txn' })<TxnProps>(
  ({ txn }) => ({
    opacity: txn?.status.value === 'unconfirmed' ? 0.3 : 1,
  })
)
const CategoryIconOverlay = styled(Typography)(({ theme }) => ({
  textShadow: Array(5).fill(`0 0 20px ${theme.palette.background.default}`).join(','),
  color: theme.palette.primary.main,
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
}))
const TxnItemIcon = ({ txn }: TxnProps) => (
  <TxnItemIconLayout>
    {txn ? (
      <CategoryIconRoot txn={txn}>
        {txn.spendEligibility.pretaxCategory === 'parking' && <ParkingIcon title="parking" width="100%" />}
        {txn.spendEligibility.pretaxCategory === 'mass_transit' && (
          <MassTransitIcon title="mass transit" width="100%" />
        )}
      </CategoryIconRoot>
    ) : (
      <Skeleton variant="rectangular" height={48} />
    )}
    {txn?.status.value === 'unconfirmed' && <CategoryIconOverlay>?</CategoryIconOverlay>}
  </TxnItemIconLayout>
)

// Right column, same row as vendor
const TxnItemAmountLayout = styled(Box)({ gridRowStart: '2', alignSelf: 'end' })
const TxnItemAmount = ({ txn }: TxnProps) => (
  <TxnItemAmountLayout>
    <Typography variant="body1" fontWeight="bold" textAlign="right">
      {txn ? formatCentsAsDollars(txn.amount, false) : <Skeleton width="60%" />}
    </Typography>
  </TxnItemAmountLayout>
)

// Center and right columns, below vendor + amount
const TxnItemEstSavingsLayout = styled(Box)({ gridArea: '3 / span 2' })
const TxnItemEstSavings = ({ txn }: TxnProps) => {
  const intl = useIntl()
  return (
    <TxnItemEstSavingsLayout>
      <Grid container justifyContent="space-between">
        <Grid item component={Typography} variant="body2">
          <FormattedMessage
            id="transactions.transaction_list_item.status"
            values={{
              status: txn ? intl.formatMessage({ id: ReadableTxnStatus[txn.status.value] }) : '',
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="primary" textAlign="right">
            <FormattedMessage id="transactions.transaction_list_item.estimated_savings.abbr" /> &nbsp;
            <strong>
              {txn ? formatCentsAsDollars(txn.estimatedSavings, false) : <Skeleton width="40%" />}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    </TxnItemEstSavingsLayout>
  )
}

// Top row
const TxnItemDateLayout = styled(Box)({ gridArea: '1 / span 2', marginBottom: '0.5em' })
const TxnItemDate = ({ txn }: TxnProps) => (
  <TxnItemDateLayout>
    <Typography variant="subtitle2">
      {txn ? dateFormat.medium(txn.date) : <Skeleton width="6em" />}
    </Typography>
  </TxnItemDateLayout>
)

// Center column, below date row
const TxnItemVendorLayout = styled(Box)({ gridArea: '2 / span 1' })
const TxnItemVendor = ({ txn }: TxnProps) => (
  <TxnItemVendorLayout>
    <Typography variant="body2" color="common.black" fontWeight="bold">
      {txn ? txn.name : <Skeleton width="80%" />}
    </Typography>
  </TxnItemVendorLayout>
)

const TxnLinkLayout = styled(Box)({
  gridArea: '5 / span 1',
  alignSelf: 'end',
})
const TxnItemLink = ({ txn }: TxnProps) => (
  <TxnLinkLayout>
    <Button component={Link} variant="text" size="small" to={txn ? getTxnUrl(txn) : '#'} disabled={!txn}>
      {txn && txn.spendEligibility.autoConfirmedAt ? (
        <FormattedMessage id="transactions.transaction_list_item.auto_confirmed" />
      ) : (
        <FormattedMessage id="common.view_details" />
      )}
    </Button>
  </TxnLinkLayout>
)

const TxnConfirmationLayout = styled(Box)({
  gridColumn: 'span 2',
  marginTop: '0.5rem',
})
type TxnConfirmationActionsProps = {
  txn: Transaction
  onSelect: (isConfirmed: boolean) => void
}
const TxnConfirmationActions = ({ txn, onSelect }: TxnConfirmationActionsProps) => {
  const intl = useIntl()
  const {
    mutate: updateTxnConfirmation,
    isLoading,
    isSuccess,
  } = useConfirmTransaction(txn.id, {
    onMutate: ({ confirmed_eligible_spend }) => onSelect(confirmed_eligible_spend),
    onError: () => onSelect(true),
  })
  const handleConfirm = (payload: ConfirmationPayload) => updateTxnConfirmation(payload)
  const getConfirm = (confirmed_eligible_spend: boolean) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // don't navigate to detail view
    return handleConfirm({
      confirmed_eligible_spend,
      confirmed_partial_amount: txn.amount,
    })
  }

  const submitIsDisabled = isLoading || isSuccess
  return (
    <TxnConfirmationLayout>
      <Grid item container justifyContent="flex-end" columnSpacing={2} alignItems="center">
        <Grid item xs={12} sm="auto" md="auto">
          <Typography variant="body2" textAlign="left" fontWeight="bold" color="primary">
            <FormattedMessage
              id="transactions.status.unconfirmed.prompt"
              values={{
                pretaxCategory: intl
                  .formatMessage({
                    id: ReadableTxnCategory[txn.spendEligibility.pretaxCategory],
                  })
                  .toLowerCase(),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={getConfirm(false)}
            disabled={submitIsDisabled}
          >
            <FormattedMessage id="common.no" />
          </Button>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            fullWidth
            onClick={getConfirm(true)}
            disabled={submitIsDisabled}
          >
            <FormattedMessage id="common.yes" />
          </Button>
        </Grid>
      </Grid>
    </TxnConfirmationLayout>
  )
}

/**
 * Transaction info - markup is organized by importance, not necessarily
 * the same order that the information is layed out in the item
 */
export const TxnListItem = ({ txn }: TxnProps) => {
  const navigate = useNavigate()
  const [isRejected, setIsRejected] = React.useState(
    txn?.spendEligibility.confirmationStatus.value === ConfirmationStatusValue.Rejected
  )
  // the whole item is clickable for convenience, but the dedicated 'TxnItemLink' (`<a href="">`)
  // is also rendered for semantic HTML accessibility
  return (
    <IconListItem
      divider
      onClick={txn && (() => navigate(getTxnUrl(txn)))}
      sx={{
        opacity: isRejected ? 0 : 1,
        maxHeight: isRejected ? 0 : 300,
        overflow: 'hidden',
        padding: isRejected ? 0 : undefined,
        transition: 'all 0.25s ease-out',
      }}
    >
      <TxnItemIcon txn={txn} />
      <TxnItemDate txn={txn} />
      <TxnItemVendor txn={txn} />
      <TxnItemAmount txn={txn} />
      {txn?.status.value === 'unconfirmed' ? (
        <TxnConfirmationActions txn={txn} onSelect={(isConfirmed) => setIsRejected(!isConfirmed)} />
      ) : (
        <>
          <TxnItemEstSavings txn={txn} />
          <TxnItemLink txn={txn} />
        </>
      )}
    </IconListItem>
  )
}
