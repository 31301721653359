import { Box, Button, EmailInputController, Grid, Link, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate, useNavigate } from 'react-router'
import { AuthFormLayout } from '../auth/AuthFormLayout'
import { useTrackingContext } from '../telemetry/TrackingProvider'
import { useQueryUser } from '../user'
import { useEnrollmentEmployer } from './useEnrollmentEmployer'

/**
 * This hook wraps the `useForm` and `useQuery` functionality for the enrollment code lookup form.
 * Submitting the form triggers an async query that takes the user's email as an argument, and
 * navigates to the corresponding employer's enrollment URL, if found
 *
 * Errors are handled by setting the input field's error state
 */
const useInviteCodeLookupForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [emailToSearch, setEmailToSearch] = React.useState<string | undefined>(undefined)
  const { handleSubmit, control, setError } = useForm<{ email: string }>({
    defaultValues: { email: '' },
  })

  const { data: employerData, isSuccess, isFetching } = useEnrollmentEmployer(emailToSearch)
  const employer = employerData?.enrollmentEmployer
  const onSubmit = handleSubmit((values) => setEmailToSearch(values.email))
  const isSubmitDisabled = isFetching || isSuccess

  React.useEffect(() => {
    if (!isSuccess) return
    if (!employer) {
      // no employer found - set error and reset query state
      setError('email', {
        message: intl.formatMessage({ id: 'enroll.find_enrollment_employer.employer_could_not_be_found' }),
      })
      setEmailToSearch(undefined)
      return
    }
    navigate(`/enroll/${employer.inviteCode}`)
  }, [setError, intl, isSuccess, employer, navigate])

  return { control, onSubmit, isSubmitDisabled }
}

/**
 * This is the target page for DMLs that contain utm parameters that
 * include the invite code for the user. When an invite code is present,
 * immediately redirect to the start of the enrollment flow
 */
const useUtmRedirect = () => {
  const navigate = useNavigate()
  const [{ utmParams }] = useTrackingContext()

  React.useEffect(() => {
    if (utmParams?.utm_medium !== 'invite_code') return

    const enrollmentCode = utmParams.utm_campaign
    navigate(`/enroll/${enrollmentCode}`, { replace: true })
  }, [utmParams, navigate])
}

/**
 * This view is served when a user wants to enroll but doesn't have an enrollment code (employer.invite_code).
 * The view handles both a logged-in and logged-out experience. If the user arrives from a Direct Magic Link (DML),
 * the REST API will return a valid `user` object that contains their employer's enrollment code, and we can
 * immediately redirect.
 *
 * If the user is not logged in, they need to supply their work email address so that we can look up their
 * employer's enrollment code and redirect appropriately.
 */
export const FindEnrollmentCode = () => {
  const { data: user } = useQueryUser()
  useUtmRedirect()
  const { control, onSubmit, isSubmitDisabled } = useInviteCodeLookupForm()

  const enrollmentCode = user?.employee_profile?.employer?.invite_code
  if (enrollmentCode) return <Navigate to={`/enroll/${enrollmentCode}`} replace />

  return (
    <AuthFormLayout>
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id="enroll.find_enrollment_code.heading" />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage id="enroll.find_enrollment_code.instructions" />
      </Typography>
      <form onSubmit={onSubmit}>
        <EmailInputController name="email" required fullWidth autoFocus control={control} />

        <Grid container justifyContent="center">
          <Box component={Grid} item xs={12} sm={7} mt={5} mb={3}>
            <Button variant="contained" type="submit" fullWidth disabled={isSubmitDisabled}>
              <FormattedMessage id="enroll.start_enrollment.label" />
            </Button>
          </Box>
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold" gutterBottom textAlign="center">
              <Link to="/login">
                <FormattedMessage id="auth.login.do_login" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </AuthFormLayout>
  )
}
