import {
  UseRestMutationCallbacks,
  composeMutationCallbacks,
  fetchRest,
  useMutationWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { UseMutationOptions } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { prettyTelString } from '../../utils/formatters/telFormat'
import { useEmployeeEnrollmentInfoQuery } from '../enrollment/gql/employeeEnrollmentInfo_gen'
import { USER_QUERY_KEY } from './useQueryUser'
import { useCurrentUserQuery } from './gql/currentUser_gen'

type RequestPhoneVerifParams = { tel: string }

/**
 * Request that a new confirmation code be sent to the supplied number. Requires a logged-in user because
 * the user's `tel` number will be updated if it is different from their current number
 */
export const requestCode = (values: RequestPhoneVerifParams) =>
  fetchRest<unknown>(`/api/identity_verification/send_code`, {
    method: 'POST',
    body: JSON.stringify(values),
  })

type RequestCodeMutationOptions = Pick<UseMutationOptions, 'mutationKey'> &
  UseRestMutationCallbacks<unknown, RequestPhoneVerifParams, void>
/**
 * Hook to manage the _request_ of the confirmation code.
 */
export const useRequestPhoneVerification = (mutationOptions?: RequestCodeMutationOptions) => {
  const intl = useIntl()
  const { notifySubmit, notifyError, clearNotif } = useResponseNotification()
  const optionsWithNotifications = composeMutationCallbacks(mutationOptions, {
    onMutate: ({ tel }) => {
      notifySubmit(
        intl.formatMessage({ id: 'enroll.phone_verification.sending_code_to' }, { tel: prettyTelString(tel) })
      )
    },
    onError: (err) => notifyError(err.message),
    onSuccess: () => {
      clearNotif()
    },
  })
  // must invalidate any queries that expose the user's phone number, which will be updated by this mutation
  return useMutationWithInvalidation(requestCode, optionsWithNotifications, [
    useEmployeeEnrollmentInfoQuery.getKey(),
    useCurrentUserQuery.getKey(),
    USER_QUERY_KEY,
  ])
}
