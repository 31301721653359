import { useQuery } from '@tanstack/react-query'
import { useCreateBillingSetupIntentMutation } from '../../externalServices/stripe/gql/createBillingSetupIntent_gen'
import { getBillableType } from './billingUtils'
import { Billable } from './types'

/**
 * This is kind of cheating because we're wrapping a mutation in useQuery, but in this case the mutation
 * is creating a value that we need as soon as the owner is loaded.
 */
export const useBillingClientSecret = (billable: Billable) => {
  const { mutateAsync: createSetupIntent } = useCreateBillingSetupIntentMutation({
    onSuccess: (response) => {
      if (!response.createBillingSetupIntent) {
        throw new Error('Could not configure billing. Please refresh and try again.')
      }
    },
  })
  return useQuery(['createSetupIntent', billable.id], () =>
    createSetupIntent({ input: { ownerId: billable.id, ownerType: getBillableType(billable) } }).then(
      (response) => response.createBillingSetupIntent?.clientSecret
    )
  )
}
useBillingClientSecret.getKey = (billable: Billable) => ['createSetupIntent', billable.id]
