import { UseRestMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../../../utils/useMutationNotifier'
import { useBankingTransactionQuery } from '../graphql/bankingTransaction_gen'
import { useBankingTransactionsQuery } from '../graphql/bankingTransactions_gen'

/**
 * A transaction-specific disconfirmation mutation
 */
export const useDisonfirmTransaction = (txnId: string, _mutationOptions?: UseRestMutationCallbacks) => {
  const intl = useIntl()

  const mutationOptions = useMutationNotifier({
    onSuccess: intl.formatMessage({ id: 'transactions.spending.disconfirm.success' }),
  })
  return useMutationWithInvalidation(
    () =>
      fetchRest<unknown>(`/api/user/transactions/${txnId}/disconfirm`, {
        method: 'PATCH',
      }),
    mutationOptions,
    [useBankingTransactionsQuery.getKey(), useBankingTransactionQuery.getKey({ id: txnId })]
  )
}
