import {
  ActionLayout,
  Box,
  Button,
  Collapse,
  Container,
  FormHelperText,
  Grid,
  InlineCurrencyInputController,
  InlineInputController,
  Link,
  ListItem,
  PageBody,
  RadioButton,
  RadioGroup,
  SelectController,
  Typography,
  insetShadow,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate } from 'react-router'
import { daysFromDate } from '../../../../utils/dateUtils'
import { dateFormat, dateStringFormat } from '../../../../utils/formatters/dateFormat'
import { isNotNull } from '../../../../utils/typeUtils'
import { PretaxCategory } from '../../../graphql/generated.types'
import { isConnected } from '../../cards/personal/connect/bankingConnectionUtils'
import { useQuerySpendingConnections } from '../../cards/useQuerySpendingConnections'
import { useEmployeeQuery } from '../../gql/employee_gen'
import { SubmitReceiptInput } from './SubmitReceiptInput'
import { MissingTransactionFormValues } from './types'
import { useSubmitMissingTransaction } from './useSubmitMissingTransaction'

const defaultValues: MissingTransactionFormValues = {
  amount: '',
  description: '',
  pretax_category: PretaxCategory.Parking,
  date: '',
  payment_type: 'card',
  image: null,
}

const dateOlderThanOneWeek = (date: Date) => {
  const today = new Date()
  const oneWeekAgo = daysFromDate(today, -7)
  return date < oneWeekAgo
}

const useMayShowMissingTransactionForm = () => {
  const today = new Date()
  const { data: employeeData } = useEmployeeQuery()
  const enrollmentDate = employeeData?.employee?.enrollmentInfo?.enrollmentDate
  if (!enrollmentDate) return false
  const enrollmentDateObj = dateStringFormat.dateFromDateString(enrollmentDate)
  const tenDaysFromEnrollment = daysFromDate(enrollmentDateObj, 10)
  return today > tenDaysFromEnrollment
}

/**
 * This item can be used in a list of transactions to prompt the user to submit a missing transaction.
 */
export const MissingTxnListItem = () => {
  const mayShowMissingTransactionForm = useMayShowMissingTransactionForm()
  if (!mayShowMissingTransactionForm) return null
  return (
    <ListItem sx={{ boxShadow: (theme) => insetShadow(theme.shadows[4]) }}>
      <Box component={Container} textAlign="center" py={3}>
        <Typography variant="h2" component="h3" fontWeight="normal" gutterBottom>
          <FormattedMessage id="transactions.transaction_list.did_we_missing_something" />
        </Typography>
        <Button component={Link} variant="contained" color="primary" to="new">
          <FormattedMessage id="transactions.transaction_list.submit_missing_transaction" />
        </Button>
      </Box>
    </ListItem>
  )
}

const useCardNames = (): Array<string> => {
  const intl = useIntl()
  const {
    data: { bankingConnections, aliceCardAccounts },
  } = useQuerySpendingConnections()
  if (!bankingConnections || !aliceCardAccounts) return []
  const cardNames = []
  if (aliceCardAccounts.length > 0) {
    cardNames.push(intl.formatMessage({ id: 'spending.missing_transaction.payment_card.alice_card' }))
  }
  const connectedBankNames = bankingConnections
    .filter(isConnected)
    .map((b) => b.institution?.name)
    .filter(isNotNull)
    .map((bankName) => intl.formatMessage({ id: 'spending.missing_transaction.issued_by' }, { bankName }))

  return [...cardNames, ...connectedBankNames]
}

const CardRadioButton = () => {
  const cardNames = useCardNames()
  if (cardNames.length === 0) return null

  return (
    <RadioButton
      value="card"
      label={
        <Box pt={0.25}>
          <Typography variant="body2">
            {cardNames.length === 1 ? (
              cardNames[0]
            ) : (
              <FormattedMessage id="spending.missing_transaction.my_connected_card" />
            )}
          </Typography>
          {cardNames.length > 1 && (
            <Typography variant="caption" component="ul">
              {cardNames.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </Typography>
          )}
        </Box>
      }
    />
  )
}

/**
 * A progressive-disclosure form that is used to submit a single missing transaction.
 * Note that although the API only recognizes 'card' and 'cash' transactions, the UI
 * allows the user to select 'card not connected to Alice', which we treat _internally_
 * as a cash transaction that requires substantiation.
 */
const MissingTransactionForm = () => {
  const { formState, handleSubmit, control, watch, register, setValue } =
    useForm<MissingTransactionFormValues>({
      mode: 'all',
      defaultValues,
    })
  const intl = useIntl()
  const { mutate: submitTransaction, isSuccess, isLoading } = useSubmitMissingTransaction(intl)
  const [pretaxCategory, paymentType, image] = watch(['pretax_category', 'payment_type', 'image'])
  const isInputDisabled = isLoading || isSuccess
  const isSubmitDisabled = !formState.isDirty || !formState.isValid || isInputDisabled
  const disableCashMessage =
    pretaxCategory === 'mass_transit'
      ? intl.formatMessage({ id: 'spending.missing_transaction.constraint_on_mass_transit' })
      : false
  const requireSubstantiation = paymentType !== 'card'
  React.useEffect(() => {
    if (disableCashMessage) setValue('payment_type', 'card')
  }, [disableCashMessage, setValue])
  const removeImage = React.useCallback(() => setValue('image', null), [setValue])

  return (
    <form onSubmit={handleSubmit((values) => submitTransaction(values))}>
      {formState.errors.date && (
        <Typography variant="body2" color="error" gutterBottom>
          {formState.errors.date.message}
        </Typography>
      )}
      <Typography gutterBottom>
        <FormattedMessage
          id="spending.missing_transaction.expense_details"
          values={{
            'date-input': () => (
              <InlineInputController
                name="date"
                type="date"
                disableErrorMessage // long error message - show it in the Typography above
                inputProps={{
                  max: dateFormat.inputVal(new Date()),
                  // min: enrollmentStartDate
                }}
                control={control}
                rules={{
                  required: intl.formatMessage(
                    { id: 'common.validation.please_enter' },
                    { subject: intl.formatMessage({ id: 'transactions.date' }) }
                  ),
                  validate: (value) => {
                    if (typeof value !== 'string') return true // guard condition against null/undefined
                    // value must be before 7 days ago for connected card
                    return (
                      paymentType !== 'card' ||
                      dateOlderThanOneWeek(new Date(value)) ||
                      intl.formatMessage({ id: 'spending.missing_transaction.date_error' })
                    )
                  },
                }}
                disabled={isInputDisabled}
              />
            ),
            'amount-input': () => (
              <InlineCurrencyInputController
                name="amount"
                control={control}
                rules={{
                  required: intl.formatMessage(
                    { id: 'common.validation.please_enter' },
                    { subject: intl.formatMessage({ id: 'transactions.amount' }) }
                  ),
                }}
                disabled={isInputDisabled}
              />
            ),
            'pretax-category-input': () => (
              <SelectController
                name="pretax_category"
                control={control}
                rules={{
                  required: intl.formatMessage(
                    { id: 'common.validation.please_pick' },
                    { subject: intl.formatMessage({ id: 'transactions.a_category' }) }
                  ),
                }}
                disabled={isInputDisabled}
              >
                <option value="parking">
                  <FormattedMessage id="transactions.pretax_categories.parking" />
                </option>
                <option value="mass_transit">
                  <FormattedMessage id="transactions.pretax_categories.mass_transit" />
                </option>
              </SelectController>
            ),
          }}
        />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage
          id="spending.missing_transaction.expense_description"
          values={{
            'description-input': () => (
              <InlineInputController
                control={control}
                name="description"
                multiline
                placeholder="ex. Hooper's store"
                rules={{
                  required: intl.formatMessage(
                    { id: 'common.validation.please_enter' },
                    {
                      subject: intl.formatMessage({ id: 'spending.missing_transaction.brief_description' }),
                    }
                  ),
                }}
                disabled={isInputDisabled}
              />
            ),
          }}
        />
      </Typography>
      <Typography>
        <FormattedMessage id="spending.missing_transaction.i_paid_with" />{' '}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="payment type" {...field}>
                <CardRadioButton />
                <RadioButton
                  value="card-not-connected"
                  label={intl.formatMessage({ id: 'spending.missing_transaction.personal_card' })}
                  disabled={Boolean(disableCashMessage)}
                />
                <RadioButton
                  value="cash"
                  label={intl.formatMessage({ id: 'transactions.payment_type.cash' })}
                  disabled={Boolean(disableCashMessage)}
                />
                {disableCashMessage && <FormHelperText>{disableCashMessage}</FormHelperText>}
              </RadioGroup>
            )}
            name="payment_type"
            control={control}
          />
        </Grid>
      </Grid>
      <Collapse in={requireSubstantiation}>
        <Box
          mt={2}
          component={Grid}
          container
          spacing={2}
          flexDirection="row-reverse"
          justifyContent="flex-end"
        >
          {!image && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="p" gutterBottom>
                <FormattedMessage id="spending.receipt.context_around_receipt" />
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={image ? 12 : 4}>
            <SubmitReceiptInput
              register={register}
              value={image}
              reset={removeImage}
              required={requireSubstantiation}
            />
          </Grid>
        </Box>
      </Collapse>
      <ActionLayout
        primary={
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={isSubmitDisabled}>
            <FormattedMessage id="spending.missing_transaction.submit_expense" />
          </Button>
        }
      />
    </form>
  )
}

export const MissingTransaction = () => {
  const mayShowMissingTransactionForm = useMayShowMissingTransactionForm()
  if (!mayShowMissingTransactionForm) return <Navigate to="/spending" />

  return (
    <PageBody>
      <Container>
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id="transactions.transactions" />
        </Typography>
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id="spending.missing_transaction.submit_expense" />
        </Typography>
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="spending.missing_transaction.instructions_to_submit" />
        </Typography>
        <MissingTransactionForm />
      </Container>
    </PageBody>
  )
}
