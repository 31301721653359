import { Box, Button, Grid, Link, styled, Typography } from '@alice-financial/pretext-ui'
import VisibilityIcon from '@mui/icons-material/Visibility'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ONE_DAY_MS } from '../../../../utils/dateUtils'
import { dateFormat, dateStringFormat } from '../../../../utils/formatters/dateFormat'
import { AliceCardType } from '../../../graphql/generated.types'
import { AliceCardFragment } from '../gql/connectedCardsQuery_gen'
import { isCardDead } from './aliceCardUtils'
import cardArtUrl from './assets/card-art.png'
import { ReportMissingButton } from './physical/ReportMissingCard'
import { SecureCardDetailsModal } from './stripe/secureCardDetails/SecureCardDetails'
import { useShowSecureCardDetails } from './stripe/secureCardDetails/useShowSecureCardDetails'
import { CardProps } from './types'

/**
 * Only instant cards can use Stripe Issuing elements to view card details
 * https://docs.stripe.com/issuing/elements
 */
const getMayViewDetails = (card: AliceCardFragment) => card.cardType === AliceCardType.Instant

const getExpectedDeliveryDate = ({ createdAt }: Pick<AliceCardFragment, 'createdAt'>) => {
  const createdDate = dateStringFormat.dateFromDateString(createdAt)
  const threeWeeksAfterRequested = new Date(createdDate.getTime() + ONE_DAY_MS * 21)
  return threeWeeksAfterRequested
}

/**
 * Special layout component that looks like a credit card
 * 1. Card art background
 * 2. Fixed aspect ratio
 * 3. display 'table' to allow a child to cover full area and have vertically centered text (see CardDisplayLabelLayout)
 */
const CardBodyLayout = styled(Box)(({ theme }) => ({
  display: 'table',
  width: '100%',
  position: 'relative',
  float: 'left',
  aspectRatio: '16/9',
  background: `center / contain no-repeat url("${cardArtUrl}")`,
  borderRadius: 2,
  overflow: 'hidden',
  boxShadow: theme.shadows[2],
}))

/**
 * Special layout component that displays a vertically-centered label that appears as
 * a hover effect for the card display (used to prompt user to click for more details)
 */
const CardDisplayLabelLayout = styled('div')(({ theme }) => ({
  display: 'table-cell',
  textAlign: 'center',
  verticalAlign: 'middle',
  opacity: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  textDecoration: 'none',
  transition: theme.transitions.create('opacity'),
  '&:hover': {
    opacity: 1,
  },
}))

/**
 * A label to display the card type (instant, physical, etc.) as an overlay on the card display
 */
const CardTypeLabel = ({ cardType }: { cardType: AliceCardType }) => (
  <Typography
    textAlign="right"
    color="white"
    fontWeight="bolder"
    sx={(theme) => ({
      boxShadow: theme.shadows[2],
      backgroundColor:
        cardType === AliceCardType.Instant ? theme.palette.secondary.main : theme.palette.info.main,
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      textShadow: '1px 1px 1px rgba(0,0,0,0.5)', // just a little more edge contrast
      fontVariant: 'small-caps',
      padding: '2% 0.8rem',
      borderRight: 'none',
      borderTop: 'none',
    })}
  >
    {cardType.toLowerCase()}
  </Typography>
)

type CardDisplayDetailProps = {
  label?: string
  children: React.ReactNode
}
const CardDetail = ({ label, children }: CardDisplayDetailProps) => (
  <Typography variant="body2" color="white" textAlign="left">
    {label && <span style={{ fontVariant: 'small-caps', fontWeight: 'bold' }}>{label} </span>}
    {children}
  </Typography>
)

const ShowDetailsButton = ({ card }: CardProps) => {
  const { openLink } = useShowSecureCardDetails(card)
  return (
    <Button
      component={Link}
      to={openLink}
      color="primary"
      variant="outlined"
      size="small"
      endIcon={<VisibilityIcon />}
    >
      <FormattedMessage id="cards.alice.instant.show_details" />
    </Button>
  )
}

const CardActions = ({ card }: CardProps) => {
  const expectedDeliveryDate = getExpectedDeliveryDate(card)
  const isPastDeliveryDate = expectedDeliveryDate < new Date()
  return (
    <Grid container spacing={1}>
      {card.cardType === AliceCardType.Physical && (
        <>
          {!isPastDeliveryDate && (
            <Grid item xs={12}>
              <Typography variant="caption" component="p" color="inherit" lineHeight={1}>
                <FormattedMessage
                  id="cards.alice.arrival_date"
                  values={{ arrivalDate: dateFormat.medium(getExpectedDeliveryDate(card)) }}
                />
              </Typography>
            </Grid>
          )}
          <Grid item>
            <ReportMissingButton card={card} />
          </Grid>
        </>
      )}
      {getMayViewDetails(card) && (
        <Grid item>
          <ShowDetailsButton card={card} />
        </Grid>
      )}
    </Grid>
  )
}

/**
 * Display card details as an overlay on the card display, but obfuscate sensitive information
 */
const ObfuscatedCardDetails = ({ card }: CardProps) => {
  return (
    <Grid container spacing={1} marginLeft={1}>
      <Grid item xs={12}>
        <CardDetail>•••• •••• •••• {card.last4}</CardDetail>
      </Grid>
      <Grid item xs={5}>
        <CardDetail label="exp">{dateStringFormat.MMYY(card.expiryDate)}</CardDetail>
      </Grid>
      {card.cardType === AliceCardType.Instant && (
        <Grid item xs={6}>
          <CardDetail label="cvc">•••</CardDetail>
        </Grid>
      )}
    </Grid>
  )
}

/**
 * A component to render card information inside a faux card display.
 *
 * For instant cards, the whole element can be clicked to view the obfuscated card details.
 */
const CardBody = ({ card }: CardProps) => {
  const { openLink } = useShowSecureCardDetails(card)
  const mayViewDetails = getMayViewDetails(card)
  const linkProps = mayViewDetails ? { component: Link, to: openLink } : {}
  return (
    <CardBodyLayout {...linkProps}>
      {mayViewDetails && (
        <CardDisplayLabelLayout>
          <Typography variant="caption" color="inherit" fontWeight="bold">
            <FormattedMessage id="cards.alice.instant.show_details" />
          </Typography>
        </CardDisplayLabelLayout>
      )}

      <Box position="absolute" top={0} right={0}>
        <CardTypeLabel cardType={card.cardType} />
      </Box>

      <Box position="absolute" bottom="8%" left={5}>
        <ObfuscatedCardDetails card={card} />
      </Box>
    </CardBodyLayout>
  )
}

const CardDisplay = ({ card }: CardProps) => (
  <Grid item xs={12} sm={5} minWidth={270} mb={3}>
    <CardBody card={card} />
    <CardActions card={card} />
    <SecureCardDetailsModal card={card} />
  </Grid>
)

export const AliceCardItem = ({ card }: CardProps) => {
  if (isCardDead(card)) {
    return null
  }

  return <CardDisplay card={card} />
}
