import * as React from 'react'
import { ACHMandate } from '../../externalServices/stripe/ACHMandate'
import { ConnectBankAccountForm } from '../../externalServices/stripe/ConnectBankAccountForm'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { getBillableType } from './billingUtils'
import { ConnectBillingSourceProps } from './types'
import { useBillingClientSecret } from './useBillingClientSecret'
import { useSetBankingAccount } from './useSetBankingAccount'

export const ACHBillingSourceInput = ({
  billable,
  clientSecret,
  onSuccess,
  onBankSelected,
}: ConnectBillingSourceProps & { clientSecret: string; onBankSelected?: () => void }) => {
  const [showACHMandate, setShowACHMandate] = React.useState(false)
  const { mutate: setBankingAccount } = useSetBankingAccount(
    { ownerId: billable.id, ownerType: getBillableType(billable) },
    [useOrgDashboardQuery.getKey()], // eventually we might need to invalidate a billable-specific query
    { onSuccess }
  )

  if (!clientSecret) return null

  if (showACHMandate) {
    return (
      <ACHMandate
        clientSecret={clientSecret}
        handleConfirmationResponse={setBankingAccount}
        invalidationKeys={[useBillingClientSecret.getKey(billable)]}
      />
    )
  }
  return (
    <ConnectBankAccountForm
      clientSecret={clientSecret}
      onSuccess={() => {
        setShowACHMandate(true)
        onBankSelected && onBankSelected()
      }}
    />
  )
}
