import {
  Chip,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemLink,
  ListItemText,
  Toolbar,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation, useParams } from 'react-router'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'
import { EmployeeRole } from '../graphql/generated.types'
import { useMayManageAliceCardProgram } from './aliceCardProgram/aliceCardProgramUtils'
import { useOrgDashboardQuery } from './homepage/gql/orgDashboard_gen'

type NavLinkProps = {
  to: string
  isNew?: boolean
  children: React.ReactNode
}
const NavLink = ({ to, isNew, children }: NavLinkProps) => {
  const location = useLocation()
  const target = `/manage${to}`
  const isOnTarget = location.pathname === target
  if (isOnTarget) {
    return (
      <ListItem sx={(theme) => ({ p: theme.spacing(1.1, 1) })}>
        <ListItemText
          primary={
            <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography color="text.disabled" fontWeight="bold">
                {children}
              </Typography>
            </Grid>
          }
        />
      </ListItem>
    )
  }
  return (
    <ListItem disablePadding disableGutters>
      <ListItemLink to={target} sx={{ columnGap: 0 }}>
        <ListItemText
          primary={
            <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography color="primary" fontWeight="bold">
                {children}
              </Typography>

              {isNew && <Chip label="New" color="primary" size="small" sx={{ marginLeft: 1 }} />}
            </Grid>
          }
        />
      </ListItemLink>
    </ListItem>
  )
}
type NavigationProps = {
  drawerWidth: number
}

type L = (props: { root?: string }) => React.ReactElement | null

const OrgDashboard = () => <NavLink to="">Org dashboard</NavLink>
const OrgBilling = () => <NavLink to="/billing">Billing</NavLink>
const AliceCardProgram = () => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  return <NavLink to="/alice-card-program">Alice Card</NavLink>
}
const EmployerDashBoard: L = ({ root = '' }) => <NavLink to={root}>Paygroup home</NavLink>
const People: L = ({ root = '' }) => <NavLink to={`${root}/people`}>People</NavLink>
const PayPeriods: L = ({ root = '' }) => <NavLink to={`${root}/pay-periods`}>Pay periods</NavLink>
const Documents: L = ({ root = '' }) => <NavLink to={`${root}/documents`}>Documents</NavLink>
const Locations: L = () => <NavLink to="/locations">Locations</NavLink>
const Profile: L = () => <NavLink to={`/profile`}>Profile</NavLink>

/**
 * Payroll reports are only relevant for particular payroll platforms that
 * are not sufficiently automated. Only display nav when the platform permits
 */
const PayrollReports: L = () => {
  const { data: orgDashboardData } = useOrgDashboardQuery()
  const org = orgDashboardData?.organization
  if (!org?.payrollConnection.payrollPlatform?.permitsAdminReports) return null

  return <NavLink to="/reports">Payroll reports</NavLink>
}

const EmployerNavigationList = ({ routingRoot }: { routingRoot: string }) => {
  const { data: employeeData } = useEmployeeQuery()
  const employee = employeeData?.employee
  if (!employee) return null

  const isOrgAdmin = employee.role === EmployeeRole.OrganizationAdmin

  return (
    <>
      <List disablePadding dense>
        <EmployerDashBoard root={routingRoot} />
        <People root={routingRoot} />
        <PayPeriods root={routingRoot} />
        <Documents root={routingRoot} />
      </List>
      {isOrgAdmin && (
        <>
          <Divider sx={{ my: 1 }} />
          <List disablePadding dense>
            <OrgDashboard />
            <Profile />
          </List>
        </>
      )}
    </>
  )
}
const OrgNavigationList = () => {
  return (
    <List disablePadding dense>
      <OrgDashboard />
      <AliceCardProgram />
      <People />
      <PayPeriods />
      <OrgBilling />
      <Documents />
      <Locations />
      <PayrollReports />
      <Profile />
    </List>
  )
}

export const OrgDashboardNavigation = ({ drawerWidth }: NavigationProps) => {
  const params = useParams()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      {params.employerId ? (
        <EmployerNavigationList routingRoot={`/${params.employerId}`} />
      ) : (
        <OrgNavigationList />
      )}
    </Drawer>
  )
}
