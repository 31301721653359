import { GqlApiError, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { InertHealthcheckItem, TextField } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { dateFormat } from '../../../utils/formatters/dateFormat'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'
import { useCurrentUserOnboardingQuery } from './gql/currentUserOnboarding_gen'
import {
  SetLaunchDateMutation,
  SetLaunchDateMutationVariables,
  useSetLaunchDateMutation,
} from './gql/setLaunchDate_gen'
import { AliceCardIssuingStatus } from '../../graphql/generated.types'

type SetLaunchDateApiError = GqlApiError<SetLaunchDateMutationVariables>
const SetLaunchDateInput = ({ org }: { org: OrganizationDetailFragment }) => {
  const [launchDateInputValue, setLaunchDateInputValue] = React.useState(org.commutingLaunchDate)
  const mutationOptions = useMutationOptionsWithInvalidation(
    useMutationNotifier<SetLaunchDateMutation, SetLaunchDateApiError, SetLaunchDateMutationVariables>(),
    [useCurrentUserOnboardingQuery.getKey(), useOrgDashboardQuery.getKey()]
  )
  const { mutate: setLaunchDate, isLoading: disabled } = useSetLaunchDateMutation(mutationOptions)

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setLaunchDateInputValue(value)
    setLaunchDate({ input: { orgId: org.id, commutingLaunchDate: value } })
  }
  return (
    <TextField
      type="date"
      size="small"
      error={!launchDateInputValue}
      value={launchDateInputValue || ''}
      disabled={disabled}
      inputProps={{ min: dateFormat.inputVal(new Date()) }}
      onChange={handleChange}
    />
  )
}

type LaunchDateHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}
export const LaunchDateHealthcheck = ({ org }: LaunchDateHealthcheckProps) => {
  if (!org) return null
  const onboardingState = org.onboardingState
  const inactiveAliceCardStatuses = [AliceCardIssuingStatus.Enabled, AliceCardIssuingStatus.Deactivated]
  // show this if the org does not have a company account, if they have one that has been deactivated for some reason,
  // or if it is 'not_submitted' or 'submitted' - and keep it open for future statuses like 'denied' or 'alice hold'
  const aliceCardProgramNotReady =
    !inactiveAliceCardStatuses.includes(org.aliceCardProgram.cardIssuingStatus) &&
    !onboardingState.isAliceCardProgramActive

  if (
    !onboardingState.isPayrollConnectionComplete ||
    !onboardingState.isBillingConnected ||
    aliceCardProgramNotReady ||
    onboardingState.isAlreadyLaunched
  ) {
    return null
  }
  if (!org.commutingLaunchDate) {
    return (
      <InertHealthcheckItem
        primary="Set launch date"
        secondary="Set your launch date"
        status="incomplete"
        action={<SetLaunchDateInput org={org} />}
      />
    )
  }
  if (onboardingState.isLaunchingToday) {
    const launchDayNotice = 'Starting today, your employees can use Alice for commuting benefits.'
    return (
      <InertHealthcheckItem primary="You're ready to launch!" secondary={launchDayNotice} status="complete" />
    )
  }
  if (onboardingState.isLaunchingInFuture) {
    const futureLaunchNotice = `Starting on ${org.commutingLaunchDate}, your employees will be able to use Alice for commuting benefits.`
    return (
      <InertHealthcheckItem
        primary="You're ready to launch!"
        secondary={futureLaunchNotice}
        status="complete"
        action={<SetLaunchDateInput org={org} />}
      />
    )
  }
  return null // don't need to show this item _after_ launch date, although we might want to check that 'launch' happened, if possible
}
