import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ActionLayout,
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import { theme } from '@alice-financial/pretext-ui/theme/theme'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { usStates } from '../../../data/usStates'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocation } from '../../graphql/generated.types'
import { useManageWorkLocation } from './useManageWorkLocation'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { LogoUploadButton } from '../LogoUploadButton'
import { convertPlaceToLocationInput, convertWorkLocationToFormInputs } from './locationUtils'
import { PlaceSearchBar } from './PlaceSearchBar'
import { WorkLocationFormInputs } from './types'
import { NULL_FILE_LIST } from '../../../utils/fileUtils'

type WorkLocationFormProps = {
  org: OrganizationDetailFragment
  initialData?: WorkLocation
  onSuccess?: () => void
  disableLookup?: boolean
}

const useWorkLocationForm = (
  org: OrganizationDetailFragment,
  initialData?: WorkLocation,
  onSuccess?: () => void
) => {
  const [logoChanged, setLogoChanged] = React.useState<boolean>(false)
  const locationId = initialData?.id
  const inputData = convertWorkLocationToFormInputs(initialData) // converts a WorkLocation to a WorkLocationFormInputs
  const form = useForm<WorkLocationFormInputs>({
    values: inputData,
  })
  const { handleSubmit, setValue, watch, reset } = form

  const { mutate: manageWorkLocation } = useManageWorkLocation(org.id, [useOrgDashboardQuery.getKey()], {
    onSuccess,
  })
  const uploadedLogoValue = watch('logo')?.item(0)
  const logoValue = uploadedLogoValue ? URL.createObjectURL(uploadedLogoValue) : null

  const handleAddressSelect = (place: google.maps.places.PlaceResult) => {
    const locationInput = convertPlaceToLocationInput(place)
    if (!locationInput || !locationInput.address) return

    setValue('address', locationInput.address)
    locationInput.latitude && setValue('latitude', locationInput.latitude)
    locationInput.longitude && setValue('longitude', locationInput.longitude)
    setValue('name', locationInput.name)
  }

  const onSubmit: SubmitHandler<WorkLocationFormInputs> = (values) =>
    manageWorkLocation({ ...values, logoChanged })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('logo', e.target.files)
    setLogoChanged(true)
  }

  const removeLogo = () => {
    setValue('logo', NULL_FILE_LIST)
    setLogoChanged(true)
  }

  const handleReset = () => {
    reset()
    if (locationId) {
      setValue('logo', NULL_FILE_LIST)
    }
  }

  return {
    logoValue,
    setLogoChanged,
    logoChanged,
    handleAddressSelect,
    onSubmit: handleSubmit(onSubmit),
    locationId,
    handleReset,
    removeLogo,
    handleFileChange,
    ...form,
  }
}

export const WorkLocationForm: React.FC<WorkLocationFormProps> = ({
  org,
  initialData,
  onSuccess,
  disableLookup,
}) => {
  const intl = useIntl()
  const {
    logoValue,
    control,
    handleAddressSelect,
    onSubmit,
    locationId,
    logoChanged,
    register,
    handleReset,
    removeLogo,
    handleFileChange,
  } = useWorkLocationForm(org, initialData, onSuccess)

  const displayLogo = logoChanged ? logoValue : initialData?.logo?.url

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="body2" gutterBottom>
        Enter {!initialData && 'new'} location details for <strong>{initialData?.name}</strong>
      </Typography>

      <Box pb={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {!disableLookup && (
              <Grid item xs={12}>
                <PlaceSearchBar onSuccess={handleAddressSelect} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Accordion expanded={disableLookup}>
                <AccordionSummary expandIcon={!locationId && <ExpandMoreIcon />}>
                  <Typography variant="body2" color="primary" fontWeight="bold">
                    Enter address manually
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextFieldController
                        name="address.line1"
                        control={control}
                        label="Address Line 1"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldController
                        name="address.line2"
                        control={control}
                        label="Address Line 2"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldController
                        name="address.cityName"
                        control={control}
                        label="City"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldController
                        name="address.zipcode"
                        control={control}
                        label="Zip Code"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldController
                        name="address.state"
                        control={control}
                        rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        label="State"
                        variant="outlined"
                        fullWidth
                      >
                        <option value=""></option>
                        {usStates.map(({ code }) => (
                          <option key={code} value={code}>
                            {code}
                          </option>
                        ))}
                      </TextFieldController>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextFieldController
            name="name"
            control={control}
            label="Location name"
            variant="outlined"
            autoComplete="off"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <TextFieldController
            name="nickname"
            control={control}
            label="Nickname (optional)"
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Grid container alignItems="top" justifyContent="space-between">
              <Grid item>
                <FormLabel>Upload your logo</FormLabel>
                <Typography variant="body2" color="primary">
                  We&apos;ll use it to help your employees get enrolled
                </Typography>
              </Grid>
              <Grid item justifyContent="center">
                {displayLogo ? (
                  <Paper
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      justifyContent: 'center',
                    }}
                  >
                    {/* this takes the file in memory and creates a temporary URL for it */}
                    <img
                      src={displayLogo}
                      alt="Logo"
                      style={{ maxHeight: 120, maxWidth: theme.spacing(18) }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => removeLogo()}
                      sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Paper>
                ) : (
                  <LogoUploadButton
                    {...register('logo', { onChange: (e) => handleFileChange(e) })}
                    id={locationId?.toString()}
                  >
                    Upload logo
                  </LogoUploadButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <ActionLayout
          autoWidth
          primary={
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          }
          secondary={
            <Button type="reset" variant="outlined" color="secondary" onClick={() => handleReset()}>
              Clear
            </Button>
          }
        />
      </Grid>
    </form>
  )
}
