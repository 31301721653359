import { ENGLISH_TRNS } from '../../../translations/messages/en-us'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import { BankingTransactionFragment } from '../../graphql/fragments/BankingTransactionFragment_gen'
import {
  ConfirmationStatusValue,
  PretaxCategory,
  ReimbursementClaimApprovalStatus,
} from '../../graphql/generated.types'
import { Transaction, TransactionPaymentType, TransactionStatus, TxnFilter } from './types'

export const ReadableTxnStatus: Record<TransactionStatus, keyof typeof ENGLISH_TRNS | undefined> = {
  submitted: 'transactions.status.submitted.title',
  approved: 'transactions.status.approved.title',
  approved_final: 'transactions.status.approved.title',
  denied: 'transactions.status.denied.title',
  unconfirmed: 'transactions.status.unconfirmed.title',
  confirmed: 'transactions.status.confirmed.title',
  disconfirmed: 'transactions.status.disconfirmed.title',
  confirmed_final: 'transactions.status.confirmed.title',
  rejected: undefined, // this should probably never be shown to user - these transactions are known-invalid
  reimbursed_deduction_pending: 'transactions.status.reimbursed_deduction_pending.title',
  deducted: 'transactions.status.deducted.title',
  deduction_canceled: 'transactions.status.deduction_canceled.title',
  reimbursed: 'transactions.status.reimbursed.title',
}

export const ReadableTxnCategory: Record<PretaxCategory, keyof typeof ENGLISH_TRNS> = {
  [PretaxCategory.Dental]: 'transactions.pretax_categories.dental',
  [PretaxCategory.DependentCare]: 'transactions.pretax_categories.dependent_care',
  [PretaxCategory.Healthcare]: 'transactions.pretax_categories.healthcare',
  [PretaxCategory.HealthcarePharmacy]: 'transactions.pretax_categories.healthcare',
  [PretaxCategory.MassTransit]: 'transactions.pretax_categories.mass_transit',
  [PretaxCategory.Parking]: 'transactions.pretax_categories.parking',
  [PretaxCategory.Vision]: 'transactions.pretax_categories.vision',
  [PretaxCategory.NotEligible]: 'transactions.pretax_categories.not_eligible',
}

export const ReadablePaymentType: Record<TransactionPaymentType, keyof typeof ENGLISH_TRNS> = {
  alice_card: 'transactions.payment_type.alice_card',
  connected_card: 'transactions.payment_type.personal_card',
  cash: 'transactions.payment_type.cash',
}

const getPaymentType = (account: BankingTransactionFragment['account']): TransactionPaymentType => {
  switch (account.__typename) {
    case 'CashPaymentAccount': {
      return 'cash'
    }
    case 'BankingAccount': {
      // accountType is not always populated, so we use the account name as a fallback
      const isAliceCard = account.accountType === 'alice' || account.name === 'Alice Card'
      return isAliceCard ? 'alice_card' : 'connected_card'
    }
  }
}

/**
 * type TransactionReimbursement {
 *  isFullyReimbursed: Boolean
 *  reimbursements: [{ amount, date }]
 * }
 */
const getApiTxnStatus = (
  apiTxn: BankingTransactionFragment
): { value: TransactionStatus; updatedAt?: Date } => {
  const type = getPaymentType(apiTxn.account)
  const isFullyReimbursed = apiTxn.reimbursementStatus?.isFullyReimbursed
  const txnDate = dateStringFormat.dateFromDateString(apiTxn.date)
  switch (type) {
    case 'cash': {
      if (isFullyReimbursed) return { value: 'reimbursed' }
      const approvalStatus = apiTxn.reimbursementClaim?.approvalStatus
      const reviewDate = apiTxn.reimbursementClaim?.reviewedAt
        ? dateStringFormat.dateFromDateString(apiTxn.reimbursementClaim.reviewedAt)
        : undefined

      if (approvalStatus === ReimbursementClaimApprovalStatus.Denied) {
        return { value: 'denied', updatedAt: reviewDate }
      }
      if (approvalStatus === ReimbursementClaimApprovalStatus.Approved) {
        if (!apiTxn.reimbursementStatus) {
          // the BE cannot determine reimbursement status, so 'approved' is the final status possible for this txn
          return { value: 'approved_final', updatedAt: reviewDate }
        }
        return { value: 'approved', updatedAt: reviewDate }
      }
      // !approvalStatus or approvalStatus === ReimbursementClaimApprovalStatus.Pending
      return {
        value: 'submitted',
        updatedAt: txnDate,
      }
    }
    case 'alice_card': {
      // 'deducted'
      // 'deduction_canceled'
      return { value: 'reimbursed_deduction_pending', updatedAt: txnDate }
    }
    case 'connected_card': {
      const confirmationStatus = apiTxn.spendEligibility.confirmationStatus.value
      if (confirmationStatus === ConfirmationStatusValue.Disconfirmed) return { value: 'disconfirmed' }
      if (isFullyReimbursed) return { value: 'reimbursed' }
      const confirmationDate = apiTxn.spendEligibility.confirmationStatus.updatedAt
        ? dateStringFormat.dateFromDateString(apiTxn.spendEligibility.confirmationStatus.updatedAt)
        : undefined
      // if (apiTxn.reimbursement.is_fully_reimbursed) return 'reimbursed'
      if (confirmationStatus === ConfirmationStatusValue.Unconfirmed) return { value: 'unconfirmed' }
      if (confirmationStatus === ConfirmationStatusValue.Rejected) {
        return { value: 'rejected', updatedAt: confirmationDate }
      }
      if (!apiTxn.reimbursementStatus) {
        // the BE cannot determine reimbursement status, so 'confirmed' is the final status possible for this txn
        return { value: 'confirmed_final', updatedAt: confirmationDate }
      }
      return { value: 'confirmed', updatedAt: confirmationDate }
    }
  }
}

/**
 * Basic transform function that accepts and API transaction object and returns
 * the equivalent app-defined Transaction object. Generally shouldn't need unit
 * tests for this because the type definitions should provide sufficient type safety
 */
export const apiTxnToAppTxn = (apiTxn: BankingTransactionFragment): Transaction => ({
  id: apiTxn.id,
  date: dateStringFormat.dateFromDateString(apiTxn.date),
  name: apiTxn.name,
  amount: apiTxn.amount,
  estimatedSavings: apiTxn.estimatedSavings,
  paymentType: getPaymentType(apiTxn.account),
  reimbursementClaim: apiTxn.reimbursementClaim || null,
  spendEligibility: apiTxn.spendEligibility,
  status: getApiTxnStatus(apiTxn),
})

export const getNumberOfSpecifiedFilters = (filter: TxnFilter) =>
  Object.values(filter).reduce((acc, filterValues) => acc + filterValues.length, 0)

export const getTxnUrl = (txn: Transaction): string => `/spending/${txn.id}`

export const isCategory = (value: string): value is PretaxCategory => value in ReadableTxnCategory
export const isStatus = (value: string): value is TransactionStatus => value in ReadableTxnStatus
