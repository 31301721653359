import {
  fetchRest,
  RestApiError,
  useMutationWithInvalidation,
  UseRestMutationCallbacks,
} from '@alice-financial/api'
import { RestUser } from './types'

/**
 * This endpoint will supply a _new_, logged-in user cookie. Do not call this mutation
 * unless you are ready to handle a logged-in user state.
 */
export const useSubmitPhoneVerification = (
  mutationOptions?: UseRestMutationCallbacks<RestUser, { confirmation_code: string }>
) => {
  return useMutationWithInvalidation<RestUser, RestApiError, { confirmation_code: string }>(
    ({ confirmation_code }) =>
      fetchRest<{ user: RestUser }>('/api/identity_verification/verify_phone_code', {
        method: 'POST',
        body: JSON.stringify({ confirmation_code }),
      }).then(({ user }) => user),
    mutationOptions,
    'all'
  )
}
